import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import DocumentUploadList from './modals/DocumentUploadList';
import PropTypes from 'prop-types';

const DocumentUploader = ({
  onFileUploaded,
  wo_files,
  overrideUploadProps = {},
  showTitle = true,
  maxVisibleItems = null,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (wo_files.wo_documents && wo_files.wo_documents.length > 0) {
      const initialFileList = wo_files.wo_documents.map(
        (documentOrUrl, index) => ({
          uid: `old-doc-${index}`,
          name: documentOrUrl.name ? documentOrUrl.name : `Document ${index}`,
          status: 'done',
          url: documentOrUrl.url ? documentOrUrl.url : documentOrUrl,
        })
      );

      setFileList(initialFileList);
    }
  }, [wo_files.wo_documents]);

  const handleFileChange = ({ file, fileList: newFileList }) => {
    const filteredList = newFileList.filter(
      (f) => !f.size || f.size / 1024 / 1024 <= 10
    );

    if (newFileList.length > filteredList.length) {
      notification.open({
        message: <div style={{ color: 'red' }}>Error</div>,
        description: 'File size must be less than 10MB.',
      });
    }

    setFileList(filteredList);
  };

  const beforeUpload = (file) => {
    const isGreaterThan2M = file.size / 1024 / 1024 > 10;
    if (isGreaterThan2M) {
      return false;
    }

    onFileUploaded(file);
    return false;
  };

  const uploadProps = {
    accept: '.pdf',
    onRemove: (file) => {
      setFileList((curr) => {
        const index = curr.indexOf(file);
        const newFileList = curr.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
      onFileUploaded(file, 'removed');
    },
    fileList,
    onChange: handleFileChange,
    beforeUpload,
    multiple: true,
    ...overrideUploadProps,
  };

  return (
    <div>
      {showTitle && (
        <div>
          <b>Add Documents:</b>
        </div>
      )}
      <DocumentUploadList
        uploadProps={uploadProps}
        maxVisibleItems={maxVisibleItems}
      />
    </div>
  );
};

DocumentUploader.propTypes = {
  onFileUploaded: PropTypes.func,
  wo_files: PropTypes.object,
  overrideUploadProps: PropTypes.object,
  showTitle: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
};

DocumentUploader.defaultProps = {
  onFileUploaded: () => {},
  wo_files: {
    wo_images: [],
    wo_documents: [],
  },
  overrideUploadProps: {},
  showTitle: true,
  maxVisibleItems: null,
};

export default DocumentUploader;
