import React from 'react';
import styled from 'styled-components';
import { Row, Col, Badge, Tooltip } from 'antd'; 
import { MinusCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { PropTypes } from 'mobx-react';
import _ from 'lodash';

import { getPriorityTagColor } from 'services/Issue';
import { prescriptiveMaintenanceMessages, customFleets } from 'shared';
import { CurrentUserStore, ShopStore } from 'stores';
import { calcMileage } from 'helpers/unitCalculations';
import { ReactComponent as MilLamp } from 'assets/icons/milLamp.svg';
import { ReactComponent as ProtectLamp } from 'assets/icons/protectLamp.svg';
import { ReactComponent as AmberWarningLamp } from 'assets/icons/amberWarningLamp.svg';
import { ReactComponent as RedStopLamp } from 'assets/icons/redStopLamp.svg';


const CarIndicator = styled.div`
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  padding: 0;
  background-color: ${(props) => props.color || 'initial'};
`;

const StyledBadge = ({ count, backgroundColor }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{ backgroundColor }}
    overflowCount={1000000}
  />
);

const VehicleResolvedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

StyledBadge.propTypes = {
  count: PropTypes.any,
  backgroundColor: PropTypes.string,
};

const CarIDSection = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

export const renderLastTriggeredAtV2 = (car) => {
  const stringDate = (new Date(car.last_triggered_at)).toString();
  const outputString = _.get(stringDate.split('GMT'),0,stringDate);

  return <p>{outputString}</p>;
};

export const renderIndicatorV2 = (car) => {
  if (parseInt(car.critical) > 0) {
    return <CarIndicator color={getPriorityTagColor(4)} />;
  }
  if (parseInt(car.major) > 0) {
    return <CarIndicator color={getPriorityTagColor(2)} />;
  }
  return <CarIndicator />;
};

export const renderVehicleResolvedV2 = (car) => {
  return (
  <VehicleResolvedContainer>
    {_.get(car, 'resolved') ? <CheckCircleTwoTone style={{fontSize: '200%'}} /> : <MinusCircleTwoTone style={{fontSize: '200%'}} />}
  </VehicleResolvedContainer>
  );
};

export const renderVehicleIdV2 = (car) => {
  return (
    <CarIDSection>
      <strong>{car.car_name}</strong>
      <p>{car.car_make} {car.car_model} {car.car_year}</p>
      <p>
        {`${Number(calcMileage(car.mileage_total).toFixed(1)).toReadable()}
        ${CurrentUserStore.user.displayedOdometer}`}
      </p>
      {!customFleets.wolfline.includes(ShopStore.currentShop.id) && !customFleets.bmc.includes(ShopStore.currentShop.id) && (
        <p>{Math.round(car.engine_hours/3600)} engine hours</p>
      )}
    </CarIDSection>
  );
};

export const renderEngineLightStatus = (car) => {
  const engineLightStatus = _.get(car, 'engine_light_status');
  const iconList = [];
  if (_.isNil(engineLightStatus)){
    return null;
  }
  if (_.get(engineLightStatus, 'generalVehicleWarningLight')){
    return [<Tooltip key='amberLamp' title='Engine Light'><div key='amberLamp'><AmberWarningLamp key='amberLamp' width={35} height={35} /></div></Tooltip>];
  }
  if (_.get(engineLightStatus, 'redStopLamp')){
    iconList.push(<Tooltip key="redLamp" title="Red Stop Lamp"><div key='redLamp'><RedStopLamp key='redLamp' width={35} height={35} /></div></Tooltip>);
  }
  if (_.get(engineLightStatus, 'malfunctionLamp')){
    iconList.push(<Tooltip key='milLamp' title='MIL Lamp (emissions)'><div key='milLamp'><MilLamp key='milLamp' width={35} height={35} /></div></Tooltip>);
  }
  if (_.get(engineLightStatus, 'amberWarningLamp')){
    iconList.push(<Tooltip key="amberLamp" title="Check Engine Light"><div key='amberLamp'><AmberWarningLamp key='amberLamp' width={35} height={35} /></div></Tooltip>);
  }
  if (_.get(engineLightStatus, 'protectWarningLamp')){
    iconList.push(<Tooltip key="protectLamp" title="Engine Protect Lamp"><div key='protectLamp'><ProtectLamp key='protectLamp' width={35} height={35} /></div></Tooltip>);
  }
  if (_.isEmpty(iconList)){
    return null;
  } else {
    return iconList;
  }

};

export const renderSuggestedActionV2 = (car) => {
  const maxPriority = car.critical > 0 ? '4' : '2';
  let now = new Date();
  //Temp maxMajorIssueDate to always trigger the stale major message
  let maxMajorIssueDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8);
  const issueCounts = null;// car.issueCounts;
  return <p>{prescriptiveMaintenanceMessages(maxPriority, maxMajorIssueDate, false, issueCounts)}</p>;
};

export const renderIssuesV2 = (car) => {
  let criticalCount = car.critical || 0;
  let majorCount = car.major || 0;
  let minorCount = car.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={criticalCount}
            backgroundColor={getPriorityTagColor(4)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Critical
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={majorCount}
            backgroundColor={getPriorityTagColor(2)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Major
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={minorCount}
            backgroundColor={getPriorityTagColor(1)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Minor
        </Col>
      </Row>
    </>
  );
};