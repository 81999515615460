import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, computed, decorate, observable, observe } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import {
  DatePicker,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';

import { AppStore, CurrentUserStore, ShopStore, TableStore } from 'stores';

import { calcMileage, convertSecondsToHours, convertHourToSeconds } from 'helpers/unitCalculations';
import { PitstopTable } from 'shared';

const { MonthPicker } = DatePicker;

const Option = Select.Option;

const Container = styled.div`
  #routines-list > li {
    p {
      font-size: 14px;
      display: inline-flex;
      width: 130px;
    }

    input {
      width: 250px;
    }
  }

  #close-check {
    i {
      cursor: pointer;
      margin: 0px 5px;
    }
  }
`;

class RoutinesTableList extends Component {
  state = {
    edittableRoutineId: null,
    edittableData: [],
  };

  tableStore = new TableStore();

  disposer = observe(ShopStore.currentShop, 'id', () => {
    if (ShopStore.isAdmin()) return;

    this.fetchData();
  });

  get dataSource () {
    return this.tableStore.data;
  }

  get customPagination () {
    return {
      pageSize: 100,
    };
  }

  get columns() {
    return [
      {
        title: 'Priority',
        key: 'priority',
        render: (routine) => {
          let priority = routine?.priority;

          if (this.state.edittableRoutineId === routine.id) {
            return (
              <Select
                defaultValue={(() => {
                  if (_.includes([0, 1], priority))
                    return <Tag color="#dec431">Minor</Tag>;

                  if (_.includes([2, 3], priority))
                    return <Tag color="#e28931">Major</Tag>;

                  if (_.includes([4, 5], priority))
                    return <Tag color="#f92d2e">Critical</Tag>;
                })()}
                style={{ width: 110 }}
                placeholder="Priority"
                onChange={this.addPriorityChangeToEditableData}
              >
                <Option value="1">
                  <Tag color="#dec431">Minor</Tag>
                </Option>
                <Option value="3">
                  <Tag color="#e28931">Major</Tag>
                </Option>
                <Option value="5">
                  <Tag color="#f92d2e">Critical</Tag>
                </Option>
              </Select>
            );
          }

          if (_.isNil(priority)) return 'N/A';

          if (_.includes([0, 1], priority))
            return <Tag color="#dec431">Minor</Tag>;

          if (_.includes([2, 3], priority))
            return <Tag color="#e28931">Major</Tag>;

          if (_.includes([4, 5], priority))
            return <Tag color="#f92d2e">Critical</Tag>;

          return 'N/A';
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (routine) => {
          let action = routine?.action;

          if (this.state.edittableRoutineId === routine.id) {
            return (
              <Input
                name="action"
                defaultValue={action}
                onChange={this.onChangeOnEditing}
              />
            );
          }

          if (_.isNil(action)) return 'N/A';

          return action;
        },
      },
      {
        title: 'Item',
        key: 'item',
        render: (routine) => {
          let item = routine?.item;

          if (this.state.edittableRoutineId === routine.id) {
            return (
              <Input name="item" defaultValue={item} onChange={this.onChangeOnEditing} />
            );
          }

          if (_.isNil(item)) return 'N/A';

          return item;
        },
      },
      {
        title: 'Description',
        key: 'description',
        render: (routine) => {
          let description = routine?.description;

          if (this.state.edittableRoutineId === routine.id) {
            return (
              <Input
                name="description"
                defaultValue={description}
                onChange={this.onChangeOnEditing}
              />
            );
          }

          if (_.isNil(description)) return 'N/A';

          return description;
        },
      },
      {
        title: 'Routine',
        key: 'routine',
        render: (routine) => {
          return this.generateRoutineInfo(routine.id, routine);
        },
      },
      {
        title: '',
        key: 'actions',
        render: (record) => {
          if (this.state.edittableRoutineId) {
            return this.generateCloseCheckIcons();
          }

          const menu = (
            <Menu>
              <Menu.Item
                onClick={() => {
                  this.setState({
                    edittableRoutineId: record.id,
                    edittableData: [],
                  });
                }}
              >
                Edit
              </Menu.Item>
            </Menu>
          );

          return (
            <Dropdown overlay={menu} trigger={['click']}>
              <Icon style={{ fontSize: 26 }} type="more" />
            </Dropdown>
          );
        },
      },
    ];
  }

  getKeyIndexInArray = (arr, key) => {
    return arr.findIndex((o) => {
      return o.hasOwnProperty(key);
    });
  }

  addPriorityChangeToEditableData = (priority) => {
    // just to avoid colateral effects
    const currentData = this.getCopyOfEdittableData();
    const keyIndexInArray = this.getKeyIndexInArray(currentData, 'priority');
    const newPriority = {
      priority
    };
    if (keyIndexInArray > -1) {
      // update it
      currentData.splice(keyIndexInArray, 1, newPriority);
    } else {
      // add it
      currentData.push(newPriority);
    }
    this.setState({
      edittableData: currentData,
    });
  }

  fetchData = async (filters, tableStore = this.tableStore) => {
    if (!ShopStore.isAdmin()) {
      await ShopStore.getRoutinesList(tableStore);
    }
  };

  getCopyOfEdittableData = () => {
    return this.state.edittableData ? JSON.parse(JSON.stringify(this.state.edittableData)) : [];
  }

  onChangeOnEditingDate = (date) => {
    // just to avoid colateral effects
    const currentData = this.getCopyOfEdittableData();
    const keyIndexInArray = this.getKeyIndexInArray(currentData, 'fixed_month');
    const newItem = {
      fixed_month: moment(date).format('X'),
    };
    if (keyIndexInArray > -1) {
      // update it
      currentData.splice(keyIndexInArray, 1, newItem);
    } else {
      // add it
      currentData.push(newItem);
    }
    this.setState({
      edittableData: currentData,
    });
  }

  onChangeOnEditing = (e) => {
    let objToEdit = {};

    if (_.includes(e.target.name, 'threshold')) {
      let names = _.split(e.target.name, '_');
      let key = names[1];

      objToEdit.threshold = {
        ...objToEdit?.threshold,
        [key]: e.target.value,
      };
    } else {
      objToEdit[e.target.name] = e.target.value;
    }

    const toEditKey = Object.keys(objToEdit)[0];

    // just to avoid colateral effects
    const currentData = this.getCopyOfEdittableData();
    const keyIndexInArray = this.getKeyIndexInArray(currentData, toEditKey);
    const newItem = {
      ...objToEdit,
    };
    if (newItem.interval_engine_hours) {
      newItem.interval_engine_hours = convertHourToSeconds(newItem.interval_engine_hours);
    }
    if (keyIndexInArray > -1) {
      // update it
      currentData.splice(keyIndexInArray, 1, newItem);
    } else {
      // add it
      currentData.push(newItem);
    }
    this.setState({
      edittableData: currentData,
    });
  };

  generateRoutineInfo = (routineId, routineInfo) => {
    let result = [];

    const defaultMonthVal = routineInfo.fixed_month && moment.unix(routineInfo.fixed_month).isValid()
      ? moment.unix(routineInfo.fixed_month)
      : null;

    if (routineInfo.fixed_engine_hours) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Fixed engine hours:</p>
            <Input
              name="fixed_engine_hours"
              defaultValue={routineInfo.fixed_engine_hours}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(
          `Fixed engine hours: ${Math.round(
            convertSecondsToHours(routineInfo.fixed_engine_hours)
          ).toReadable()}`
        );
      }
    }

    if (routineInfo.fixed_mileage) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Fixed mileage:</p>
            <Input
              name="fixed_mileage"
              defaultValue={calcMileage(Number(routineInfo.fixed_mileage)).toReadable()}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(
          `Fixed mileage: ${calcMileage(Number(routineInfo.fixed_mileage)).toReadable()} ${CurrentUserStore.user.displayedOdometer}`
        );
      }
    }

    if (routineInfo.fixed_month) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Fixed month:</p>
            {/* <Input
              name="fixed_month"
              defaultValue={routineInfo.fixed_month}
              onChange={this.onChangeOnEditing}
            /> */}
            <MonthPicker
              name="fixed_month"
              picker="month"
              defaultValue={defaultMonthVal}
              format={'MMMM / YYYY'}
              onChange={this.onChangeOnEditingDate}
            />
          </>
        );
      } else {
        result.push(
          `Fixed month: ${defaultMonthVal ? defaultMonthVal.format('MMMM / YYYY') : 'N/A'}`
        );
      }
    }

    if (routineInfo.interval_engine_hours) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Interval engine hours:</p>
            <Input
              name="interval_engine_hours"
              defaultValue={Math.round(
                convertSecondsToHours(routineInfo.interval_engine_hours)
              )}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(
          `Interval engine hours: ${Math.round(
            convertSecondsToHours(routineInfo.interval_engine_hours)
          )} hours`
        );
      }
    }

    if (routineInfo.interval_mileage) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Interval mileage:</p>
            <Input
              name="interval_mileage"
              defaultValue={calcMileage(Number(routineInfo.interval_mileage)).toReadable()}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(
          `Interval mileage: ${calcMileage(Number(routineInfo.interval_mileage)).toReadable()} ${CurrentUserStore.user.displayedOdometer}`
        );
      }
    }

    if (routineInfo.interval_month) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Interval months:</p>
            <Input
              name="interval_month"
              defaultValue={routineInfo.interval_month}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(`Interval months: ${routineInfo.interval_month} months`);
      }
    }

    if (routineInfo?.threshold?.engineHours) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Alert before:</p>
            <Input
              name="threshold_engineHours"
              defaultValue={routineInfo.threshold.engineHours}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(
          `Alert before: ${routineInfo.threshold.engineHours} engine hours`
        );
      }
    }

    if (routineInfo?.threshold?.mileage) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Fixed Engine Hours:</p>
            <Input
              name="threshold_mileage"
              defaultValue={routineInfo.threshold.mileage}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(`Alert before: ${calcMileage(Number(routineInfo.threshold.mileage)).toReadable()} ${CurrentUserStore.user.displayedOdometer}`);
      }
    }

    if (routineInfo?.threshold?.time) {
      if (routineId === this.state.edittableRoutineId) {
        result.push(
          <>
            <p>Alert before:</p>
            <Input
              name="threshold_time"
              defaultValue={routineInfo.threshold.time}
              onChange={this.onChangeOnEditing}
            />
          </>
        );
      } else {
        result.push(`Alert before: ${routineInfo.threshold.time} days`);
      }
    }

    if (_.isEmpty(result)) return 'N/A';

    return (
      <ul id="routines-list">
        {_.map(result, (item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  generateCloseCheckIcons = () => {
    return (
      <span id="close-check">
        <Tooltip title="Cancel ?">
          <Icon
            key="close-circle"
            type="close-circle"
            onClick={() => this.setState({ edittableRoutineId: null })}
          />
        </Tooltip>

        <Tooltip title="Confirm ?">
          <Icon
            key="check-circle"
            type="check-circle"
            onClick={async () => {
              try {
                let { edittableRoutineId, edittableData } = this.state;

                if (_.isEmpty(edittableData)) {
                  this.setState({ edittableRoutineId: null });
                  return;
                }

                AppStore.addLoading('Updating routine...');

                const dataToUpdate = edittableData.reduce((acc, current) => {
                  const key = Object.keys(current)[0];
                  acc[key] = current[key];
                  return acc;
                }, {});

                await ShopStore.updateRoutineById(
                  edittableRoutineId,
                  dataToUpdate
                );

                this.setState({ edittableRoutineId: null });

                await this.fetchData();
              } catch (err) {
                AppStore.addError(`Error is updating: ${err.message}`);
              } finally {
                AppStore.removeLoading('Updating routine...');
              }
            }}
          />
        </Tooltip>
      </span>
    );
  };

  render() {
    return (
      <Container>
        <Row gutter={[8, 8]}>
          <Col>
            <p style={{ fontWeight: 600 }}>Current routines list:</p>
          </Col>

          <Col>
            <PitstopTable
              id={`${ShopStore.currentShop.id}-routines-list`}
              columns={this.columns}
              data={this.dataSource}
              pagination={this.customPagination}
              fetchData={(filters, table) => {
                this.fetchData(filters, table);
              }}
              rowKey="id"
              getTableStore={(tableStore) => (this.tableStore = tableStore)}
              expandedRowRender={(record) => {
                return (
                  <Table
                    dataSource={record.cars}
                    columns={[
                      {
                        key: 'car_name',
                        dataIndex: 'car_name',
                        title: 'Unit ID',
                      },
                      {
                        key: 'vin',
                        dataIndex: 'vin',
                        title: 'vin',
                      },
                      {
                        key: 'mmy',
                        title: 'Make - Model - Year',
                        render: (record) => {
                          return _.join(
                            [
                              record.car_make,
                              record.car_model,
                              record.car_year,
                            ],
                            ' - '
                          );
                        },
                      },
                      {
                        key: 'mileage_total',
                        dataIndex: 'mileage_total',
                        title: 'Mileage',
                        render: (record) => {
                          return `${Math.round(
                            calcMileage(Number(record))
                          ).toReadable()} ${CurrentUserStore.user.displayedOdometer}`;
                        },
                      },
                      {
                        key: 'engine_hours',
                        dataIndex: 'engine_hours',
                        title: 'Engine Hours',
                        render: (record) => {
                          return Math.round(
                            convertSecondsToHours(record)
                          ).toReadable();
                        },
                      },
                    ]}
                    rowKey="vin"
                  />
                );
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

decorate(RoutinesTableList, {
  tableStore: observable,
  dataSource: computed,
  fetchData: action,
});

export default observer(RoutinesTableList);
