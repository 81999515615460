import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Col,
  Row,
  List,
  Typography,
  Skeleton,
  Select,
  InputNumber,
  message,
} from 'antd';

import { convertToMonetary } from 'shared/utils';

const { Text } = Typography;
const { Option } = Select;

const Wrapper = styled.div`
  border: solid 1px #ddd;
  padding: 20px;
`;

class WorkOrderCostSummary extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    lineItems: PropTypes.array,
    tax: PropTypes.object,
    misc: PropTypes.object,
    onChangeTaxType: PropTypes.func,
    onChangeTaxValue: PropTypes.func,
    onChangeMiscType: PropTypes.func,
    onChangeMiscValue: PropTypes.func,
    currentWorkOrder: PropTypes.object,
    rawData: PropTypes.any,
  };

  defaultTaxObj = {
    type: 'percentage',
    value: 0,
  };

  defaultMiscObj = {
    type: 'percentage',
    value: 0,
  };

  state = {
    data: [],
    isPartsCostInitialized: false,
    isLaborCostInitialized: false,
    userUpdatedTaxValues: false,
    laborAmount: 0,
    partsAmount: 0,
    totalAmount: 0,
  };

  tax = this.props.tax;
  misc = this.props.misc;

  get localTax() {
    return this.props.tax;
  }

  get localMisc() {
    return this.props.misc;
  }

  onChangeTaxType = (value) => {
    this.setState({ userUpdatedTaxValues: true }, () => {
      if (this.props.onChangeTaxType) {
        this.props.onChangeTaxType(value);
      }
    });
  };

  onChangeTaxValue = (value) => {
    this.setState({ userUpdatedTaxValues: true }, () => {
      if (this.props.onChangeTaxValue) {
        this.props.onChangeTaxValue(value);
      }
    });
  };

  onChangeMiscType = (value) => {
    this.setState({ userUpdatedTaxValues: true }, () => {
      if (this.props.onChangeMiscType) {
        this.props.onChangeMiscType(value);
      }
    });
  };

  onChangeMiscValue = (value) => {
    this.setState({ userUpdatedTaxValues: true }, () => {
      if (this.props.onChangeMiscValue) {
        this.props.onChangeMiscValue(value);
      }
    });
  };

  initializeCosts(rawData) {
    if (rawData) {
      if (
        !this.state.isPartsCostInitialized &&
        rawData['Parts Cost'] !== 'NOT FOUND'
      ) {
        this.setState({
          partsAmount: this.state.partsAmount + rawData['Parts Cost'],
          isPartsCostInitialized: true,
        });
      }

      if (
        !this.state.isLaborCostInitialized &&
        rawData['Labour Cost'] !== 'NOT FOUND'
      ) {
        this.setState({
          laborAmount: this.state.laborAmount + rawData['Labour Cost'],
          isLaborCostInitialized: true,
        });
      }

      if (
        rawData['Parts Cost'] === 'NOT FOUND' ||
        rawData['Labour Cost'] === 'NOT FOUND' ||
        rawData['Total Cost'] === 'NOT FOUND'
      ) {
        message.info(
          'Please verify the accuracy of the highlighted field in the cost summary'
        );
      }
    }
  }

  onLaborAmountChange = (value) => {
    this.setState({ laborAmount: value });
  };

  onPartsAmountChange = (value) => {
    this.setState({ partsAmount: value });
  };

  onTotalAmountChange = (value) => {
    this.setState({ totalAmount: value });
  };

  get currentLineItems() {
    return this.props.lineItems;
  }

  get summaryItems() {
    const { lineItems } = this.props;
    const isPartsCostCalculated =
      this.props.rawData == null ||
      this.props.rawData['Parts Cost'] === 'NOT FOUND';
    const isLaborCostCalculated =
      this.props.rawData == null ||
      this.props.rawData['Labour Cost'] === 'NOT FOUND';

    let partsAmount;
    let laborAmount;

    let currentWorkOrder = _.get(this, 'props.currentWorkOrder', {});
    if (currentWorkOrder && currentWorkOrder.partsTotal) {
      partsAmount = currentWorkOrder.partsTotal;
    } else {
      partsAmount = isPartsCostCalculated
        ? _.sumBy(lineItems, 'partsTotal')
        : this.state.partsAmount;
    }

    if (currentWorkOrder && currentWorkOrder.laborsTotal) {
      laborAmount = currentWorkOrder.laborsTotal;
    } else {
      laborAmount = isLaborCostCalculated
        ? _.sumBy(lineItems, 'laborTotal')
        : this.state.laborAmount;
    }

    this.setState({ laborAmount: laborAmount, partsAmount: partsAmount });

    // let costsTotal = this.total;

    return [
      {
        description: 'Labors',
        amount: laborAmount,
      },
      {
        description: 'Parts',
        amount: partsAmount,
      },
      {
        description: 'Tax',
        amount: this.localTax.value,
        amountType: this.localTax.type || 'absolute',
        extraFields: [
          <Select
            defaultValue={this.localTax.type || 'absolute'}
            onChange={this.onChangeTaxType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            defaultValue={this.localTax.value || 0}
            onChange={this.onChangeTaxValue}
            formatter={(value) =>
              `${
                this.localTax.type === 'absolute' ? '$ ' : ''
              }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100px', marginRight: '30px', marginLeft: '5px' }}
          />,
        ],
      },
      {
        description: 'Misc',
        amount:
          _.sumBy(this.currentLineItems, 'miscTotal') || this.localMisc.value, // must be a sum of misc totals in the lineItems
        amountType: this.localMisc.type || 'absolute',
        extraFields: [
          <Select
            defaultValue={this.localMisc.type || 'absolute'}
            onChange={this.onChangeMiscType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            defaultValue={this.localMisc.value || 0}
            onChange={this.onChangeMiscValue}
            formatter={(value) =>
              `${
                this.localMisc.type === 'absolute' ? '$ ' : ''
              }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100px', marginRight: '30px', marginLeft: '5px' }}
            disabled={_.sumBy(this.currentLineItems, 'miscTotal') > 0}
          />,
        ],
      },
    ];
  }

  get total() {
    let currentWorkOrder = _.get(this, 'props.currentWorkOrder', {});
    if (currentWorkOrder && currentWorkOrder.costsTotal) {
      this.onTotalAmountChange(currentWorkOrder.costsTotal);
      return currentWorkOrder.costsTotal;
    }

    let costsTotal;
    if (
      this.props.rawData === null ||
      this.props.rawData['Total Cost'] === 'NOT FOUND' ||
      this.state.userUpdatedTaxValues
    ) {
      // if (this.props.rawData !== null) {
      //   message.info(
      //     'Total cost is being estimated, please verify its accuracy'
      //   );
      // }
      costsTotal = _.sumBy(this.currentLineItems, (i) =>
        _.sum([i.laborTotal, i.partsTotal])
      );
      const taxValue =
        this.localTax.type === 'percentage'
          ? ((this.localTax.value || 0) / 100) * costsTotal
          : parseFloat(this.localTax.value || 0);
      costsTotal += taxValue;
      const miscValue =
        this.localMisc.type === 'percentage'
          ? ((this.localMisc.value || 0) / 100) * costsTotal
          : parseFloat(this.localMisc.value || 0);
      costsTotal += miscValue;
    } else {
      costsTotal = _.get(this, "props.rawData['Total Cost']", 0);
    }

    this.onTotalAmountChange(costsTotal);
    return costsTotal;
  }

  componentDidUpdate(prevProps) {
    if (this.props.rawData !== prevProps.rawData) {
      this.initializeCosts(this.props.rawData);
    }
  }

  componentDidMount() {
    const { childRef, rawData } = this.props;
    childRef(this);

    if (rawData) {
      this.initializeCosts(rawData);
    }
  }

  toPercentage(value = 0) {
    return value.toLocaleString('en-US', {
      style: 'percent',
    });
  }

  render() {
    const showExtraField = (item) => {
      if (item.extraFields && item.extraFields.length) {
        return item.extraFields.map((field) => field);
      }
      return <></>;
    };

    const showCurrencyOrPct = (item) => {
      if (item.amountType && item.amountType === 'percentage') {
        return <div>{this.toPercentage(item.amount / 100)}</div>;
      }
      return <div>{convertToMonetary(item.amount)}</div>;
    };

    return (
      <Wrapper>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              header={<Text strong>Cost Summary</Text>}
              footer={
                <List.Item>
                  <List.Item.Meta title={<Text strong>Total</Text>} />
                  <div>{convertToMonetary(this.total)}</div>
                </List.Item>
              }
              dataSource={this.summaryItems}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta title={item.description} />
                    {showExtraField(item)}
                    {showCurrencyOrPct(item)}
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

decorate(WorkOrderCostSummary, {
  currentLineItems: computed,
  total: computed,
  summaryItems: computed,
  localTax: computed,
  localMisc: computed,
  tax: observable,
  misc: observable,
});

export default observer(WorkOrderCostSummary);
