import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Col,
  Row,
  List,
  Typography,
  Skeleton,
  Select,
  InputNumber,
} from 'antd';

import { convertToMonetary } from 'shared/utils';
import FormLegend from 'components/Forms/FormLegend';
import WorkOrderSummary from './WorkOrderSummary';
import WorkOrderLineItemsSummary from './LineItems/WorkOrderLineItemsSummary';

const { Text } = Typography;
const { Option } = Select;

// border: solid 1px #ddd;
const Wrapper = styled.div`
  padding: 20px;
`;

class WorkOrderCostSummary extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    lineItems: PropTypes.array,
    tax: PropTypes.object,
    misc: PropTypes.object,
    onChangeTaxType: PropTypes.func,
    onChangeTaxValue: PropTypes.func,
    onChangeMiscType: PropTypes.func,
    onChangeMiscValue: PropTypes.func,
    currentWorkOrder: PropTypes.object,
    shopUsers: PropTypes.array,
    shopParts: PropTypes.array,
    currentCarIssues: PropTypes.array,
  };

  defaultTaxObj = {
    type: 'percentage',
    value: 0,
  };

  defaultMiscObj = {
    type: 'percentage',
    value: 0,
  };

  state = {
    data: [],
  };

  tax = this.props.tax;
  misc = this.props.misc;

  get localTax() {
    return this.props.tax;
  }

  get localMisc() {
    return this.props.misc;
  }

  onChangeTaxType = (value) => {
    if (this.props.onChangeTaxType) {
      this.props.onChangeTaxType(value);
    }
  };

  onChangeTaxValue = (value) => {
    if (this.props.onChangeTaxValue) {
      this.props.onChangeTaxValue(value);
    }
  };

  onChangeMiscType = (value) => {
    if (this.props.onChangeMiscType) {
      this.props.onChangeMiscType(value);
    }
  };

  onChangeMiscValue = (value) => {
    if (this.props.onChangeMiscValue) {
      this.props.onChangeMiscValue(value);
    }
  };

  get currentLineItems() {
    return this.props.lineItems;
  }

  get summaryItems() {
    return [
      {
        description: 'Labors',
        amount: _.sumBy(this.currentLineItems, 'laborTotal'),
      },
      {
        description: 'Parts',
        amount: _.sumBy(this.currentLineItems, 'partsTotal'),
      },
      {
        description: 'Tax',
        amount: this.localTax.value,
        amountType: this.localTax.type || 'percentage',
        extraFields: [
          <Select
            defaultValue={this.localTax.type || 'percentage'}
            onChange={this.onChangeTaxType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            defaultValue={this.localTax.value || 0}
            onChange={this.onChangeTaxValue}
            formatter={(value) =>
              `${
                this.localTax.type === 'absolute' ? '$ ' : ''
              }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100px', marginRight: '30px', marginLeft: '5px' }}
          />,
        ],
      },
      {
        description: 'Misc',
        amount: this.localMisc.value,
        amountType: this.localMisc.type || 'percentage',
        extraFields: [
          <Select
            defaultValue={this.localMisc.type || 'percentage'}
            onChange={this.onChangeMiscType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            defaultValue={this.localMisc.value || 0}
            onChange={this.onChangeMiscValue}
            formatter={(value) =>
              `${
                this.localMisc.type === 'absolute' ? '$ ' : ''
              }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100px', marginRight: '30px', marginLeft: '5px' }}
          />,
        ],
      },
    ];
  }

  get total() {
    let costsTotal = _.sumBy(this.currentLineItems, (i) =>
      _.sum([i.laborTotal, i.partsTotal])
    );
    const taxValue =
      this.localTax.type === 'percentage'
        ? ((this.localTax.value || 0) / 100) * costsTotal
        : parseFloat(this.localTax.value || 0);
    costsTotal += taxValue;
    const miscValue =
      this.localMisc.type === 'percentage'
        ? ((this.localMisc.value || 0) / 100) * costsTotal
        : parseFloat(this.localMisc.value || 0);
    costsTotal += miscValue;
    return costsTotal;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  toPercentage(value = 0) {
    return value.toLocaleString('en-US', {
      style: 'percent',
    });
  }

  render() {
    const showExtraField = (item) => {
      if (item.extraFields && item.extraFields.length) {
        return item.extraFields.map((field) => field);
      }
      return <></>;
    };

    const showCurrencyOrPct = (item) => {
      if (item.amountType && item.amountType === 'percentage') {
        return <div>{this.toPercentage(item.amount / 100)}</div>;
      }
      return <div>{convertToMonetary(item.amount)}</div>;
    };

    return (
      <Wrapper className="limit-size-tablets">
        <Row gutter={[8, 8]} className="limit-size-tablets">
          <Col span={24}>
            <FormLegend>Work Order Details</FormLegend>
            <WorkOrderSummary
              workOrder={this.props.currentWorkOrder}
              shopUsers={this.props.shopUsers}
            />
          </Col>
          <Col span={24}>
            <WorkOrderLineItemsSummary
              lineItems={this.currentLineItems}
              shopUsers={this.props.shopUsers}
              shopParts={this.props.shopParts}
              currentCarIssues={this.props.currentCarIssues}
            />
          </Col>
          <Col span={24}>
            <FormLegend>Cost Summary</FormLegend>
            <List
              footer={
                <List.Item>
                  <List.Item.Meta title={<Text strong>Total</Text>} />
                  <div>{convertToMonetary(this.total)}</div>
                </List.Item>
              }
              dataSource={this.summaryItems}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta title={item.description} />
                    {showExtraField(item)}
                    {showCurrencyOrPct(item)}
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

decorate(WorkOrderCostSummary, {
  currentLineItems: computed,
  total: computed,
  summaryItems: computed,
  localTax: computed,
  localMisc: computed,
  tax: observable,
  misc: observable,
});

export default observer(WorkOrderCostSummary);
