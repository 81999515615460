import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Button } from 'antd';
import WorkOrderLineItemPartsItem from './WOLineItemPartsItem';

class WorkOrderLineItemParts extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    onUpdateLineTotal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.any,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    shopParts: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  };

  state = {};

  partsItemsRefs = {};

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  onAddItem = () => {
    this.forceUpdate();
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  };

  onUpdateLineTotal = (index, lineTotal) => {
    if (this.props.onUpdateLineTotal) {
      this.props.onUpdateLineTotal(index, lineTotal);
    }
  };

  render() {
    if (!_.isEmpty(this.props.items)) {
      const renderPartAddButtons = () => {
        return (
          <div style={{ marginLeft: 35 }}>
            <Button
              icon="plus-circle"
              type="link"
              onClick={this.props.onAddItem}
            >
              Add Existing Part
            </Button>
            <Button
              icon="plus-circle"
              type="link"
              onClick={() => {
                if (this.props.onAddNewPart) {
                  this.props.onAddNewPart();
                }
              }}
            >
              Add New Part
            </Button>
          </div>
        );
      };
      return (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {this.props.items.map((mapItem, index) => {
              return (
                <div key={mapItem.key}>
                  <WorkOrderLineItemPartsItem
                    displayIcon={index === 0}
                    key={mapItem.key}
                    item={mapItem}
                    shopParts={this.props.shopParts}
                    childRef={(ref) => (this.partsItemsRefs[index] = ref)}
                    onUpdateTotal={(lineTotal) =>
                      this.onUpdateLineTotal(index, lineTotal)
                    }
                    onRemoveItem={this.onRemoveItem}
                    onAddNewPart={this.props.onAddNewPart}
                    onUpdateLocalItem={(newVal, changedField) =>
                      this.props.onUpdateLocalItem(
                        mapItem.key,
                        newVal,
                        changedField
                      )
                    }
                    isDisabled={this.props.isDisabled}
                  />
                  {index === this.props.items.length - 1 &&
                    renderPartAddButtons()}
                </div>
              );
            })}
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

decorate(WorkOrderLineItemParts, {});

export default observer(WorkOrderLineItemParts);
