import { observable, computed, decorate, action } from 'mobx';
import moment from 'moment-timezone';
import _ from 'lodash';

import { webServiceProvider } from 'shared';
import { errors } from 'helpers';
import { calcMileage } from 'helpers/unitCalculations';

import { TableStore, AbstractStore } from 'stores/abstract';
import { ShopStore, CurrentUserStore } from 'stores';
import { ReportCacheStore } from 'stores/CacheStore';
import DateRangeEnum from 'helpers/DateRangeEnum';

class ReportsDataStoreC extends AbstractStore {
  startDate = moment()
    .startOf('day')
    .subtract(1, 'years');
  endDate = moment().endOf('day');

  maintenanceSummary = new TableStore();
  maintenanceSummaryInRange = new TableStore();
  dvirStatusSummary = new TableStore();
  alarmReport = new TableStore();
  drivingReport = new TableStore();
  systemWatcherReport = new TableStore();
  vehiclesScoreReport = new TableStore();
  vehicleHealthScoreReport = new TableStore();
  highPriorityVehicles = new TableStore();
  engineIdleScoreReport = new TableStore();
  vehiclesWithSpecificIssues = new TableStore();
  vehiclesWithDEFIssues = new TableStore();
  roiReport = new TableStore();
  predictiveInsightReport = new TableStore();
  brakeUsageReport = new TableStore();
  vmrsReport = new TableStore();
  defSystemHealthReport = new TableStore();
  algorithmStatsReport = new TableStore();
  vehicleCostStatsReport = new TableStore();

  maintenanceSummaryController = new AbortController();
  maintenanceSummaryInRangeController = new AbortController();
  dvirStatusSummaryController = new AbortController();
  alarmReportController = new AbortController();
  drivingReportController = new AbortController();
  systemWatcherController = new AbortController();
  vehiclesScoreController = new AbortController();
  vehicleHealthScoreController = new AbortController();
  engineIdleScoreController = new AbortController();
  vehiclesWithSpecificIssuesController = new AbortController();
  vehiclesWithDEFIssuesController = new AbortController();
  highPriorityVehiclesController = new AbortController();
  roiReportController = new AbortController();
  predictiveInsightController = new AbortController();
  brakeUsageController = new AbortController();
  vmrsReportController = new AbortController();
  defSystemHealthController = new AbortController();
  algorithmStatsController = new AbortController();
  vehicleCostStatsController = new AbortController();

  dateRangeValue = DateRangeEnum.SEVEN_DAYS;

  get csvReport() {
    let report = [];

    let drivingReport = _.map(this.drivingReport.data, (object) => {
      return _.omit(object, ['services']);
    });

    let mergedReports = _.merge(
      _.keyBy(drivingReport, 'id_car'),
      _.keyBy(this.alarmReport.data, 'id_car')
    );

    report = _.values(mergedReports);

    return report;
  }

  get serviceReport() {
    let carIds = _.keys(this.maintenanceSummary);

    let reports = [];

    _.forEach(carIds, (carId) => {
      let critical = 0,
        major = 0,
        minor = 0;

      let report = _.get(this.maintenanceSummary, carId);

      _.forEach(report, (item) => {
        if (item.priority === 0 || item.priority === 1) {
          minor += item.count;
        }

        if (item.priority === 2 || item.priority === 3) {
          major += item.count;
        }

        if (item.priority === 4 || item.priority === 5) {
          critical += item.count;
        }
      });

      reports.push({
        carId,
        total_service_count: carIds.length,
        critical_service_count: critical,
        major_service_count: major,
        minor_service_count: minor,
      });
    });

    return reports;
  }

  get totalServiceCount() {
    let summary = this.maintenanceSummary.data;

    let critical = 0,
      major = 0,
      minor = 0;

    _.forEach(summary, (data) => {
      _.forEach(data[1], (item) => {
        if (item.priority === 0 || item.priority === 1) {
          minor += item.count;
        }

        if (item.priority === 2 || item.priority === 3) {
          major += item.count;
        }

        if (item.priority === 4 || item.priority === 5) {
          critical += item.count;
        }
      });
    });

    let result = {
      total: _.sum([critical, major, minor]),
      critical: critical,
      major: major,
      minor: minor,
    };

    return result;
  }

  get totalServiceCountInRange() {
    let summary = this.maintenanceSummaryInRange.data;

    let critical = 0,
      major = 0,
      minor = 0;

    _.forEach(summary, (data) => {
      _.forEach(data[1], (item) => {
        if (item.priority === 0 || item.priority === 1) {
          minor += item.count;
        }

        if (item.priority === 2 || item.priority === 3) {
          major += item.count;
        }

        if (item.priority === 4 || item.priority === 5) {
          critical += item.count;
        }
      });
    });

    let result = {
      total: _.sum([critical, major, minor]),
      critical: critical,
      major: major,
      minor: minor,
    };

    return result;
  }

  get numberCriticalVehicles() {
    return this.highPriorityVehicles.data.filter((item) => item.critical > 0)
      .length;
  }

  get totalServiceCountPerCar() {
    let summary = this.maintenanceSummary.data;

    const toReturn = [];

    _.forEach(summary, (data) => {
      let critical = 0,
        major = 0,
        minor = 0;
      const carId = data[0];
      _.forEach(data[1], (item) => {
        if (item.priority === 0 || item.priority === 1) {
          minor += item.count;
        }

        if (item.priority === 2 || item.priority === 3) {
          major += item.count;
        }

        if (item.priority === 4 || item.priority === 5) {
          critical += item.count;
        }
      });
      toReturn.push({
        carId,
        critical_service_count: critical,
        major_service_count: major,
        minor_service_count: minor,
        total: _.sum([critical, major, minor]),
      });
    });

    return toReturn;
  }

  get maxServicePriorityPerCar() {
    let summary = this.maintenanceSummary.data;

    let critical = 0,
      major = 0,
      majorCallDriver = 0,
      majorNoCall = 0,
      minor = 0;

    _.forEach(summary, (data) => {
      let maxPriority = 0;
      let maxMajorDate = Math.floor(new Date('1970-01-01').getTime() / 1000);
      _.forEach(data[1], (item) => {
        let issueDate = new Date(item.created_at).getTime() / 1000;
        if (
          (item.priority === 2 || item.priority === 3) &&
          issueDate > maxMajorDate
        ) {
          maxMajorDate = issueDate;
        }
        if (item.priority > maxPriority) {
          maxPriority = item.priority;
        }
      });
      if (maxPriority === 0 || maxPriority === 1) {
        minor += 1;
      }

      if (maxPriority === 2 || maxPriority === 3) {
        major += 1;
        //Checking if it's been less than two weeks since the most recent major
        if (Date.now() / 1000 - maxMajorDate < 604800) {
          majorCallDriver += 1;
        } else {
          majorNoCall += 1;
        }
      }

      if (maxPriority === 4 || maxPriority === 5) {
        critical += 1;
      }
    });
    const toReturn = {
      critical: critical,
      major: major,
      majorCallDriver: majorCallDriver,
      majorNoCall: majorNoCall,
      minor: minor,
    };
    return toReturn;
  }

  setCurrentSelectedDateRange(dateRange) {
    this.dateRangeValue = dateRange;
  }

  get currentSelectedDateRange() {
    return this.dateRangeValue;
  }

  get currentSelectedDateRangeDescription() {
    switch (this.dateRangeValue) {
      case DateRangeEnum.ONE_DAY:
        return '1 Day';
      case DateRangeEnum.THREE_DAYS:
        return '3 Days';
      case DateRangeEnum.ONE_MONTH:
        return '1 Month';
      case DateRangeEnum.THREE_MONTHS:
        return '3 Months';
      case DateRangeEnum.SEVEN_DAYS:
      default:
        return '7 Days';
    }
  }

  get currentSelectedDateRangeDays() {
    switch (this.dateRangeValue) {
      case DateRangeEnum.ONE_DAY:
        return 1;
      case DateRangeEnum.THREE_DAYS:
        return 3;
      case DateRangeEnum.ONE_MONTH:
        return 30;
      case DateRangeEnum.THREE_MONTHS:
        return 90;
      case DateRangeEnum.SEVEN_DAYS:
      default:
        return 7;
    }
  }

  get reportMajorOrCriticalCarIdsInRange() {
    const summary = this.maintenanceSummaryInRange.data;
    return summary
      .filter((item) => {
        let maxPriority = 0;
        _.forEach(item[1], (item) => {
          if (item.priority > maxPriority) {
            maxPriority = item.priority;
          }
        });
        return maxPriority > 1;
      })
      .map((item) => parseInt(item[0]));
  }

  get maxServicePriorityPerCarInRange() {
    let summary = this.maintenanceSummaryInRange.data;
    //The threshold for stale alerts (currently 2 weeks) in milliseconds
    const staleMajorThreshold = 1000 * 60 * 60 * 24 * 14;
    let critical = 0,
      major = 0,
      majorCallDriver = 0,
      majorNoCall = 0,
      minor = 0;

    _.forEach(summary, (data) => {
      let maxPriority = 0;
      //Stored as a Unix Timestamp
      let maxMajorDate = 0;
      _.forEach(data[1], (item) => {
        if (maxMajorDate < Date.parse(item.created_at)) {
          maxMajorDate = Date.parse(item.created_at);
        }
        if (item.priority > maxPriority) {
          maxPriority = item.priority;
        }
      });
      if (maxPriority === 0 || maxPriority === 1) {
        minor += 1;
      }

      if (maxPriority === 2 || maxPriority === 3) {
        major += 1;
        if (maxMajorDate > Date.now() - staleMajorThreshold) {
          majorCallDriver += 1;
        } else {
          majorNoCall += 1;
        }
      }

      if (maxPriority === 4 || maxPriority === 5) {
        critical += 1;
      }
    });
    const toReturn = {
      critical: critical,
      major: major,
      majorCallDriver: majorCallDriver,
      majorNoCall: majorNoCall,
      minor: minor,
    };
    return toReturn;
  }

  get totalDrivingDistance() {
    let sum = 0;

    _.map(this.drivingReport.data, (item) => {
      const distance = calcMileage(parseFloat(_.get(item, 'total_distance')));

      if (!isNaN(distance)) {
        sum += distance;
      }
    });

    return Math.round(sum);
  }

  get totalSpeedingDistance() {
    let sum = 0;

    _.map(this.alarmReport.data, (item) => {
      const distance = calcMileage(
        parseFloat(_.get(item, 'speeding_distance_sum'))
      );

      if (!isNaN(distance)) {
        sum += distance;
      }
    });

    return Math.round(sum);
  }

  get totalDrivingTime() {
    let sum = 0;

    _.map(this.drivingReport.data, (item) => {
      const time = parseFloat(_.get(item, 'total_trip_time'));
      if (!isNaN(time)) {
        sum += time;
      }
    });

    // in mins
    return Math.round(sum / 60);
  }

  get totalIdlingTime() {
    let sum = 0;

    _.map(this.alarmReport.data, (item) => {
      const idleTime = parseFloat(_.get(item, 'idling_sum'));
      if (!isNaN(idleTime)) {
        sum += idleTime;
      }
    });

    // in mins
    return Math.round(sum / 60);
  }

  get safetyViolationCount() {
    let idlingEventCount = 0;
    let accelerationEventCount = 0;
    let brakingEventCount = 0;
    let speedingEventCount = 0;

    _.forEach(this.alarmReport.data, (item) => {
      const highSpeedEvent = parseFloat(_.get(item, 'high_speed_event_count'));
      const accelerationEvent = parseFloat(
        _.get(item, 'acceleration_event_count')
      );
      const brakingEvent = parseFloat(_.get(item, 'braking_event_count'));
      const idlingEvent = parseFloat(_.get(item, 'idling_event_count'));

      if (!isNaN(highSpeedEvent)) {
        speedingEventCount += highSpeedEvent;
      }
      if (!isNaN(accelerationEvent)) {
        accelerationEventCount += accelerationEvent;
      }
      if (!isNaN(brakingEvent)) {
        brakingEventCount += brakingEvent;
      }
      if (!isNaN(idlingEventCount)) {
        idlingEventCount += idlingEvent;
      }
    });

    return {
      accelerationEventCount,
      brakingEventCount,
      speedingEventCount,
      idlingEventCount,
    };
  }

  getReportKey = (shopId, type, startDate, endDate) => {
    let keys = [shopId, type, startDate, endDate];

    return `${keys.join('-')}-report`;
  };

  getDvirStatusSummary = async () => {
    let key = this.getReportKey(ShopStore.currentShop.id, 'dvirStatusSummary');
    const shopId = ShopStore.currentShop.id;
    const tableStore = new TableStore();
    this.dvirStatusSummaryController.abort();
    // make sure to clear the dvirStatusSummary data
    this.dvirStatusSummary = tableStore;
    this.dvirStatusSummary = ReportCacheStore.getData(key);
    try {
      if (_.isNil(this.dvirStatusSummary)) {
        this.dvirStatusSummary = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.dvirStatusSummaryController = new AbortController();

        let result = await webServiceProvider.get(
          `v1/dvirs/shop?shopId=${shopId}`);

        if(result.data[0]?.carId === 0 && result.data[0].record === null && result.data.length === 1) {
          result = {data: []};
        }
        
        const statusCounts = {safe: result.data.filter(item => item?.record?.safetyStatus === 'safe').length,
                              unsafe: result.data.filter(item => item?.record?.safetyStatus === 'unsafe').length,
                              resolved: result.data.filter(item => item?.record?.safetyStatus === 'resolved').length,
                              result: result.data // Whole result for DVIR by shopID
                            };

        tableStore.data = statusCounts;
        tableStore.setDataMetadata(result.data.length);

        ReportCacheStore.setData(key, tableStore);
        return statusCounts;
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
    }
  };

  getShopTrailersDvirStatusSummary = async (shopId) => {
    if (!shopId || shopId === '-1' || shopId === -1) {
      return { safe: 0, unsafe: 0, resolved: 0, result: [] };
    }

    let result = await webServiceProvider.get(
      `v1/dvirs/shop?shopId=${shopId}&trailers=true`
    );

    if (result.data[0]?.carId === 0 && result.data[0].record === null && result.data.length === 1) {
      result = { data: [] };
    }
    
    const statusCounts = {
      safe: result.data.filter(item => item?.record?.safetyStatus === 'safe').length,
      unsafe: result.data.filter(item => item?.record?.safetyStatus === 'unsafe').length,
      resolved: result.data.filter(item => item?.record?.safetyStatus === 'resolved').length,
      result: result.data // Whole result for DVIR by shopID
    };

    return statusCounts;
  }

  refreshMaintenanceSummaryReportInRange = async (startDate, endDate) => {
    this.startDate = startDate;
    this.endDate = endDate;

    await this.getMaintenanceSummaryReportInRange(startDate, endDate);
  };

  getMaintenanceSummaryReportInRange = async (startDate, endDate) => {
    let key = this.getReportKey(
      ShopStore.currentShop.id,
      'maintenanceSummaryInRange',
      moment(startDate).toISOString(),
      moment(endDate).toISOString()
    );

    const tableStore = new TableStore();
    this.maintenanceSummaryInRangeController.abort();
    // make sure to clear the maintenanceSummaryInRange data
    this.maintenanceSummaryInRange = tableStore;
    this.maintenanceSummaryInRange = ReportCacheStore.getData(key);

    try {
      if (_.isNil(this.maintenanceSummaryInRange)) {
        this.maintenanceSummaryInRange = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.maintenanceSummaryInRangeController = new AbortController();

        let result = await this.getSummaryReport(
          {
            shopId: ShopStore.currentShop.id,
            type: 'maintenanceSummaryInRange',
            startDate: moment(startDate).toISOString(),
            endDate: moment(endDate).toISOString(),
          },
          this.maintenanceSummaryInRangeController.signal
        );

        let data = _.map(result, (value, key) => {
          return [key, value];
        });

        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! Unable to load maintenance summary report in range!'
      );
    }
  };

  getMaintenanceSummaryReport = async (
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) => {
    let key = this.getReportKey(
      shopId,
      'maintenanceSummary',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );

    this.maintenanceSummaryController.abort();

    this.maintenanceSummary = ReportCacheStore.getData(key);

    try {
      if (_.isNil(this.maintenanceSummary)) {
        this.maintenanceSummary = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.maintenanceSummaryController = new AbortController();
        this.maintenanceSummaryReportSignal = this.maintenanceSummaryController.signal;

        let result = await this.getSummaryReport(
          {
            shopId,
            type: 'maintenanceSummary',
            startDate: moment(this.startDate).toISOString(),
            endDate: moment(this.endDate).toISOString(),
          },
          this.maintenanceSummaryReportSignal
        );

        let data = _.map(result, (value, key) => {
          return [key, value];
        });

        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! Unable to load maintenance summary report!');
    }
  };

  async getDrivingReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'drivingUtilization',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );

    this.drivingReportController.abort();

    this.drivingReport = ReportCacheStore.getData(key);

    try {
      if (_.isNil(this.drivingReport)) {
        this.drivingReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.drivingReportController = new AbortController();
        this.drivingReportSignal = this.drivingReportController.signal;

        let result = await this.getSummaryReport(
          {
            shopId,
            type: 'drivingUtilization',
            startDate: moment(this.startDate).toISOString(),
            endDate: moment(this.endDate).toISOString(),
          },
          this.drivingReportSignal
        );

        tableStore.data = result;
        tableStore.setDataMetadata(tableStore.data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.logErrorWithoutAlert(err, 'Sorry! Unable to load driving report!');
    }
  }

  async removeDrivingReports(shopId) {
    try {
      await webServiceProvider.delete(
        `v1/summaryReport/${shopId}/drivingUtilization`
      );
    } catch (err) {
      errors.response({
        err,
        message: 'Error in remove driving report cache!',
      });
    }
  }

  async getAlarmReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'alarm',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );

    this.alarmReportController.abort();

    this.alarmReport = ReportCacheStore.getData(key);

    try {
      if (_.isNil(this.alarmReport)) {
        this.alarmReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.alarmReportController = new AbortController();
        this.alarmReportSignal = this.alarmReportController.signal;

        const report = await this.getSummaryReport(
          {
            shopId,
            type: 'alarm',
            startDate: moment(this.startDate).toISOString(),
            endDate: moment(this.endDate).toISOString(),
          },
          this.alarmReportSignal
        );

        tableStore.data = report;
        tableStore.setDataMetadata(tableStore.data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! Unable to load alarm report!');
    }
  }

  async getSummaryReport(
    params = {
      shopId: ShopStore.currentShop.id,
      type: '',
      startDate: moment(this.startDate)
        .startOf('date')
        .toISOString(),
      endDate: moment(this.endDate)
        .startOf('date')
        .toISOString(),
    },
    signal
  ) {
    try {
      // customer role not have access to driving and alarms report
      if (CurrentUserStore.user.role === 'customer') {
        return;
      }

      if (_.isEmpty(params.type)) {
        throw Error('Type of report is missing!');
      }

      let { result } = await webServiceProvider.getMany(
        'v1/summaryReport',
        params,
        signal
      );

      return result;
    } catch (err) {
      throw err;
    }
  }

  async getSystemWatcherReports(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore(),
    startDate = moment()
      .startOf('day')
      .subtract(1, 'years'),
    endDate = moment().startOf('day')
  ) {
    let key = this.getReportKey(shopId, 'systemWatcher', startDate, endDate);

    try {
      this.systemWatcherController.abort();

      this.systemWatcherReport = ReportCacheStore.getData(key);

      if (_.isNil(this.systemWatcherReport)) {
        this.systemWatcherReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.systemWatcherController = new AbortController();
        this.systemWatcherSignal = this.systemWatcherController.signal;

        let params = {
          dateFrom: moment(startDate).toISOString(),
          dateTo: moment(endDate).toISOString(),
          shopID: shopId,
        };

        let report = await webServiceProvider.getMany(
          'v1/labs/watcher',
          params,
          this.systemWatcherSignal
        );

        tableStore.data.push(report);
        tableStore.setDataMetadata(tableStore.data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! Unable to get system watcher report!');
    }
  }
  fetchDVIRsForCars = async (carIds) => {
    try {
      const { data } = await webServiceProvider.get(
        `v1/dvirs/latest?carIds=${carIds}`
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  async getHighPriorityVehicles(
    lookbackDays = 7,
    vehicleLimit = 3,
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'highPriorityVehicles',
      lookbackDays,
      vehicleLimit
    );
    try {
      this.highPriorityVehiclesController.abort();

      this.highPriorityVehicles = ReportCacheStore.getData(key);
      if (_.isNil(this.highPriorityVehicles)) {
        this.highPriorityVehicles = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;
        this.highPriorityVehiclesController = new AbortController();
        this.highPriorityVehiclesSignal = this.highPriorityVehiclesController.signal;
        let { data } = await webServiceProvider.get(
          `v1/car/shop/${shopId}/high-priority-vehicles?lookbackDays=${lookbackDays}&numVehiclesReturned=${vehicleLimit}`,
          this.highPriorityVehiclesSignal
        );
        const carIds = data.map((item) => item.id_car);
        // should we move this?
        const dvirsData = await this.fetchDVIRsForCars(carIds);
        // hydrate car with dvir data
        data = data.map((item) => {
          const dvir = dvirsData
            ? dvirsData.find((dvir) => dvir.carId === item.id_car)
            : null;
          return {
            ...item,
            dvir,
          };
        });
        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      console.log(err);
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load highPriorityVehicles!'
      );
    }
  }

  async getVehiclesWithSpecificIssues(
    searchQuery = 'ABS',
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'vehiclesWithSpecificIssues',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.vehiclesWithSpecificIssuesController.abort();

      this.vehiclesWithSpecificIssues = ReportCacheStore.getData(key);
      if (_.isNil(this.vehiclesWithSpecificIssues)) {
        this.vehiclesWithSpecificIssues = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.vehiclesWithSpecificIssuesController = new AbortController();
        this.vehiclesWithSpecificIssuesSignal = this.vehiclesWithSpecificIssuesController.signal;

        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/specific-issues-widget?issueSearchString=${searchQuery}`,
          this.vehiclesWithSpecificIssuesSignal
        );

        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      } else {
        this.vehiclesWithSpecificIssuesController = new AbortController();
        this.vehiclesWithSpecificIssuesSignal = this.vehiclesWithSpecificIssuesController.signal;
        tableStore.pending = true;
        tableStore.loaded = false;
        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/specific-issues-widget?issueSearchString=${searchQuery}`,
          this.vehiclesWithSpecificIssuesSignal
        );
        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! We are unable to load these vehicles!');
    }
  }

  async getVehiclesWithDEFIssues(
    searchQuery = 'DEF',
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'vehiclesWithDEFIssues',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.vehiclesWithDEFIssuesController.abort();

      this.vehiclesWithDEFIssues = ReportCacheStore.getData(key);
      if (_.isNil(this.vehiclesWithDEFIssues)) {
        this.vehiclesWithDEFIssues = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.vehiclesWithDEFIssuesController = new AbortController();
        this.vehiclesWithDEFIssuesSignal = this.vehiclesWithDEFIssuesController.signal;

        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/specific-issues-widget?issueSearchString=${searchQuery}`,
          this.vehiclesWithDEFIssuesSignal
        );

        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      } else {
        this.vehiclesWithDEFIssuesController = new AbortController();
        this.vehiclesWithDEFIssuesSignal = this.vehiclesWithDEFIssuesController.signal;
        tableStore.pending = true;
        tableStore.loaded = false;
        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/specific-issues-widget?issueSearchString=${searchQuery}`,
          this.vehiclesWithDEFIssuesSignal
        );
        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! We are unable to load these vehicles!');
    }
  }

  async getEngineIdleScoreReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'engineIdleScore',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.engineIdleScoreController.abort();

      this.engineIdleScoreReport = ReportCacheStore.getData(key);

      if (_.isNil(this.engineIdleScoreReport)) {
        this.engineIdleScoreReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.engineIdleScoreController = new AbortController();
        this.engineIdleScoreSignal = this.engineIdleScoreController.signal;

        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/idle-score`,
          this.engineIdleScoreSignal
        );
        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load vehicle score report!'
      );
    }
  }

  async getVehicleHealthScoreReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'healthScore',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.vehicleHealthScoreController.abort();

      this.vehicleHealthScoreReport = ReportCacheStore.getData(key);

      if (_.isNil(this.vehicleHealthScoreReport)) {
        this.vehicleHealthScoreReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.vehicleHealthScoreController = new AbortController();
        this.vehicleHealthScoreSignal = this.vehicleHealthScoreController.signal;

        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/cars/health-score`,
          this.vehicleHealthScoreSignal
        );

        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load vehicle score report!'
      );
    }
  }

  async getVehicleCostStatsReport(
    shopId = 1085,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'vehicleCostStats',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.vehicleCostStatsController.abort();

      this.vehicleCostStatsReport = ReportCacheStore.getData(key);

      if (_.isNil(this.vehicleCostStatsReport)) {
        this.vehicleCostStatsReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.vehicleCostStatsController = new AbortController();
        this.vehicleCostStatsSignal = this.vehicleCostStatsController.signal;
        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/vehiclecoststats`,
          this.vehicleCostStatsSignal
        );
        tableStore.data = data;
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      console.log('cost stats failure to load');
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load vehicle cost stats report!'
      );
    }
  }

  async getAlgorithmStatsReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'algorithmStats',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.algorithmStatsController.abort();

      this.algorithmStatsReport = ReportCacheStore.getData(key);

      if (_.isNil(this.algorithmStatsReport)) {
        this.algorithmStatsReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.algorithmStatsController = new AbortController();
        this.algorithmStatsSignal = this.algorithmStatsController.signal;
        let { data } = await webServiceProvider.get(
          `v1/shops/${shopId}/algostats`,
          this.algorithmStatsSignal
        );
        tableStore.data = data.length > 0 ? data[0].algorithm_stats : [];
        tableStore.setDataMetadata(data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load algorithm stats report!'
      );
    }
  }

  async getVehiclesScoreReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'score',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );
    try {
      this.vehiclesScoreController.abort();

      this.vehiclesScoreReport = ReportCacheStore.getData(key);

      this.vehiclesScoreReport = tableStore;

      tableStore.pending = true;
      tableStore.loaded = false;

      this.vehiclesScoreController = new AbortController();
      this.vehiclesScoreSignal = this.vehiclesScoreController.signal;

      let { data } = await webServiceProvider.get(
        `v1/shops/${shopId}/cars/score`,
        this.vehiclesScoreSignal
      );

      tableStore.data = data;
      tableStore.setDataMetadata(data.length);

      ReportCacheStore.setData(key, tableStore);
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(
        err,
        'Sorry! We are unable to load vehicle score report!'
      );
    }
  }

  async getRoIReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore(),
    startDate = moment()
      .startOf('day')
      .subtract(1, 'years')
      .toISOString(),
    endDate = moment()
      .startOf('day')
      .toISOString()
  ) {
    let key = this.getReportKey(shopId, 'roi', startDate, endDate);

    try {
      this.roiReportController.abort();

      this.roiReport = ReportCacheStore.getData(key);

      if (_.isNil(this.roiReport)) {
        this.roiReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.roiReportController = new AbortController();
        this.roiReportSignal = this.roiReportController.signal;

        startDate = moment('2018-01-01')
          .startOf('day')
          .toISOString();
        endDate = moment()
          .startOf('day')
          .toISOString();

        let report = await webServiceProvider.getMany(
          'v1/summaryReport',
          {
            shopId: shopId,
            type: 'roi',
            startDate,
            endDate,
          },
          this.roiReportSignal
        );

        tableStore.data = report;
        tableStore.setDataMetadata(tableStore.data.length);

        ReportCacheStore.setData(key, tableStore);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! Unable to get ROI report!');
    }
  }

  async getPredictiveInsightReport(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    let key = this.getReportKey(
      shopId,
      'predictiveInsight',
      moment(this.startDate).toISOString(),
      moment(this.endDate).toISOString()
    );

    try {
      this.predictiveInsightController.abort();

      this.predictiveInsightReport = ReportCacheStore.getData(key);

      if (_.isNil(this.predictiveInsightReport)) {
        this.predictiveInsightReport = tableStore;

        tableStore.pending = true;
        tableStore.loaded = false;

        this.predictiveInsightController = new AbortController();
        this.predictiveInsightSignal = this.predictiveInsightController.signal;

        let report = await webServiceProvider.getMany(
          'v1/summaryReport',
          {
            shopId: shopId,
            type: 'predictiveInsight',
          },
          this.predictiveInsightSignal
        );

        tableStore.data = report;
        tableStore.setDataMetadata(tableStore.data.length);

        ReportCacheStore.setData(key, this.predictiveInsightReport);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! Unable to get predictive insight report!');
    }
  }

  async getBrakeUsageReport(
    shopId = ShopStore.currentShop.id,
    { offset, limit, filter, sort } = {},
    tableStore = new TableStore(),
    startDate = moment(this.startDate).toISOString(),
    endDate = moment(this.endDate).toISOString()
  ) {
    try {
      this.brakeUsageController.abort();

      let key = this.getReportKey(shopId, 'brakeUsage', startDate, endDate);

      this.brakeUsageReport = ReportCacheStore.getData(key);

      this.brakeUsageController = new AbortController();
      this.brakeUsageSignal = this.brakeUsageController.signal;

      tableStore.pending = true;
      tableStore.loaded = false;

      let params = {
        offset,
        limit,
        sort,
        ...filter,
      };

      let _params = _.pickBy(params, _.identity);

      let report = await webServiceProvider.getMany(
        'v1/summaryReport',
        {
          shopId,
          type: 'brakeUsage',
          startDate,
          endDate,
          ..._params,
        },
        this.brakeUsageSignal
      );

      _.forEach(report.data, (item) => {
        tableStore.data.push(item);
      });

      tableStore.setDataMetadata(report.total, offset, limit, sort, filter);

      this.brakeUsageReport = tableStore;

      ReportCacheStore.setData(key, this.brakeUsageReport);
    } catch (err) {
      errors.response(err, 'Sorry! Unable to get brake usage report!');
    }
  }

  async getVMRSReport(
    shopId = ShopStore.currentShop.id,
    startDate = this.startDate.toISOString(),
    endDate = this.endDate.toISOString()
  ) {
    let key = this.getReportKey(shopId, 'vmrs', startDate, endDate);

    try {
      this.vmrsReportController.abort();

      this.vmrsReport = ReportCacheStore.getData(key);

      if (_.isNil(this.vmrsReport)) {
        this.vmrsReport = new TableStore();

        this.vmrsReport.pending = true;
        this.vmrsReport.loaded = false;

        this.vmrsReportController = new AbortController();
        this.vmrsReportSignal = this.vmrsReportController.signal;

        let { data } = await webServiceProvider.getMany(
          'v1/summaryReport',
          {
            shopId: shopId,
            type: 'vmrs',
            startDate,
            endDate,
          },
          this.vmrsReportSignal
        );

        this.vmrsReport.data = data;
        this.vmrsReport.setDataMetadata(data.length);

        ReportCacheStore.setData(key, this.vmrsReport);
      }
    } catch (err) {
      ReportCacheStore.deleteKey(key);
      errors.response(err, 'Sorry! We are unable to load vmrs report!');
    }
  }

  async getDefSystemHealthReport(
    shopId = ShopStore.currentShop.id,
    { offset, limit, filter, sort } = {},
    tableStore = new TableStore(),
    startDate = this.startDate.toISOString(),
    endDate = this.endDate.toISOString()
  ) {
    // let key = this.getReportKey(
    //   shopId,
    //   'defSystemHealthAlert',
    //   startDate,
    //   endDate
    // );

    try {
      this.defSystemHealthController.abort();

      tableStore.pending = true;
      tableStore.loaded = false;

      let params = {
        offset,
        limit,
        sort,
        ...filter,
      };

      let _params = _.pickBy(params, _.identity);

      let result = await webServiceProvider.getMany('v1/summaryReport', {
        shopId,
        type: 'defSystemHealthAlert',
        startDate,
        endDate,
        ..._params,
      });

      tableStore.data = result.data;
      tableStore.setDataMetadata(result.total, offset, limit, sort, filter);

      this.defSystemHealthReport = tableStore;
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to load def system report!');
    }
  }

  reload = () => {
    ReportCacheStore.reset();

    this.getDrivingReport();
    this.getMaintenanceSummaryReport();
    this.refreshMaintenanceSummaryReportInRange(
      moment()
        .tz(CurrentUserStore?.user?.settings?.timezone || 'America/Toronto')
        .subtract(DateRangeEnum.DEFAULT_DAYS_TO_SEARCH, 'days')
        .startOf('day'),
      moment()
        .tz(CurrentUserStore?.user?.settings?.timezone || 'America/Toronto')
        .endOf('day')
    );
    this.getAlarmReport();
    //this.getSystemWatcherReports();
    this.getDvirStatusSummary();
    this.getVehicleHealthScoreReport();
    this.getVehiclesScoreReport();
    this.getEngineIdleScoreReport();
    this.getVehiclesWithSpecificIssues('ABS');
    this.getVehiclesWithDEFIssues();
    this.getHighPriorityVehicles(this.currentSelectedDateRangeDays, 20);
    this.getRoIReport();
    this.getPredictiveInsightReport();
    this.getVMRSReport();
    this.getBrakeUsageReport();
    this.getDefSystemHealthReport();
    this.getAlgorithmStatsReport();
    this.getVehicleCostStatsReport();
  };
}

decorate(ReportsDataStoreC, {
  drivingReport: observable,
  alarmReport: observable,
  systemWatcherReport: observable,
  vehiclesScoreReport: observable,
  vehicleHealthScoreReport: observable,
  engineIdleScoreReport: observable,
  vehiclesWithSpecificIssues: observable,
  vehiclesWithDEFIssues: observable,
  highPriorityVehicles: observable,
  vehicleCostStatsReport: observable,
  roiReport: observable,
  maintenanceSummary: observable,
  maintenanceSummaryInRange: observable,
  dvirStatusSummary: observable,
  predictiveInsightReport: observable,
  brakeUsageReport: observable,
  vmrsReport: observable,
  endDate: observable,
  startDate: observable,
  getBrakeUsageReport: action,
  getVMRSReport: action,
  getDefSystemHealthReport: action,
  getAlgorithmStatsReport: action,
  getVehicleCostStatsReport: action,
  getRoIReport: action,
  getDrivingReport: action,
  getMaintenanceSummaryReport: action,
  getMaintenanceSummaryReportInRange: action,
  refreshMaintenanceSummaryReportInRange: action,
  getAlarmReport: action,
  getPredictiveInsightReport: action,
  getVehiclesScoreReport: action,
  getVehicleHealthScoreReport: action,
  getHighPriorityVehicles: action,
  getEngineIdleScoreReport: action,
  getVehiclesWithSpecificIssues: action,
  getVehiclesWithDEFIssues: action,
  getSystemWatcherReports: action,
  reload: action,
  totalServiceCount: computed,
  totalServiceCountInRange: computed,
  totalServiceCountPerCar: computed,
  numberCriticalVehicles: computed,
  maxServicePriorityPerCar: computed,
  maxServicePriorityPerCarInRange: computed,
  reportMajorOrCriticalCarIdsInRange: computed,
  totalDrivingDistance: computed,
  totalSpeedingDistance: computed,
  totalDrivingTime: computed,
  totalIdlingTime: computed,
  safetyViolationCount: computed,
  serviceReport: computed,
  csvReport: computed,
  setCurrentSelectedDateRange: action,
  currentSelectedDateRange: computed,
  currentSelectedDateRangeDescription: computed,
  currentSelectedDateRangeDays: computed,
});

const ReportsDataStore = new ReportsDataStoreC();
export default ReportsDataStore;
