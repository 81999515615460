import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import axios from 'axios';

import {
  Spin,
  Tag,
  Card,
  Input,
  Select,
  Table,
  Row,
  Icon,
  Col,
  Form,
  Switch,
  Tooltip,
} from 'antd';

import {
  PitstopButton,
  PitstopModal,
  titles,
  webServiceProvider,
} from 'shared';

import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';

import {
  AppStore,
  CarStore,
  CurrentUserStore,
  IssueStore,
  ShopStore,
} from 'stores';

import {
  getPriorityCategory,
  getPriorityTagColor,
  getStatusColor,
} from 'services/Issue';
import {
  getRoutineDueDate,
  getRoutineDueMileage,
  getRoutineDueEngineHours,
} from './share/utils';

import ContentRow from 'components/ContentRow';

import SymptomAnalyserChart from 'containers/Widgets/SymptomAnalyser/SymptomAnalyserChart';
import MarkAsCompleteBtn from './MarkAsCompleteBtn';
import GoogleSearchBtn from './GoogleSearchBtn';
import MarkAsCurrentBtn from './MarkAsCurrentBtn';
import DeleteIssueBtn from './DeleteIssueBtn';

const { Option } = Select;
const { Search } = Input;

const AnchorButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #1e48c2;
`;

const InfoHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  && > div {
    margin-right: 12px;
  }
  && p {
    margin-bottom: 0;
    flex: 1;
  }
`;

const ContentSection = styled.div`
  display: flex;

  && > div {
    flex: 1;
  }
`;

const StyledForm = styled(Form)`
  &&& {
    label {
      float: left;
      font-weight: 500;
    }
  }
`;

const InlineP = styled.p`
  display: inline;
`;

class WrappedIssueProfilePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    form: PropTypes.object,
  };

  state = {
    editing: false,
    openServiceHistory: false,
    symptomAnalyserReport: null,
    isFetchingSymptomAnalyserReport: false,
    isSavedConfigService: false,
    currentNotesBoxLength: 0,
    dtcClusterData: null,
  };

  disposer = observe(CarStore, 'demo', async () => {
    if (CarStore.demo === false) {
      AppStore.openModals.set('backToDashboard', true);
    }
  });

  async componentDidMount() {
    await this.getIssueData();
    if (this.issue.action === 'Cluster Detected') {
      const dtcClusterData = await webServiceProvider.get(
        `v1/car/${this.issue.car.id}/alert?alertType=dtcCluster`
      );
      this.setState({
        dtcClusterData: _.get(
          dtcClusterData,
          'data[0].faultcode_cluster.id_car_services_by_code'
        ),
      });
    }

    // load if configuration is selected for future service based on user's settings
    this.setState({
      isSavedConfigService: !!CurrentUserStore.user.settings
        .defaultSavedConfigService,
    });
  }

  get issueId() {
    return Number(this.props.match.params.id);
  }

  get issue() {
    return IssueStore.data.get(this.issueId);
  }

  getIssueData = async () => {
    if (!IssueStore.data.has(this.issueId) || !this.issue.loaded) {
      // if issue is not loaded, load it
      await IssueStore.getIssueById(this.issueId);
      if (
        this.issue &&
        (this.issue.source === 'dtc' || this.issue.source === 'spn')
      ) {
        // redirect the user to IssueProfilePageV2
        this.props.history.replace(`/issues/${this.issueId}/v2`);
      }
      // check if ShopStore.currentShop.id is not provided or isAdmin
      if (!ShopStore.currentShop.id || ShopStore.currentShop.id === -1) {
        try {
          AppStore.addLoading('Loading issue data ...');
          // get car data
          const carId = this.issue.car.id;
          const carData = await CarStore.getCarByIdAndReturn(carId);
          let shopId = carData.shop.id;
          // Update the issue with shopId
          await IssueStore.getIssueById(this.issueId, shopId);
        } catch (err) {
          AppStore.addError(err.message);
        } finally {
          AppStore.removeLoading('Loading issue data ...');
        }
      }
    }
  };

  onUpdate = async (values) => {
    let updateIssueObject = {};

    _.each(values, (value, key) => {
      if (!_.isNil(value)) {
        updateIssueObject[key] = value;
      }
      if (updateIssueObject['priority'] === 0) {
        updateIssueObject['priority'] = 1;
      }
    });

    updateIssueObject['applyToSimilarIssues'] = this.state.isSavedConfigService;

    try {
      AppStore.addLoading('Updating the issue ...');
      await this.issue.update(updateIssueObject);
      await this.getIssueData();
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Updating the issue ...');
      this.setState({
        editing: false,
        isSavedConfigService: !!CurrentUserStore.user.settings
          .defaultSavedConfigService,
      });
    }
  };

  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let _values = {
          shopId: ShopStore.currentShop.id,
          userId: CurrentUserStore.user.id,
          item: this.issue.item,
          status: this.issue.status,
          source: this.issue.source,
        };

        _.each(values, (value, key) => {
          if (key === 'item') {
            if (_values['report']) _values['report'] = { testName: value };

            _values['item'] = value;
          } else if (key === 'priority') {
            if (typeof value === 'string') {
              _values['priority'] = parseInt(values.priority);
            } else {
              _values['priority'] = parseInt(this.issue.priority);
            }
          } else {
            _values[key] = value;
          }
        });

        await this.onUpdate(_values);
      }
    });
  };

  getSymptomAnalyserReport = async (value) => {
    try {
      this.setState({
        isFetchingSymptomAnalyserReport: true,
      });

      const response = await axios.get(
        `https://3y2exkk4k4w62lqzhcjt6prly40olsin.lambda-url.us-east-2.on.aws/?search=${value.replace(
          ' ',
          '+'
        )}`
      );
      const results = JSON.parse(response.data.result);

      if (results[0].result) {
        this.setState({
          symptomAnalyserReport: results[0]['Categories'],
        });
      } else {
        this.setState({
          symptomAnalyserReport: false,
        });
      }
    } catch (e) {
      if (!_.isEmpty(value)) AppStore.addError('Error in getting report!');

      this.setState({
        symptomAnalyserReport: false,
      });
    } finally {
      this.setState({
        isFetchingSymptomAnalyserReport: false,
      });
    }
  };

  showField(field, source) {
    let allowedFields = [];
    if (source === 'dataone') {
      allowedFields = [
        'Priority',
        'Remaining Mileage',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }

    if (['dtc', 'heavyVehicleDTC', 'lightVehicleDTC', 'DTC'].includes(source)) {
      allowedFields = [
        'Priority',
        'Description',
        'Possible Causes',
        'Symptoms',
        'Notes',
        'Explanation',
        'Failure Mode Indicator',
        'Source Address',
        'Suggested Action',
        'Engine Derate Possible',
        'Finish Route',
      ];
    }

    if (
      ['user', 'algorithm', 'routine', 'algorithm_history'].includes(source)
    ) {
      allowedFields = ['Priority', 'Description', 'Action', 'Item', 'Notes'];
    }
    if (
      ['algorithm', 'algorithm_history'].includes(source)
      // _.get(this.issue, 'action', null) === 'Cluster Detected' // maybe we need to review it
    ) {
      allowedFields = [
        'Priority',
        'Description',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }
    if (source === 'recall') {
      allowedFields = ['Priority', 'Description', 'Notes', 'Explanation'];
    }
    if (source === 'archive') {
      allowedFields = [
        'Status',
        'Source',
        'Priority',
        'Description',
        'Done Date',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }
    // let check = allowedFields.includes(field);
    // console.log(`field - ${field}, source - ${source}, vale - ${check}`);
    return allowedFields.includes(field);
  }

  showPriceField(source) {
    if (source === 'dataone') {
      return true;
    }
    return false;
  }

  onSaveConfigService = (e) => {
    this.setState({
      isSavedConfigService: !!e,
    });
    CurrentUserStore.user.updateSettings({ defaultSavedConfigService: e });
  };

  getVMRSCode = (item) => {
    let code;

    switch (item) {
      case 'Battery':
        code = '032';
        break;
      case 'Brakes':
        code = '013';
        break;
      case 'DEF fluid':
        code = '043';
        break;
      case 'Air Flow':
        code = '041';
        break;
      default:
        code = '900';
    }

    return code;
  };

  getVMRSText = (item) => {
    let text;

    switch (item) {
      case 'Battery':
        text = 'CRANKING SYSTEM';
        break;
      case 'Brakes':
        text = 'BRAKE SYSTEM';
        break;
      case 'DEF fluid':
        text = 'EXHAUST SYSTEM';
        break;
      case 'Air Flow':
        text = 'AIR INTAKE SYSTEM';
        break;
      default:
        text = 'OTHER';
    }

    return text;
  };

  getScoreLabel = () => {
    let { report } = this.issue;

    let formattedScoreLabel = (label) => _.join(_.split(label, '_'), ' ');

    return (
      <>
        Score
        {report.score_label && (
          <>
            {` (${formattedScoreLabel(report.score_label)}) `}
            <Tooltip title={report.score_explanation}>
              <Icon type={'question-circle-o'} />
            </Tooltip>
          </>
        )}
      </>
    );
  };

  columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'date',
      render: (data) => moment(data).format('LLL'),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (data) => (
        <Tag color={getPriorityTagColor(data)}>{getPriorityCategory(data)}</Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <Tag color={getStatusColor(data)}>{data.capitalize()}</Tag>
      ),
    },
  ];

  formItemLayout = {
    labelCol: {
      lg: { span: 5 },
      sm: { span: 7 },
      xs: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  getFormattedStatus = (status) => {
    switch (status) {
      case 'waitingForApproval':
        return 'Waiting For Invoice';
      case 'new':
        return 'Current';
      case 'upcoming':
        return 'Upcoming';
      case 'pending':
        return 'Pending';
      case 'done':
        return 'Done';
      case 'deleted':
        return 'Deleted';
      default:
        return status;
    }
  };

  isTriggeredAtValid = (triggeredAt) => {
    return (
      !_.isNil(triggeredAt) && !_.isEmpty(triggeredAt) && _.isArray(triggeredAt)
    );
  };
  onClickIssueLink = (carServiceId) => {
    this.props.history.push(`/issues/${carServiceId}/v2`);
  };
  render() {
    let {
      editing,
      openServiceHistory,
      symptomAnalyserReport,
      isFetchingSymptomAnalyserReport,
    } = this.state;

    const { getFieldDecorator } = this.props.form;

    if (_.isNil(this.issue)) {
      return (
        <>
          <h1>Issue Profile</h1>
          <Spin tip="Loading..." />
        </>
      );
    }

    let {
      id,
      priority,
      source,
      status,
      car,
      notes,
      item,
      doneDate,
      report,
      triggeredAt,
      serviceHistory,
      mileage,
      routineInfo,
      vmrs,
      activeTimeSeconds,
      updatedAt,
      reportDate,
    } = this.issue;

    if (['heavyVehicleDTC', 'lightVehicleDTC', 'dtc'].indexOf(source) !== -1) {
      source = 'DTC';
    }

    let issueReportDate = report?.date || updatedAt || reportDate;
    let issueExplanation = this.issue.getExplanation();
    if (
      this.issue.action === 'Cluster Detected' &&
      !_.isNil(this.state.dtcClusterData)
    ) {
      let regexSplitter = '(';
      for (let dtcClusterDataIndex in this.state.dtcClusterData) {
        regexSplitter +=
          this.state.dtcClusterData[dtcClusterDataIndex].code + '|';
      }
      regexSplitter = regexSplitter.slice(0, regexSplitter.length - 1);
      regexSplitter += ')';
      regexSplitter = new RegExp(regexSplitter);
      const splitExplanation = issueExplanation.split(regexSplitter);
      this.state.dtcClusterData.forEach((element) => {
        const code = element.code;
        for (let i = 0; i < splitExplanation.length; i++) {
          if (splitExplanation[i] === code) {
            splitExplanation[i] = (
              <InlineP>
                <AnchorButton
                  onClick={() =>
                    this.onClickIssueLink(
                      element.carServiceId
                        ? element.carServiceId
                        : element.id_car_service
                    )
                  }
                >
                  {code}
                </AnchorButton>
              </InlineP>
            );
          }
        }
        issueExplanation = splitExplanation;
      });
    }
    return (
      <React.Fragment>
        <PitstopModal
          title=""
          id="backToDashboard"
          onOk={() => this.props.history.push('/report')}
          onCancel={() => CarStore.toggleDemo()}
        >
          If you turn off sample data, you will be redirect to Dashboard!
        </PitstopModal>
        <h1>Issue: {this.issue.getName()}</h1>
        <InfoHeaderRow>
          <Tag color={IssueStore.data.get(id).priorityTagColor}>
            {IssueStore.data.get(id).priorityCategory}
          </Tag>
          <p>
            {`Reported on ${moment
              .tz(issueReportDate, CurrentUserStore.user.settings.timezone)
              .format('lll')}`}
          </p>
          {status === 'new' && (
            <MarkAsCompleteBtn issue={this.issue} onClick={() => {}} />
          )}
          {status === 'waitingForApproval' && (
            <MarkAsCompleteBtn issue={this.issue} onClick={() => {}} />
          )}
          {['done', 'waitingForApproval', 'upcoming'].includes(status) && (
            <MarkAsCurrentBtn issue={this.issue} onClick={() => {}} />
          )}
        </InfoHeaderRow>
        <ContentSection>
          <Card>
            <h4>Issue Details</h4>
            <br />

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <StyledForm {...this.formItemLayout} onSubmit={this.onSubmit}>
                  <ContentRow
                    name="Vehicle Name"
                    show={!_.isNil(car.name || car.vin)}
                    value={
                      <Link to={`/car/${car.id}`}>{car.name || car.vin}</Link>
                    }
                  />
                  {this.issue.getDescription() &&
                    this.showField('Description', source) && (
                      <>
                        <Row gutter={[16, 16]}>
                          <Col xl={5} sm={7} xs={24}>
                            <strong>Description</strong>
                          </Col>
                          <Col xl={19} sm={17} xs={24}>
                            <pre
                              style={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}
                            >
                              {this.issue.getDescription()}
                            </pre>
                          </Col>
                          {this.issue.item === 'DTC/SPN Trigger Rate' ? (
                            <PitstopButton
                              onClick={() =>
                                this.props.history.push(
                                  `/issues/${this.issue.report.id_car_service}`
                                )
                              }
                            >
                              View Fault Code
                            </PitstopButton>
                          ) : null}
                        </Row>
                      </>
                    )}

                  {this.issue && this.issue.source === 'dtc' && (
                    <>
                      <Row
                        style={{
                          marginBottom: '16px',
                        }}
                      >
                        <Col xl={5} sm={7} xs={24}>
                          <span>&nbsp;</span>
                        </Col>
                        <Col xl={19} sm={17} xs={24}>
                          <GoogleSearchBtn
                            style={{ marginRight: '10px' }}
                            icon="search"
                            issue={this.issue}
                            onClick={() => {}}
                            type="primary"
                            title="Learn more"
                          ></GoogleSearchBtn>
                        </Col>
                      </Row>
                    </>
                  )}

                  {this.issue.getSuggestedActions() !==
                  'No available suggested actions' ? (
                    <Row gutter={[16, 16]}>
                      <Col xl={5} sm={7} xs={24}>
                        <strong>Suggested Action</strong>
                      </Col>
                      <Col xl={19} sm={17} xs={24}>
                        {this.issue.getSuggestedActions()}
                      </Col>
                    </Row>
                  ) : null}

                  {/* {this.issue.getFinishRoute() !==
                  'No available finish route' ? (
                    <ContentRow
                      name="Finish Route"
                      show={this.showField('Finish Route', source)}
                      value={
                        this.issue.getFinishRoute()
                          ? "It is safe to finish today's route"
                          : "It is not safe to finish today's route"
                      }
                    />
                  ) : null} */}

                  {this.issue.getMilStatus() !== null ? (
                    <ContentRow
                      name="MIL Status"
                      show={
                        this.issue.getMilStatus() === 1 ||
                        this.issue.getMilStatus() === 0
                      }
                      value={
                        this.issue.getMilStatus() === 1
                          ? 'On'
                          : this.issue.getMilStatus === 0
                          ? 'Off'
                          : 'Unknown'
                      }
                    />
                  ) : null}

                  {this.issue.getExplanation() !==
                  'No available explanation' ? (
                    <ContentRow
                      name="Explanation"
                      show={this.showField('Explanation', source)}
                      value={issueExplanation}
                    />
                  ) : null}

                  <ContentRow
                    name="Status"
                    show={!_.isNil(status)}
                    value={this.getFormattedStatus(status)}
                  />

                  <ContentRow
                    name="Feedback"
                    show={status === 'done'}
                    value={this.issue.feedback || 'N/A'}
                  />

                  <ContentRow
                    name="Created By"
                    show={this.issue.createdBy}
                    value={this.issue.createdBy}
                  />

                  <ContentRow
                    name="Updated By"
                    show={this.issue.updatedBy}
                    value={this.issue.updatedBy}
                  />

                  <ContentRow
                    name="Updated On"
                    show={this.issue.updatedOn}
                    value={this.issue.updatedOn}
                  />

                  <ContentRow
                    name="Updated Type"
                    show={this.issue.updatedType}
                    value={this.issue.updatedType}
                  />

                  {this.issue.getAction() && (
                    <ContentRow
                      name="Action"
                      show={this.showField('Action', source)}
                      value={this.issue.getAction()}
                    />
                  )}

                  {this.issue.getItem() && (
                    <ContentRow
                      name="Item"
                      show={this.showField('Item', source)}
                      value={this.issue.getItem()}
                    />
                  )}

                  {this.issue.getSourceAddress() !==
                  'No available source address (SA)' ? (
                    <ContentRow
                      name="Source Address"
                      show={this.showField('Source Address', source)}
                      value={this.issue.getSourceAddress()}
                    />
                  ) : null}

                  {this.issue.getFailureModeIndicator() !==
                  'No available failure mode indicator (FMI)' ? (
                    <ContentRow
                      name="Failure Mode Indicator"
                      show={this.showField('Failure Mode Indicator', source)}
                      value={this.issue.getFailureModeIndicator()}
                    />
                  ) : null}

                  {report && !_.isNil(report.score) && (
                    <ContentRow
                      name={this.getScoreLabel()}
                      show={!_.isNil(report.score)}
                      value={Number(report.score).toFixed(2)}
                    />
                  )}
                  <>
                    <ContentRow
                      name="VMRS System Code"
                      show={true}
                      value={
                        source === 'recall'
                          ? 900
                          : vmrs
                          ? vmrs.sys
                          : this.getVMRSCode(item)
                      }
                    />

                    <ContentRow
                      name="VMRS System Text"
                      show={true}
                      value={
                        source === 'recall'
                          ? 'OTHER'
                          : vmrs
                          ? vmrs.text
                          : this.getVMRSText(item)
                      }
                    />
                  </>

                  {!_.isNil(priority) && editing ? (
                    <Form.Item label="Priority">
                      {getFieldDecorator('priority', {
                        initialValue: (
                          <Tag color={IssueStore.data.get(id).priorityTagColor}>
                            {IssueStore.data.get(id).priorityCategory}
                          </Tag>
                        ),
                        rules: [
                          {
                            required: true,
                            message: 'Please select your priority!',
                          },
                        ],
                      })(
                        <Select style={{ width: 110 }} placeholder="Priority">
                          <Option value="-1">
                            <Tag color="#dec431">Not Valid</Tag>
                          </Option>
                          <Option value="1">
                            <Tag color="#dec431">Minor</Tag>
                          </Option>
                          <Option value="2">
                            <Tag color="#e28931">Major</Tag>
                          </Option>
                          <Option value="4">
                            <Tag color="#f92d2e">Critical</Tag>
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  ) : (
                    <ContentRow
                      name="Priority"
                      show={this.showField('Priority', source)}
                      value={
                        <Tag color={IssueStore.data.get(id).priorityTagColor}>
                          {IssueStore.data.get(id).priorityCategory}
                        </Tag>
                      }
                    />
                  )}

                  {editing ? (
                    <>
                      <Form.Item label="Notes">
                        {getFieldDecorator('notes', {
                          initialValue:
                            notes ||
                            'Here you can leave a note that will appear next time this issue occurs.',
                        })(
                          <Input.TextArea
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            maxLength="600"
                            onChange={(value) =>
                              this.setState({
                                currentNotesBoxLength:
                                  value.target.value.length,
                              })
                            }
                            onBlur={this.onSubmit}
                          />
                        )}
                        <p>{this.state.currentNotesBoxLength}/600</p>
                        <PitstopButton onClick={this.onSubmit}>
                          Save
                        </PitstopButton>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col xl={5} sm={7} xs={24}>
                          <strong>Notes</strong>
                        </Col>
                        <Col xl={19} sm={17} xs={24}>
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}
                          >
                            {notes ||
                              'Here you can leave a note that will appear next time this issue occurs.'}
                          </pre>
                          <PitstopButton
                            onClick={() =>
                              this.setState({
                                editing: true,
                              })
                            }
                          >
                            Edit
                          </PitstopButton>
                        </Col>
                      </Row>
                    </>
                  )}

                  {this.issue.getCauses() !== 'No available cause' ? (
                    <ContentRow
                      name="Possible Causes"
                      show={this.showField('Possible Causes', source)}
                      value={this.issue.getCauses()}
                    />
                  ) : null}

                  {/* {this.issue.getEngineDeratePossible() !== 'No available engine derate possible' ?
                    <ContentRow
                      name="Engine Derate Possible"
                      show={this.showField('Engine Derate Possible', source)}
                      value={this.issue.getEngineDeratePossible() ? 'yes' : 'no'}
                    /> : null} */}

                  <ContentRow
                    name="Source"
                    show={!_.isNil(source)}
                    value={source}
                  />

                  {source === 'dtc' && (
                    <ContentRow
                      name={
                        <>
                          Active Time&nbsp;
                          <Tooltip title={titles.dtc}>
                            <Icon type={'question-circle-o'} />
                          </Tooltip>
                        </>
                      }
                      show={!_.isNil(activeTimeSeconds)}
                      value={
                        !activeTimeSeconds
                          ? 'N/A'
                          : humanizeDuration(activeTimeSeconds * 1000)
                      }
                    />
                  )}

                  <ContentRow
                    name="Done At"
                    show={!_.isNil(doneDate) && status === 'done'}
                    value={moment
                      .tz(doneDate, CurrentUserStore.user.settings.timezone)
                      .format('ll HH:mm:ss:SSS')}
                  />

                  <ContentRow
                    name="Current Mileage"
                    show={!_.isNil(car.mileageTotal)}
                    value={`${Math.round(
                      calcMileage(car.mileageTotal)
                    ).toReadable()} ${CurrentUserStore.user.displayedOdometer}`}
                  />

                  {routineInfo && (
                    <>
                      <ContentRow
                        name="Due Mileage"
                        show={
                          source === 'routine' &&
                          !_.isNil(getRoutineDueMileage(this.issue))
                        }
                        value={`${Math.round(
                          getRoutineDueMileage(this.issue)
                        ).toReadable()} ${
                          CurrentUserStore.user.displayedOdometer
                        }`}
                      />

                      <ContentRow
                        name="Routine Mileage Interval"
                        show={
                          source === 'routine' &&
                          !_.isNil(routineInfo.interval_mileage)
                        }
                        value={`${Math.round(
                          calcMileage(Number(routineInfo.interval_mileage))
                        ).toReadable()} ${
                          CurrentUserStore.user.displayedOdometer
                        }`}
                      />

                      <ContentRow
                        name="Alert Before (x) Mileage"
                        show={
                          source === 'routine' &&
                          !_.isNil(
                            this.issue.routineInfo.threshold &&
                              this.issue.routineInfo.threshold.mileage
                          )
                        }
                        value={`${Math.round(
                          calcMileage(
                            this.issue.routineInfo.threshold &&
                              this.issue.routineInfo.threshold.mileage
                          )
                        ).toReadable()} ${
                          CurrentUserStore.user.displayedOdometer
                        }`}
                      />
                    </>
                  )}

                  <ContentRow
                    name="Current Engine Hours"
                    show={!_.isNil(car.engineHours)}
                    value={`${Math.round(
                      convertSecondsToHours(car.engineHours)
                    ).toReadable()}`}
                  />

                  {routineInfo && (
                    <>
                      <ContentRow
                        name="Due Engine Hours"
                        show={
                          source === 'routine' &&
                          !_.isNil(getRoutineDueEngineHours(this.issue))
                        }
                        value={`${Number(
                          getRoutineDueEngineHours(this.issue)
                        ).toReadable()}`}
                      />

                      <ContentRow
                        name="Routine Engine Hours Interval"
                        show={
                          source === 'routine' &&
                          !_.isNil(routineInfo.interval_engine_hours)
                        }
                        value={`${Math.round(
                          convertSecondsToHours(
                            routineInfo.interval_engine_hours
                          )
                        ).toReadable()}`}
                      />

                      <ContentRow
                        name="Alert Before (x) Engine Hours"
                        show={
                          source === 'routine' &&
                          !_.isNil(
                            this.issue.routineInfo.threshold &&
                              this.issue.routineInfo.threshold.engineHours
                          )
                        }
                        value={Number(
                          _.divide(
                            this.issue.routineInfo.threshold &&
                              Number(
                                this.issue.routineInfo.threshold.engineHours
                              ),
                            3600
                          )
                        ).toFixed(2)}
                      />
                    </>
                  )}

                  {routineInfo && (
                    <>
                      <ContentRow
                        name="Routine Month Interval"
                        show={
                          source === 'routine' &&
                          !_.isNil(routineInfo.interval_month)
                        }
                        value={`${routineInfo.interval_month} months`}
                      />

                      <ContentRow
                        name="Due At"
                        show={
                          source === 'routine' &&
                          !_.isNil(getRoutineDueDate(this.issue))
                        }
                        value={getRoutineDueDate(this.issue)}
                      />

                      <ContentRow
                        name="Alert Before (x) Days"
                        show={
                          source === 'routine' &&
                          !_.isNil(
                            this.issue.routineInfo.threshold &&
                              this.issue.routineInfo.threshold.time
                          )
                        }
                        value={
                          this.issue.routineInfo.threshold &&
                          Number(this.issue.routineInfo.threshold.time)
                        }
                      />
                    </>
                  )}

                  <ContentRow
                    name="Mileage at which service was completed"
                    show={!_.isNil(mileage) && source !== 'routine'}
                    value={`${Math.round(calcMileage(mileage)).toReadable()} ${
                      CurrentUserStore.user.displayedOdometer
                    }`}
                  />

                  <ContentRow
                    name="DTC Trigger Counts"
                    show={
                      this.isTriggeredAtValid(triggeredAt)
                        ? triggeredAt.length > 0
                        : null
                    }
                    value={triggeredAt ? triggeredAt.length : null}
                  />

                  <ContentRow
                    name="Triggered At"
                    show={
                      this.isTriggeredAtValid(triggeredAt)
                        ? triggeredAt.length > 0
                        : null
                    }
                    value={
                      <Col span={12}>
                        <Table
                          loading={_.isNil(triggeredAt)}
                          dataSource={(() => {
                            return _.map(triggeredAt, (val, i) => ({
                              key: i,
                              time: val,
                            }));
                          })()}
                          columns={[
                            {
                              title: 'Time',
                              dataIndex: 'time',
                              key: 'time',
                              sorter: (a, b) => a > b,
                              render: (item) =>
                                moment
                                  .tz(
                                    item,
                                    CurrentUserStore.user.settings.timezone
                                  )
                                  .format('ll HH:mm:ss:SSS'),
                            },
                          ]}
                          pagination={{ pageSize: 5 }}
                        />
                      </Col>
                    }
                  />

                  <ContentRow
                    name="Service History"
                    show={!_.isNil(serviceHistory)}
                    value={
                      <PitstopButton
                        type=""
                        onClick={() =>
                          this.setState({
                            openServiceHistory: !openServiceHistory,
                          })
                        }
                      >
                        <Icon type={openServiceHistory ? 'minus' : 'plus'} />
                        {openServiceHistory ? 'Close' : 'See more'}
                      </PitstopButton>
                    }
                  />

                  {openServiceHistory && (
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Table
                          columns={this.columns}
                          dataSource={serviceHistory}
                          pagination={{ pageSize: 5 }}
                        />
                      </Col>
                    </Row>
                  )}

                  <ContentRow
                    name="Symptom Analyser "
                    show={true}
                    value={
                      <div style={{ width: '50%' }}>
                        <Search
                          placeholder="For example: 'smoke'"
                          enterButton="Search"
                          size="large"
                          compact="true"
                          onSearch={(value) =>
                            this.getSymptomAnalyserReport(value)
                          }
                          disabled={isFetchingSymptomAnalyserReport}
                        />
                      </div>
                    }
                  />

                  <ContentRow
                    name=""
                    show={true}
                    value={
                      <>
                        {isFetchingSymptomAnalyserReport ? (
                          <Spin tip="Fetching report..." />
                        ) : (
                          <SymptomAnalyserChart data={symptomAnalyserReport} />
                        )}
                      </>
                    }
                  />

                  {editing && (
                    <ContentRow
                      name="Save this configuration for future service?"
                      show={true}
                      value={
                        <>
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={this.state.isSavedConfigService}
                            onChange={this.onSaveConfigService}
                          />
                        </>
                      }
                    />
                  )}
                </StyledForm>
              </Col>
            </Row>

            <Row gutter={[32, 32]}>
              {!editing ? (
                <>
                  <Col span={2}>
                    <PitstopButton
                      onClick={() =>
                        this.setState({
                          editing: true,
                        })
                      }
                    >
                      Edit
                    </PitstopButton>
                  </Col>

                  <Col span={2}>
                    <DeleteIssueBtn
                      onClick={async () => {
                        AppStore.addLoading('Deleting...');
                        await this.issue.delete();
                        AppStore.removeLoading('Deleting...');
                        this.props.history.push('/issues');
                      }}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col xl={2} sm={4} xs={4}>
                    <PitstopButton onClick={this.onSubmit}>Save</PitstopButton>
                  </Col>

                  <Col xl={2} sm={4} xs={4}>
                    <PitstopButton
                      type=""
                      onClick={() =>
                        this.setState({
                          editing: false,
                        })
                      }
                    >
                      Cancel
                    </PitstopButton>
                  </Col>
                </>
              )}
            </Row>
          </Card>
        </ContentSection>
      </React.Fragment>
    );
  }
}

const IssueProfilePage = Form.create({ name: 'register' })(
  observer(WrappedIssueProfilePage)
);

export default withRouter(IssueProfilePage);
