import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import { PitstopButton } from 'shared';
import PropTypes from 'prop-types';
import PitstopSimpleTable from 'components/PitstopSimpleTable';
import { Link } from 'react-router-dom';

import {
  Input,
  Row,
  Col,
  Icon,
  Modal,
  Popconfirm,
  Card,
  Checkbox,
  Button,
} from 'antd';

import _ from 'lodash';

import { WorkOrderStore, ShopStore, AppStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';
import { Logger } from 'stores/Classes';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';
import FilterDropdown from 'components/FilterDropdown';

const Wrapper = styled.div`
  position: relative;

  .clickable-row {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const NoImageWrapper = styled.div`
  width: 50px;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 5px;

  height: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  padding: 5px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const AisleShelfBinWrapper = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .anticon {
    font-size: 1.1rem;
  }
`;

const CardTitle = styled.div`
  color: #1f3eb8;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 190px;

  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.5rem;

    &.green {
      color: rgb(35, 120, 4);
    }

    &.red {
      color: rgb(212, 56, 13);
    }

    &.blue {
      color: rgb(0, 58, 140);
    }

    .count {
      font-size: 55px;
      font-weight: bold;
    }

    .description {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);

      text-align: center;
    }
  }
`;

const { Search } = Input;
class PartsListPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    allData: [],
    data: [],
    loading: false,
    currentSearch: '',
    thumbnailPreviewVisible: false,
    thumbnailPreviewUrl: '',
    currentLocationFilters: [],
  };

  text = 'Are you sure you want to delete this part?';

  columns = [
    {
      title: 'Part #',
      dataIndex: 'number',
      key: 'number',
      render: (partNumber, record) => {
        const linkToPart = (
          <Link to={`/parts/${record.id}`}>{partNumber || '-'}</Link>
        );
        const partImages = record.images || '';
        const partImagesArray = partImages.split(',');
        let partThumbnailUrl = '';
        if (partImagesArray.length > 0) {
          partThumbnailUrl = partImagesArray[0];
        }
        const partThumbnail = partThumbnailUrl ? (
          <ImageWrapper
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              this.setState(
                {
                  thumbnailPreviewUrl: partThumbnailUrl,
                },
                () => {
                  this.setState({
                    thumbnailPreviewVisible: true,
                  });
                }
              );
            }}
          >
            <img
              src={partThumbnailUrl}
              alt={partNumber || 'part image'}
              style={{
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </ImageWrapper>
        ) : (
          <NoImageWrapper>
            <Icon
              type="inbox"
              style={{ fontSize: '20px', color: 'lightgrey' }}
            />
          </NoImageWrapper>
        );
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {partThumbnail}
            <div style={{ marginLeft: '10px' }}>{linkToPart}</div>
          </div>
        );
      },
      sorter: (a, b) => {
        if (!a.number) return 1;
        if (!b.number) return -1;
        return a.number.length - b.number.length;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description, row) => {
        if (!description) {
          return row.name;
        }
        return description;
      },
      sorter: (a, b) => {
        const aToSort = a.description || a.name;
        const bToSort = b.description || b.name;
        return aToSort.length - bToSort.length;
      },
    },
    {
      title: 'Location',
      dataIndex: 'id_shop',
      key: 'id_shop',
      filters: [],
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByCurrentLocationFiltersVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderLocationFilterDropdown,
      // filterDropdownVisible: this.state.filterByCurrentLocationFiltersVisible,
      render: (id_shop, part) => {
        if (part.shop) {
          return part.shop;
        }
        return id_shop;
      },
      sorter: (a, b) => {
        return a.id_shop - b.id_shop;
      },
    },
    {
      title: 'Aisle / Shelf / Bin',
      dataIndex: 'aisleShelfBin',
      key: 'aisleShelfBin',
      render: (unused, part) => {
        if (!part.aisle && !part.shelf && !part.bin) {
          return 'N/A';
        }
        return (
          <AisleShelfBinWrapper>
            {`${part.aisle || '-'} / ${part.shelf || '-'} / ${part.bin || '-'}`}
          </AisleShelfBinWrapper>
        );
      },
    },
    {
      title: 'Current Stock',
      dataIndex: 'currentStock',
      key: 'currentStock',
      render: (currentStock) => {
        if (!currentStock) return <span style={{ color: 'grey' }}>0</span>;
        return currentStock;
      },
      sorter: (a, b) => {
        if (a.currentStock === undefined || a.currentStock === null) {
          a.currentStock = 0;
        }
        if (b.currentStock === undefined || b.currentStock === null) {
          b.currentStock = 0;
        }
        return a.currentStock - b.currentStock;
      },
    },
    {
      title: 'Unit Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => {
        if (!cost) return <span style={{ color: 'grey' }}>0</span>;
        return cost;
      },
      sorter: (a, b) => {
        if (a.cost === undefined || a.cost === null) {
          a.cost = 0;
        }
        if (b.cost === undefined || b.cost === null) {
          b.cost = 0;
        }
        return a.cost - b.cost;
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (t, row) => {
        const totalCost =
          (row.currentStock ? parseInt(row.currentStock) : 0) *
          (row.cost ? parseFloat(row.cost) : 0);
        if (!totalCost) return <span style={{ color: 'grey' }}>$0.00</span>;
        const formatted = `$${parseFloat(totalCost).toFixed(2)}`;
        return `${formatted}`;
      },
      sorter: (a, b) => {
        const totalCostA =
          (a.currentStock ? parseInt(a.currentStock) : 0) *
          (a.cost ? parseFloat(a.cost) : 0);
        const totalCostB =
          (b.currentStock ? parseInt(b.currentStock) : 0) *
          (b.cost ? parseFloat(b.cost) : 0);
        return totalCostA - totalCostB;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (a, record) => (
        <ActionsWrapper
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Icon
            type="edit"
            onClick={() => this.props.history.push(`/parts/${record.id}`)}
            style={{ marginRight: 4 }}
          />
          <Popconfirm
            placement="topRight"
            title={this.text}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              this.onConfirmDeletePart(record);
            }}
            onCancel={(event) => {
              event.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Icon type="delete" />
          </Popconfirm>
        </ActionsWrapper>
      ),
    },
  ];

  async componentDidUpdate(prevProps, prevState) {
    let { currentSearch } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChangeAndParts();
    }
  }

  searchDebouncer = null;

  handleSearchChangeAndParts = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.loadSelectedShopParts(ShopStore.currentShop.id);
    }, 500);
  };

  componentDidMount() {
    this.loadSelectedShopParts(ShopStore.currentShop.id);
  }

  componentWillUnmount() {
    this.disposer();
  }

  get currentFiltersApplied() {
    return [...this.state.currentLocationFilters];
  }

  updateShopFilteringOptions(shopIdNameObject) {
    const arrayOfShopObjects = Object.keys(shopIdNameObject).map((key) => ({
      value: key,
      text: shopIdNameObject[key],
    }));
    this.setState({
      allShopEcosystem: arrayOfShopObjects,
    });
    this.columns = this.columns.map((column) => {
      if (column.key === 'id_shop') {
        column.filters = arrayOfShopObjects;
      }
      return column;
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (filters.id_shop) {
      // filtering by shop
      this.setState(
        {
          currentLocationFilters: filters.id_shop.map((key) => {
            const shop = this.state.allShopEcosystem.find(
              (s) => s.value === key
            );
            return {
              key: key,
              label: shop?.text || key,
            };
          }),
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
    }
  };

  handleFilterReset = (clearFilters, type = 'currentLocation') => {
    clearFilters();
    if (type === 'currentLocation') {
      this.setState(
        {
          currentLocationFilters: [],
          filterByCurrentLocationFiltersVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
  };

  handleCurrentLocationFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentLocationFilters: selectedKeys.map((key) => {
          const shop = this.currentLocationFilters.find((s) => s.value === key);
          return {
            key: key,
            label: shop?.text || key,
          };
        }),
        filterByCurrentLocationFiltersVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  renderLocationFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="assigned-to-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list">
          {this.state.currentLocationFilters.map((shop) => {
            return (
              <div className="filter-dropdown-list-item" key={shop.value}>
                <Checkbox
                  checked={selectedKeys.includes(shop.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, shop.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== shop.value)
                      );
                    }
                  }}
                >
                  {shop.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() =>
              this.handleFilterReset(clearFilters, 'currentLocation')
            }
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleCurrentLocationFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      location: [],
    };
    this.state.currentLocationFilters.forEach((filter) => {
      filters['location'].push(filter.key);
    });
    // update the allColumns
    this.columns = this.columns.map((column) => {
      if (column.key === 'id_shop') {
        return {
          ...column,
          // filters: this.state.currentLocationFilters,
          filteredValue: filters['location'],
        };
      }
      return column;
    });
    this.setState({
      data: this.state.allData.filter((part) => {
        if (filters.location.length > 0) {
          return filters.location.includes(part.id_shop.toString());
        }
        return true;
      }),
    });
  };

  loadSelectedShopParts = async (shopId) => {
    try {
      this.setState({ loading: true });
      const partsSearch = {
        search: this.state.currentSearch,
      };
      const data = await WorkOrderStore.getShopParts(shopId, partsSearch);
      // hydrate shop parts with shop info
      const shopNames = await ShopStore.getShopNames(
        _.uniq(data.map((part) => part.id_shop))
      );
      this.updateShopFilteringOptions(shopNames);
      data.forEach((part) => {
        part.shop = shopNames[part.id_shop];
      });
      this.setState({ data, allData: data });
      // if current shop is in the list of shops, add it to the filters
      if (ShopStore.currentShop.id) {
        const shopIds = Object.keys(shopNames).map((key) => key.toString());
        if (shopIds.includes(ShopStore.currentShop.id.toString())) {
          this.setState(
            {
              currentLocationFilters: [
                {
                  key: ShopStore.currentShop.id.toString(),
                  label: shopNames[ShopStore.currentShop.id],
                },
              ],
            },
            () => {
              this.onChangeCurrentFilters();
            }
          );
        } else {
          // clear filters
          this.setState(
            {
              currentLocationFilters: [],
            },
            () => {
              this.onChangeCurrentFilters();
            }
          );
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.isAdmin()) return;
    this.setState(
      {
        currentSearch: '',
      },
      () => {
        this.loadSelectedShopParts(ShopStore.currentShop.id);
      }
    );
  });

  navigateToAdd = () => {
    this.props.history.push('/parts/add');
  };

  onConfirmDeletePart = async (record) => {
    try {
      const partId = record.id;
      await WorkOrderStore.deletePartOnCustomEndpoint(partId);
      AppStore.addSuccess('The part was deleted!');
      this.loadSelectedShopParts(ShopStore.currentShop.id);
    } catch (error) {
      console.error(error);
      AppStore.addError('There was an error deleting the part');
    }
  };

  render() {
    return (
      <Fragment>
        <Wrapper>
          <div style={{ position: 'absolute', right: '0px' }}>
            <PitstopButton icon="plus" onClick={this.navigateToAdd}>
              Add Part
            </PitstopButton>
          </div>
          <h1>Parts</h1>

          <DealershipChooser />

          {/* Widget for In Stock, Low Stock and Out of Stock */}
          {/* Widget showing In Warranty, Out of Warranty, Warranty Ending Soon */}
          {ShopStore.currentShop.id === 374 && (
            <Row gutter={8}>
              <Col span={8}>
                <Card>
                  <CardTitle>Stock</CardTitle>
                  <CardBody>
                    <div className="counter green">
                      <div className="count">34</div>
                      <div className="description">In Stock</div>
                    </div>
                    <div className="counter red">
                      <div className="count">11</div>
                      <div className="description">Out of Stock</div>
                    </div>
                    <div className="counter blue">
                      <div className="count">14</div>
                      <div className="description">Low Stock</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <CardTitle>Warranty</CardTitle>
                  <CardBody>
                    <div className="counter green">
                      <div className="count">23</div>
                      <div className="description">In Warranty</div>
                    </div>
                    <div className="counter red">
                      <div className="count">11</div>
                      <div className="description">Out of Warranty</div>
                    </div>
                    <div className="counter blue">
                      <div className="count">17</div>
                      <div className="description">Ending Soon</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="Search by # or description"
                onChange={(event) => {
                  this.setState({
                    currentSearch: event.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <CurrentFilterBadges
            currentFiltersApplied={this.currentFiltersApplied}
            onClearAllFilters={() => {
              this.setState(
                {
                  currentLocationFilters: [],
                },
                () => {
                  this.onChangeCurrentFilters();
                }
              );
            }}
            onCloseTag={(filter) => {
              this.setState(
                {
                  currentLocationFilters: this.state.currentLocationFilters.filter(
                    (f) => f.key !== filter.key
                  ),
                },
                () => {
                  this.onChangeCurrentFilters();
                }
              );
            }}
          />

          <PitstopSimpleTable
            rowKey="id"
            onRowClick={(record) =>
              this.props.history.push(`/parts/${record.id}`)
            }
            onChange={this.handleTableChange}
            loading={this.state.loading}
            data={this.state.data}
            columns={this.columns}
            rowClassName="clickable-row"
          />

          <Modal
            visible={this.state.thumbnailPreviewVisible}
            footer={null}
            closeIcon={<CloseIcon type="close" />}
            onCancel={() => {
              this.setState({ thumbnailPreviewVisible: false });
            }}
            onOk={() => {
              this.setState({ thumbnailPreviewVisible: false });
            }}
          >
            <img
              src={this.state.thumbnailPreviewUrl}
              alt="thumbnail"
              style={{
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </Modal>
        </Wrapper>
      </Fragment>
    );
  }
}

export default observer(PartsListPage);
