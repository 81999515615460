import React, { Component } from 'react';
import { Card, Button, Icon, Row, Col, Input, Spin } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCard = styled(Card)`
  & {
    width: 100%;
    min-height: 110px;
  }

  .title {
    margin-bottom: 12px;
  }

  .ant-card-body {
    padding: 12px 12px 24px;
  }

  .ant-btn {
    padding: 0;
  }

  .component {
    margin-bottom: 12px;

    label {
      display: block;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 2px;
    }
  }
`;

class AddWarrantyComponent extends Component {
  state = {
    components: this.props?.initialComponents
      ? [...this.props.initialComponents]
      : [],
  };

  componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this);
    }
  }

  handleAddComponent = () => {
    this.setState({
      components: [
        ...this.state.components,
        {
          name: '',
          description: '',
        },
      ],
    });
  };

  getComponents = () => {
    return this.state.components;
  };

  clearComponents = () => {
    this.setState({ components: [] });
  };

  render() {
    return (
      <Spin spinning={this.props.isLoadingComponents}>
        <StyledCard>
          <div className="title">
            <b>Add Components:</b>
          </div>
          {this.state.components.map((component, index) => {
            return (
              <Row className="component" gutter={16} key={index}>
                <Col span={11}>
                  <label htmlFor="componentName">Component Name:</label>
                  <Input
                    onChange={(e) => {
                      const newComponents = [...this.state.components];
                      newComponents[index].name = e.target.value;
                      this.setState({ components: newComponents });
                    }}
                    value={component.name}
                    id="componentName"
                  />
                </Col>
                <Col span={11}>
                  <label htmlFor="componentDescription">
                    Component Description:
                  </label>
                  <Input
                    onChange={(e) => {
                      const newComponents = [...this.state.components];
                      newComponents[index].description = e.target.value;
                      this.setState({ components: newComponents });
                    }}
                    value={component.description}
                    id="componentDescription"
                  />
                </Col>
                <Col
                  span={2}
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button
                    style={{
                      position: 'absolute',
                      top: '20px',
                      color: '#757575',
                    }}
                    onClick={() => {
                      const newComponents = [...this.state.components];
                      newComponents.splice(index, 1);
                      this.setState({ components: newComponents });
                    }}
                    type="link"
                  >
                    <Icon type="delete" />
                  </Button>
                </Col>
              </Row>
            );
          })}
          <Button type="link" onClick={this.handleAddComponent}>
            <Icon type="plus-circle" />
            Add Component
          </Button>
        </StyledCard>
      </Spin>
    );
  }
}

AddWarrantyComponent.propTypes = {
  ref: PropTypes.object,
  initialComponents: PropTypes.array,
  isLoadingComponents: PropTypes.bool,
};

AddWarrantyComponent.defaultProps = {
  initialComponents: [],
  isLoadingComponents: false,
};

export default AddWarrantyComponent;
