import _ from 'lodash';

export const currencyOrNumber = (value, applyCurrency = true) => {
  if (!applyCurrency) return value;
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export const convertToMonetary = (value, showSymbol = true) => {
  if (!showSymbol) return value.toFixed(2);
  if (value === null || value === undefined) {
    return '$0.00';
  }
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export const getWorkOrderTotalCostWithoutTax = (workOrderLines = [], applyCurrency = true) => {
  if (!workOrderLines || _.isEmpty(workOrderLines)) {
    return currencyOrNumber(0, applyCurrency);
  }
  return currencyOrNumber(_.sumBy(workOrderLines || [], (line) => {
    return _.sum([parseFloat(line.parts_cost || 0), parseFloat(line.labour_cost || 0)]);
  }), applyCurrency);
};

export const getWorkOrderTotalCostWithTax = (
  workOrderLines = [],
  taxObj = { tax: 0, type: 'percentage' },
  applyCurrency = true
) => {
  const costWithoutTax = getWorkOrderTotalCostWithoutTax(workOrderLines, false);
  if (!taxObj) {
    throw new Error('Tax was not provided');
  }
  const taxIncrease = taxObj.type === 'percentage' ? (costWithoutTax * (taxObj.tax / 100)) : taxObj.tax;
  return currencyOrNumber(costWithoutTax + taxIncrease, applyCurrency);
};

export const getWorkOrderTotalCostWithTaxAndMisc = (
  workOrderLines = [],
  taxObj = { tax: 0, type: 'percentage' },
  miscObj = { misc: 0, type: 'percentage' },
  applyCurrency = true
) => {
  const costWithTax = getWorkOrderTotalCostWithTax(workOrderLines, taxObj, false);
  if (!miscObj) {
    return costWithTax;
  }
  const miscIncrease = miscObj.type === 'percentage' ? (costWithTax * (miscObj.misc / 100)) : miscObj.misc;
  return currencyOrNumber(costWithTax + miscIncrease, applyCurrency);
};

export const convertDemoVinToRealVin = (vin) => {
  const vinArr = vin.split('-');
  return vinArr[2];
};

export const showDemoVinOrRealVin = (vin) => {
  if (!vin || vin.indexOf('DEMO-') === -1) {
    return vin;
  }
  return convertDemoVinToRealVin(vin);
};
