import React from 'react';
import { Card, Col, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { Text } = Typography;

const PMsCol = styled(Col)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .overdue {
    color: #d4380d;
    display: flex;
    align-items: center;
  }

  .due-soon {
    color: #ff812a;
    display: flex;
    align-items: center;
  }

  span.count {
    font-size: 2rem;
    font-weight: bold;
  }

  span.label {
    width: 130px;
    margin-left: 0.5rem;
  }
`;

const ClickableSection = styled.div`
  cursor: pointer;
  &:hover {
    color: #9999a1;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }
`;

const PMCountWidget = ({
  isLoadingPMCounts,
  pmCounts,
  handlePMsCountClick,
}) => {
  return (
    <Card className="summary-card">
      <div className="summary-card__title">
        <Text strong>PMs</Text>
      </div>
      <Row style={{ marginTop: 15 }} gutter={[16, 16]}>
        <PMsCol span={24}>
          {isLoadingPMCounts ? (
            <Spin tip="Loading..." />
          ) : (
            <>
              <ClickableSection onClick={() => handlePMsCountClick('overdue')}>
                <div className="overdue">
                  <span className="count">{pmCounts.overdue_count}</span>
                  <span className="label">Overdue (100%+)</span>
                </div>
              </ClickableSection>
              <ClickableSection onClick={() => handlePMsCountClick('dueSoon')}>
                <div className="due-soon">
                  <span className="count">{pmCounts.due_soon_count}</span>
                  <span className="label">Due Soon (80-99%)</span>
                </div>
              </ClickableSection>
            </>
          )}
        </PMsCol>
      </Row>
    </Card>
  );
};

PMCountWidget.propTypes = {
  isLoadingPMCounts: PropTypes.bool,
  pmCounts: PropTypes.shape({
    overdue_count: PropTypes.any,
    due_soon_count: PropTypes.any,
  }),
  handlePMsCountClick: PropTypes.func,
};

PMCountWidget.defaultProps = {
  isLoadingPMCounts: false,
  pmCounts: {
    overdue_count: 0,
    due_soon_count: 0,
  },
  handlePMsCountClick: () => {},
};

export default PMCountWidget;
