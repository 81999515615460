import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Progress } from 'antd';
import styled from 'styled-components';
import DocumentUploadList from './DocumentUploadList';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    background-color: #02103d;
    color: white;
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-close-x {
    color: white;
  }

  .loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 0.4rem;
    span {
      font-size: 14px;
      color: #02103d;
    }
  }
`;

const UploadImageModal = ({ isVisible, onUpload, onCancel, isLoading, uploading, progress }) => {
  const [fileList, setFileList] = useState([]);
  const [currentLoadingMsg, setCurrentLoadingMsg] = useState(
    'Hang tight, we are processing your files...'
  );

  const intervalRef = useRef(null);

  const randomLoadingMessages = [
    'Hang tight, we are processing your files...',
    'Working on your files... almost there!',
  ];

  useEffect(() => {
    if (isVisible) {
      intervalRef.current = setInterval(() => {
        setCurrentLoadingMsg(
          randomLoadingMessages[
            Math.floor(Math.random() * randomLoadingMessages.length)
          ]
        );
      }, 3000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isVisible, randomLoadingMessages]);

  useEffect(() => {
    if (isVisible) {
      setFileList([]);
    }
  }, [isVisible]);

  const handleFileChange = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadProps = {
    accept: '.png,.jpg,.jpeg',
    onRemove: (file) => {
      setFileList((curr) => {
        const index = curr.indexOf(file);
        const newFileList = curr.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList((curr) => [...curr, file]);
      return false; // Prevent automatic uploading
    },
    fileList,
    onChange: handleFileChange,
    multiple: false,
  };

  return (
    <StyledModal
      title="Upload Work Orders - Powered by AI"
      visible={isVisible}
      onOk={() => onUpload(fileList)}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="submit"
          type="primary"
          onClick={() => onUpload(fileList)}
        >
          {isLoading ? 'Processing' : 'Process'}
        </Button>,
      ]}
    >
      <p>
        Upload scanned copies of your work orders and allow Pitstop's AI system
        to transform them into digital versions. Upload a single file below, and
        our AI will scan your documents.
      </p>
      <p>
        Once you click Process, you will have the opportunity to review and make
        any adjustments to the information gathered from your files before
        saving the work orders in our system.
      </p>
      <p>
        <b>Add Work Orders:</b>
      </p>
      <DocumentUploadList
        isDisabled={fileList.length > 0}
        uploadProps={uploadProps}
      />
      {uploading && (
        <div className="loading-box">
          <Progress percent={progress} />
        </div>
      )}
      {isLoading && (
        <div className="loading-box">
          <span>{currentLoadingMsg}</span>
        </div>
      )}
    </StyledModal>
  );
};

UploadImageModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  uploading: PropTypes.bool,
  progress: PropTypes.number,
};

UploadImageModal.defaultProps = {
  isLoading: false,
  uploading: false,
  progress: 0,
};

export default UploadImageModal;
