import React from 'react';
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import {
  WorkOrderStatusEnum,
  WorkOrderTypeOfServiceEnum,
} from 'stores/Classes/WorkOrderObject';

const { Text } = Typography;

const WorkOrderSummary = ({ workOrder = {}, shopUsers = [] }) => {
  let createdByName = '';
  let assignedToName = '';

  if (shopUsers.length) {
    const createdBy = shopUsers.find(
      (user) => user.id === workOrder.created_by
    );
    createdByName = createdBy?.getFullName().trim() || workOrder.created_by;
    const assignedTo = shopUsers.find(
      (user) => user.id === workOrder.assigned_to
    );
    assignedToName = assignedTo?.getFullName().trim() || workOrder.assigned_to;
  }

  return (
    <Row gutter={[8, 16]}>
      <Col xs={12} sm={6}>
        <div>WO Number:</div>
        <Text strong>{workOrder.invoice_number}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Asset Type:</div>
        <Text strong>{workOrder.asset_type}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Select Asset:</div>
        <Text strong>{workOrder.car_name}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Status:</div>
        <Text strong>{WorkOrderStatusEnum[workOrder.status]}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Repair Type:</div>
        <Text strong>{WorkOrderTypeOfServiceEnum[workOrder.type]}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Created By:</div>
        <Text strong>{createdByName}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Assigned To:</div>
        <Text strong>{assignedToName}</Text>
      </Col>
    </Row>
  );
};

WorkOrderSummary.propTypes = {
  workOrder: PropTypes.object,
  shopUsers: PropTypes.array,
};

export default WorkOrderSummary;
