import _ from 'lodash';

import { CurrentUserStore } from 'stores';

export function calcMileage(distance) {
  let unit = CurrentUserStore.user.displayedOdometer;

  if (unit === 'miles') {
    return _.divide(Number(distance), 1.609344);
  } else {
    return Number(distance);
  }
}

export function convertDistanceValueToOriginal(distance) {
  let unit = CurrentUserStore.user.displayedOdometer;

  if (unit === 'miles') {
    return _.multiply(Number(distance), 1.609344);
  } else {
    return Number(distance);
  }
}

export function getMileageOrKm (distance) {
  let unit = CurrentUserStore.user.displayedOdometer;

  if (unit === 'miles') {
    return _.multiply(Number(distance), 1.609344);
  } else {
    return Number(distance);
  }
}

export function calcFuel(litre = 0, scannerId = '') {
  if (scannerId.split(':').at(0) === 'samsara'){
    litre = litre/1000;
  }
  if (CurrentUserStore.user.settings.fuelUnit === 'Gallons') {
    return _.divide(Number(litre), 3.785411784);
  } else {
    return Number(litre);
  }
}

export function calcMileageBack(miles) {
  let unit = CurrentUserStore.user.displayedOdometer;

  if (unit === 'miles') {
    return _.divide(Number(miles), 1.609344);
  } else {
    return Number(miles);
  }
}

export function convertToGrams(mg) {
  return _.divide(Number(mg), 1000);
}

export const convertHourToSeconds = (hours) => {
  return _.multiply(Number(hours), 3600);
};

export const convertSecondsToHours = (hours) => {
  return _.divide(Number(hours), 3600);
};
