import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'antd';

const DeleteIssueBtn = ({ onClick }) => {
  const [isConfirmDeleteModalOn, setConfirmDeleteModalOn] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setConfirmDeleteModalOn(true);
        }}
        style={{
          backgroundColor: 'rgb(249, 45, 46)',
          color: 'white',
          border: 'none'
        }}
      >
        Delete
      </Button>
      <Modal
        title="Confirm Delete"
        visible={isConfirmDeleteModalOn}
        okText="Delete"
        onOk={() => {
          onClick();
          setConfirmDeleteModalOn(false);
        }}
        onCancel={() => setConfirmDeleteModalOn(false)}
        okButtonProps={{
          style:{
            backgroundColor: 'rgb(249, 45, 46)',
            color: 'white',
            border: 'none'
          }
        }}
      >
        <p>Are you sure you want to delete this issue?</p>
      </Modal>
    </>
  );
};

DeleteIssueBtn.propTypes = {
  onClick: PropTypes.func
};

export default DeleteIssueBtn;
