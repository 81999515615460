import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Form, Select, InputNumber, Button, Card, Col, Row, Spin, notification, Switch, Tooltip } from 'antd'; // New: Import necessary components for filters
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { get } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const { Option } = Select; // New: Import Option from Select

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class FleetRepeatVisitsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingVehicleRepeatVisits: false,
    isLoadingReasonsForVisits: false,
    dataForReasonsForVisits: [],
    dataForVehicleRepeatVisits: [],
    pmDataForVehicleRepeatVisits: [],
    nonPMDataForVehicleRepeatVisits: [],
    isStacked: true, // to determine whether chart will be stacked or not
    timePeriod: 30, // New: Add timePeriod to state
    limit: 20, // New: Add limit to state 
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.init();
  });

  loadDataForVehicleRepeatVisits = async () => { // of interest
    try {
      this.setState({ isLoadingVehicleRepeatVisits: true });
      const { timePeriod, limit } = this.state; // New: Destructure timePeriod and limit from state - also pmType will be added here
      const response = await ShopStore.getShopDataForVehicleRepeatVisits(
        ShopStore.currentShop.id,
        timePeriod, // New: Pass timePeriod as parameter
        limit // New: Pass limit as parameter
      );
      const data = get(response, 'data', []);

      const idCars = Array.isArray(data) ? data.map(item => item.id_car) : [];
      const response2 = await ShopStore.getUnitIDsFromCarIds(idCars);
      const carNamesData = get(response2, 'data', []);

      if (carNamesData && data) {
        // Create a mapping of car IDs to car names
        const carNamesMap = {};
        carNamesData.forEach(car => {
          carNamesMap[car.id] = car.car_name;
        });

        // Process the data
        const combinedData = [];
        const pmData = [];
        const nonPMData = [];
        const yAxisCategories = data.map(item => carNamesMap[item.id_car]);

        data.forEach((item, index) => {
          const vehicleId = item.id_car;
          combinedData.push({
            x: index,
            y: item.visit_freq,
            vehicleId: vehicleId,
            carName: carNamesMap[vehicleId]
          });
          pmData.push({
            x: index,
            y: item.pm_visit_freq,
            vehicleId: vehicleId,
            carName: carNamesMap[vehicleId]
          });
          nonPMData.push({
            x: index,
            y: item.non_pm_visit_freq,
            vehicleId: vehicleId,
            carName: carNamesMap[vehicleId]
          });
        });

        this.setState({
          dataForVehicleRepeatVisits: combinedData,
          pmDataForVehicleRepeatVisits: pmData,
          nonPMDataForVehicleRepeatVisits: nonPMData,
          vehicles: yAxisCategories
        });
      }

    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ dataForVehicleRepeatVisits: [], pmDataForVehicleRepeatVisits: [], nonPMDataForVehicleRepeatVisits: [] });
    } finally {
      this.setState({ isLoadingVehicleRepeatVisits: false });
    }
  };

  // loadDataForReasonsForVisits = async () => { // of interest
  //   try {
  //     this.setState({ isLoadingReasonsForVisits: true });
  //     const data = await ShopStore.getShopDataForReasonsForVisits(
  //       ShopStore.currentShop.id
  //     );
  //     this.setState({ dataForReasonsForVisits: data });
  //   } catch (error) {
  //     notification.error({
  //       message: 'Error',
  //       description: error.message,
  //     });
  //   } finally {
  //     this.setState({ isLoadingReasonsForVisits: false });
  //   }
  // };

  init = async () => {
    // load data for Vehicle Repeat Visits
    this.loadDataForVehicleRepeatVisits();
    // load data for Reasons for Visits
    // this.loadDataForReasonsForVisits();
  };

  toggleChartType = (checked) => {
    this.setState({ isStacked: checked });
  };

  handleFilterChange = (changedFields) => { // New: Handle filter changes
    this.setState(changedFields);
  };

  handleApplyFilters = () => { // New: Apply filters
    this.init();
  };

  render() {
    const { isStacked, pmDataForVehicleRepeatVisits, nonPMDataForVehicleRepeatVisits, dataForVehicleRepeatVisits, timePeriod, limit, isLoadingVehicleRepeatVisits } = this.state;

    const vehicleRepeatVisitsChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.state.vehicles, // Adjusted field name
        crosshair: true,
        title: {
          text: 'Unit ID'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Number of Visits',
        },
        stackLabels: {
          enabled: isStacked,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray'
          }
        }
      },
      plotOptions: {
        column: {
          stacking: isStacked ? 'normal' : undefined,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y !== 0 ? this.y : null;
            },
            style: {
              textOutline: '2px contrast' // Adjust the thickness here
            }
          }
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                // Construct the URL dynamically
                const vehicleId = this.options.vehicleId;
                const customUrl = `https://dashboard.pitstopconnect.com/car/${vehicleId}`;
                // window.location.href = customUrl;
                window.open(customUrl, '_blank');
              }
            }
          }
        }
      },
      series: isStacked ? [
        {
          name: 'Unplanned Maintenance',
          data: nonPMDataForVehicleRepeatVisits.map(item => ({
            y: item.y,
            vehicleId: item.vehicleId,
            carName: item.carName
          })),
          color: '#bf2412',
        },
        {
          name: 'Planned Maintenance',
          data: pmDataForVehicleRepeatVisits.map(item => ({
            y: item.y,
            vehicleId: item.vehicleId,
            carName: item.carName
          })),
          color: '#123fbf',
        }
      ] : [
        {
          name: 'Total Visits',
          data: dataForVehicleRepeatVisits.map(item => ({
            y: item.y,
            vehicleId: item.vehicleId,
            carName: item.carName
          })),
          color: '#183cbc',
        }
      ],
    };


    // const reasonsForVisitsChartOptions = {
    //   chart: {
    //     type: 'column',
    //   },
    //   title: {
    //     text: 'Reasons for Visits',
    //   },
    //   xAxis: {
    //     categories: dataForReasonsForVisits.map(item => item.name), // Adjusted field name
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Cost',
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Cost',
    //       data: dataForReasonsForVisits.map(item => item.amount), // Adjusted field name
    //     },
    //   ],
    // };

    return (
      <Fragment>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <h1>Fleet Visit Frequency</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Form layout="inline" onValuesChange={this.handleFilterChange}> {/* New: Add form for filters */}
          <Form.Item label="Time Period">
            <Select defaultValue={30} value={timePeriod} onChange={value => this.handleFilterChange({ timePeriod: value })}>
              <Option value={30}>30 days</Option>
              <Option value={90}>90 days</Option>
              <Option value={180}>180 days</Option>
              <Option value={360}>360 days</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Limit">
            <InputNumber min={1} defaultValue={20} value={limit} onChange={value => this.handleFilterChange({ limit: value })} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>Apply Filters</Button> {/* New: Apply Filters button */}
          </Form.Item>
          <Form.Item label="Maintenance Insights">
            <Switch checked={isStacked}
              onChange={this.toggleChartType}
              checkedChildren="ON"
              unCheckedChildren="OFF"
            />
            <Tooltip placement="right" title={'Toggle to divide each bar in the chart highlighting a red section for unplanned maintenance and blue section for planned maintenance'}>
              <Button style={{ marginLeft: 10, marginRight: 20 }} size="small" shape="circle" icon="info" />
            </Tooltip>
          </Form.Item>
        </Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingVehicleRepeatVisits}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Vehicle Repeat Visits</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={vehicleRepeatVisitsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          {/* <Col span={8}>
            <Spin spinning={this.state.isLoadingVehicleCosts}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Vehicle Costs</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={vehicleCostsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col> */}
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(FleetRepeatVisitsPage));