import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Button, Modal } from 'antd';

import { AppStore, CurrentUserStore, IssueStore } from 'stores';

import ServiceFeedbackForm from './ServiceFeedbackForm';

const MarkAsCompleteBtn = ({ issue, onClick, title, type, shouldDisableButton, issues }) => {
  const [isServiceFeedbackModalOn, setServiceFeedbackModalOn] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState('');
  const [completedTime, setCompletedTime] = useState(
    moment.tz(CurrentUserStore.user.settings.timezone).toISOString()
  );

  const showPMFeedbacksModal = (issues) => {
    if (_.isEmpty(issues)) return;
    IssueStore.setPMFeedbackModalData({
      title: issues[0].name,
      currentStep: 1,
      pmsToReceiveFeedback: JSON.parse(JSON.stringify(issues)),
      isModalVisible: true,
    });
  };

  return (
    <>
      <Button
        onClick={() => {
          setServiceFeedbackModalOn(true);
        }}
        type={type}
        disabled={shouldDisableButton}
      >
        { title || 'Mark as Complete' }
      </Button>

      <Modal
        title="Add Service Feedback"
        visible={isServiceFeedbackModalOn}
        onOk={async () => {
          if (_.isNil(feedbackValue) || _.isEmpty(feedbackValue)) {
            AppStore.addNotification('Please add service feedback!');
            return;
          }

          setServiceFeedbackModalOn(false);

          AppStore.addLoading('Updating...');

          if (!_.isEmpty(issues)) {
            try {
              await Promise.all(
                issues.map((issue) => {
                  // // Update PM dont need to await
                  // CarStore.updateCarPM(issue.car.id, issue.name);
                  return issue.update({
                    status: 'done',
                    feedback: feedbackValue,
                    carVin: issue.car.vin,
                    doneAt: completedTime,
                    mileage: issue.car.mileageTotal
                      ? Number(issue.car.mileageTotal)
                      : null,
                    engineHours: issue.car.engineHours
                      ? Number(issue.car.engineHours)
                      : null
                  }, false);
                })
              );
              AppStore.addSuccess('The selected issues were successfully marked as done.');
              // get routine issues
              const PMIssues = issues.filter(issue => issue.source === 'routine');
              showPMFeedbacksModal(PMIssues);
            } catch (error) {
              AppStore.addError('Unable to update issues.');
            }
          } else {
            // Update PM dont need to await
            // CarStore.updateCarPM(issue.car.id, issue.name);
            await issue.update({
              status: 'done',
              feedback: feedbackValue,
              carVin: issue.car.vin,
              doneAt: completedTime,
              mileage: issue.car.mileageTotal
                ? Number(issue.car.mileageTotal)
                : null,
              engineHours: issue.car.engineHours
                ? Number(issue.car.engineHours)
                : null
            });
            if (issue.source === 'routine') {
              showPMFeedbacksModal([issue]);
            }
          }

          AppStore.removeLoading('Updating...');

          onClick(issues);
        }}
        onCancel={() => setServiceFeedbackModalOn(false)}
      >
        <ServiceFeedbackForm
          setFeedbackValue={value => {
            setFeedbackValue(value);
          }}
          setCompletedTime={value => {
            setCompletedTime(value);
          }}
        />
      </Modal>
    </>
  );
};

MarkAsCompleteBtn.propTypes = {
  issue: PropTypes.object,
  issues: PropTypes.array,
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  shouldDisableButton: PropTypes.bool
};

MarkAsCompleteBtn.defaultProps = {
  shouldDisableButton: false,
  issue: {},
  issues: [],
};

export default MarkAsCompleteBtn;
