import React, { Component, Fragment, createRef } from 'react';
import { withRouter } from 'react-router';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton, PitstopModal } from 'shared';
import {
  Row,
  Col,
  notification,
  Spin,
  Button,
  Typography,
  InputNumber,
  Input,
  Icon,
  Checkbox,
  Collapse,
  Tabs,
  DatePicker,
  Select,
} from 'antd';
import _, { deburr, get, isArray, isEmpty, kebabCase } from 'lodash';
import moment from 'moment';
import {
  WorkOrderStore,
  TableStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  CarStore,
  AppStore,
  IssueStore,
  ContactStore,
} from 'stores';
import WorkOrderForm from 'components/WorkOrder/WODetailsForm';
// import WorkOrderIssuesSummary from 'components/WorkOrder/WOIssuesSummary';
import WorkOrderLineItems from 'components/WorkOrder/LineItems/WorkOrderLineItems';
import WorkOrderCostSummary from 'components/WorkOrder/WorkOrderCostSummary';
import DealershipChooser from 'components/DealershipChooser';
import ImageUploader from './ImageUploadContainer';
import DocumentUploader from './DocumentUploader';

import { PitstopTableCacheStore } from 'stores/CacheStore';
import { webServiceProvider } from 'shared';
import UploadImageModal from './modals/UploadImageModal';
import { Logger } from 'stores/Classes';
import { Link } from 'react-router-dom';
import FormLegend from 'components/Forms/FormLegend';

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 25px 0 10px;

  max-width: 95vw;

  @media screen and (max-width: 1024px) {
    max-width: 95vw;
  }
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }

  @media screen and (max-width: 1024px) {
    .small-screen {
      font-size: 13px;
    }
  }
`;

const ActionButtonsWrapperFlexEnd = styled(ActionButtonsWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const UploadButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #1890ff;
  color: #1890ff;
  &:hover {
    background-color: #ecf6fd;
    border-color: #1890ff;
  }
  &:focus,
  &:active {
    background-color: #fff;
    border-color: #1890ff;
  }
`;

const ImportedInformation = styled.div`
  background-color: #f0f0f0;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  p {
    margin: 0;
  }
`;

const ADD_PART_MODAL = 'ADD_PART_MODAL';

const equalHeightStyle = {
  minHeight: '321px',
};

const documentUploaderStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: '2px',
  padding: '24px',
  marginTop: '15px',
  marginBottom: '24px',
  ...equalHeightStyle,
  maxHeight: '321px',
  overflow: 'auto',
};

class WorkOrderDetails extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  currentDateTime = moment();
  randomWorkOrderId = () => _.padStart(_.random(999999), '0');

  usersTableStore = new TableStore();

  shopId = ShopStore.currentShop.id;

  get shopUsers() {
    const users = _.map(this.usersTableStore.data, (id) =>
      UserStore.data.get(id)
    );
    return users;
  }

  state = {
    activeTab: 'work-order-details', // when this is set to optional-information, work order scan works
    lineItems: [],
    currentCarIssues: [],
    selectedCarId: undefined,
    savingWorkOrder: false,
    currentWorkOrder: null,
    loadingWorkOrder: true,
    loadingWorkOrderLines: true,
    loadingInitial: true,
    title: '',
    initialFormValues: {
      woInvoiceNumber: this.randomWorkOrderId(),
      woCreatedBy: '',
      woSelectVehicle: undefined,
      woAssetType: 'Trailer',
      woAssignedTo: '',
      woStatus: 'open',
      woRepairType: 'driver_identified',
      woRepairPriority: 'minor',
      woIssueReportDate: this.currentDateTime,
      woStartDate: undefined,
      woCompletedDate: undefined,
      mileage: undefined,
      engineHours: undefined,
      tax: {
        type: 'percentage',
        value: 0,
      },
      misc: {
        type: 'percentage',
        value: 0,
      },
      woSource: '',
      rawData: '',
      vendor_id: undefined,
    },
    shopUsers: [],
    shopParts: [],
    shopVendors: [],
    carDetails: {},
    newPart: {
      name: '',
      description: '',
      number: '',
      cost: 0,
    },
    loadingSavingNewPart: false,
    currentLinePartIsBeingAdded: null,
    uploadedFiles: [],
    uploadedImages: [],
    wo_images: [],
    wo_documents: [],
    showUploadButton: true,
    isUploadWorkOrderModalVisible: false,
    isLoadingWorkOrderUpload: false,
    importedInformation: null,
    isDisabled: false,
    uploading: false,
    progress: 0,
  };

  workOrderFormRef = createRef();
  lineItemsRef = createRef();
  costSummaryRef = createRef();
  imageUploaderContainerRef = {};
  // workOrderIssuesSummary = createRef();

  orderedSections = [
    'work-order-details',
    'work-order-line-items',
    'optional-information',
    'summary',
  ];

  forceUpdateTabs() {
    // Logger.log('supposedly forcing update of tabs');
    this.forceUpdate(); // Force a re-render of the component
  }

  async componentDidMount() {
    // Logger.log('componentDidMount is being executed');
    // check if is desktop, redirect to work order desktop page
    if (window.innerWidth >= 1024) {
      // check if add page
      const id = this.props?.match?.params?.id;
      if (id) {
        return this.props.history.push(`/work-order-desktop/${id}/edit`);
      }
      return this.props.history.push('/work-order-desktop/add');
    }

    this.load();

    // check if there is a 'tab' query param
    const queryParams = new URLSearchParams(this.props.history.location.search);
    const tab = queryParams.get('tab');
    if (tab) {
      this.setState({ activeTab: tab });
    }
    // Logger.log('componentDidMount is done running');
  }

  loadShopUsers = async () => {
    await UserStore.fetchUsersForTable(
      {
        offset: 0,
        limit: 100,
        filter: null,
      },
      this.usersTableStore
    );
  };

  loadShopParts = async () => {
    try {
      const shopParts = await WorkOrderStore.getShopParts(
        ShopStore.currentShop.id,
        {
          include_deleted: true,
        }
      );
      this.setState({ shopParts });
    } catch (error) {
      console.log(error.name, error);
      // check if error is abort error
      if (error.name === 'AbortError') {
        return;
      }
      return notification.error({
        message: 'Oops!',
        key: 'listShopParts',
        description: (
          <Container>Sorry, we couldn't fetch your Parts.</Container>
        ),
      });
    }
  };

  loadShopVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ shopVendors: [] });
        return;
      }
      const shopVendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ shopVendors });
    } catch (error) {
      Logger.error(error);
    }
  }

  hydrateLineItem = (lineItem, index) => {
    return {
      ...lineItem,
      header: lineItem.title || `New Work Order Line ${index + 1}`,
      key: lineItem.id || lineItem.key || index + 1,
      laborTotal: parseFloat(lineItem.labour_cost || 0),
      partsTotal: parseFloat(lineItem.parts_cost || 0),
      vmrs_asm_code_id: lineItem.vmrs_asm_code_id,
      vmrs_sys_code_id: lineItem.vmrs_sys_code_id,
      parts: lineItem.parts
        ? lineItem.parts.map((p, i) => ({
          ...p,
          originalAmount: parseInt(p.amount || 0),
          key: parseInt(`${i + 1}${p.id}`),
          name: `Part ${parseInt(`${i + 1}${p.id}`)}`,
          markup: parseFloat(p.markup || 0),
          cost: parseFloat(p.cost || 0),
        }))
        : [],
      labours: lineItem.labours
        ? lineItem.labours.map((p, i) => ({
          ...p,
          key: parseInt(`${i + 1}${p.id}`),
          name: `Labor ${parseInt(`${i + 1}${p.id}`)}`,
          cost: parseFloat(p.cost || 0),
        }))
        : [],
      services: lineItem.services
        ? lineItem.services.map((p, i) => ({
          ...p,
          key: parseInt(`${i + 1}${p.id}`),
        }))
        : [],
      expanded: true,
    };
  };

  hydrateLineItems = (lineItems = []) => {
    return lineItems.map(this.hydrateLineItem);
  };

  get queryAssetId() {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    return queryParams.get('assetId');
  }

  load = async () => {
    this.setState({ loadingInitial: true });
    await this.loadShopUsers();
    this.loadShopParts();
    this.loadShopVendors();
    this.setState({ loadingInitial: false });

    const id = this.props.match.params.id;
    if (id) {
      this.setState({ title: 'Edit' });
      // get work order by ID
      try {
        this.setState({ loadingWorkOrder: true });
        const workOrder = await WorkOrderStore.getWorkOrderById(id);
        if (!workOrder) {
          throw new Error('Work order not found');
        }
        // remove the cache from Issues table... to refresh the list in other components
        PitstopTableCacheStore.deleteKey(`${workOrder.id_car}-wo-issues-list`);
        this.setState({
          selectedCarId: workOrder.id_car,
          currentWorkOrder: {
            ...workOrder,
            // those values are not being used in the first tab form
            // they are being used in the optional-information tab
            woStartDate: workOrder.started_at
              ? moment(workOrder.started_at)
              : null,
            woCompletedDate: workOrder.done_at
              ? moment(workOrder.done_at)
              : null,
            mileage: workOrder.mileage,
            engineHours: workOrder.engine_hours,
            vendor_id: workOrder.vendor_id,
          },
          lineItems: this.hydrateLineItems(workOrder.work_order_lines),
          initialFormValues: {
            woInvoiceNumber: workOrder.invoice_number,
            woCreatedBy:
              workOrder.migrate_source !== 'PitStop'
                ? workOrder.migrate_created_by
                : workOrder.created_by,
            woSelectVehicle: workOrder.id_car,
            woAssetType: workOrder.asset_type || 'Trailer', // default to vehicle if asset type was not provided
            woAssignedTo: workOrder.assigned_to,
            woStatus: workOrder.status,
            woRepairType: workOrder.type,
            woRepairPriority: workOrder.priority,
            woIssueReportDate: workOrder.created_at
              ? moment(workOrder.created_at)
              : null,
            woStartDate: workOrder.started_at
              ? moment(workOrder.started_at)
              : null,
            woCompletedDate: workOrder.done_at
              ? moment(workOrder.done_at)
              : null,
            mileage: workOrder.mileage,
            engineHours: workOrder.engine_hours,
            vendor_id: workOrder.vendor_id,
            tax: {
              type: workOrder.tax_type || 'percentage',
              value: workOrder.tax || 0,
            },
            misc: {
              type: workOrder.misc_type || 'percentage',
              value: workOrder.misc || 0,
            },
            woSource: workOrder.migrate_source || 'PitStop',
            rawData:
              workOrder.migrate_source !== 'PitStop' ? workOrder.raw_data : '',
          },
          loadingWorkOrder: false,
          wo_images: workOrder.wo_files?.wo_images || [],
          wo_documents: workOrder.wo_files?.wo_documents || [],
          isDisabled: workOrder.migrate_source === 'TMT' ? true : false,
        });
      } catch (error) {
        // console.log(error);
        let friendlyMsg = 'Work order not found';
        this.setState({ loadingWorkOrder: false });
        return notification.error({
          message: 'Oops!',
          key: 'orderSaveError',
          description: <Container>{friendlyMsg}</Container>,
        });
      }
    } else {
      this.setState({ title: 'Add' });
      this.setState({ loadingWorkOrder: false });
      this.setState({ loadingWorkOrderLines: false });
      // check if query contains assetId
      if (this.queryAssetId) {
        this.setState({
          selectedCarId: +this.queryAssetId,
          initialFormValues: {
            ...this.state.initialFormValues,
            woSelectVehicle: +this.queryAssetId,
          },
        });
      }
    }

    // check if state lineItems is empty. If so, add a new blank hydrated line item
    // if (!this.state.lineItems.length) {
    //   this.onAddLineItem();
    // }
  };

  goBack = () => {
    if (this.queryAssetId) {
      return this.props.history.push(
        `/trailer/${this.queryAssetId}/work-orders`
      );
    }
    return this.props.history.push('/work-order');
  };

  onCarSelect = async (selectedCarId) => {
    this.setState({ selectedCarId: +selectedCarId });
    // load car issues
    try {
      const carIssues = await IssueStore.getCarIssuesList(
        {
          offset: 0,
          limit: 100,
          filter: {},
          sort: null,
          includeHistory: false,
        },
        selectedCarId
      );
      this.setState({
        currentCarIssues: carIssues,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        key: 'carIssues',
        description: <Container>{error.message}</Container>,
      });
    }
  };

  onChangeAssignedTo = (value) => {
    this.onAddLineItem([
      {
        key: new Date().getTime(),
        id_labour: value,
        hours: 0,
        cost: 0,
      },
    ]);
  };

  onChangeTaxType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          type,
        },
      },
    });
  };

  onChangeTaxValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          value,
        },
      },
    });
  };

  onChangeMiscType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          type,
        },
      },
    });
  };

  onChangeMiscValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          value,
        },
      },
    });
  };

  onAddLineItem = (withLabors = []) => {
    const key = this.state.lineItems.length + 1;
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.push({
      header: `New Work Order Line ${key}`,
      key: key,
      title: '',
      description: '',
      laborTotal: 0,
      partsTotal: 0,
      labours: withLabors,
      parts: [],
      services: [],
      expanded: true,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onChangeLineItemField = (lineKey, newVal, fieldName) => {
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineKey
    );
    const lineItem = this.state.lineItems.find((item) => item.key === lineKey);
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem[fieldName] = newVal;
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onRemoveLineItem = (itemKey) => {
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === itemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.splice(indexOfLineItem, 1);
    this.setState({
      lineItems: newLineItems,
    });
  };

  createWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.createWorkOrderOnCustomEndpoint({
        workOrder: {
          id_car: +payload.woSelectVehicle,
          asset_type: payload.woAssetType,
          id_shop: ShopStore.currentShop.id,
          status: payload.woStatus,
          type: payload.woRepairType,
          priority: payload.woRepairPriority,
          invoice_number: payload.woInvoiceNumber,
          assigned_to: +payload.woAssignedTo,
          created_by: +payload.woCreatedBy,
          tax: this.state.initialFormValues?.tax?.value || null,
          tax_type: this.state.initialFormValues?.tax?.type || null,
          misc: this.state.initialFormValues?.misc?.value || null,
          misc_type: this.state.initialFormValues?.misc?.type || null,
          migrate_source: 'PitStop',
          started_at: payload.woStartDate
            ? moment(payload.woStartDate).toDate()
            : null,
          done_at: payload.woCompletedDate
            ? moment(payload.woCompletedDate).toDate()
            : null,
          mileage: payload.mileage,
          engine_hours: payload.engineHours,
          vendor_id: payload.vendor_id,
          wo_files: payload.wo_files,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: this.state.currentWorkOrder.id,
        id_car: +payload.woSelectVehicle,
        asset_type: payload.woAssetType,
        status: payload.woStatus,
        type: payload.woRepairType,
        priority: payload.woRepairPriority,
        invoice_number: payload.woInvoiceNumber,
        assigned_to: +payload.woAssignedTo,
        created_by: +payload.woCreatedBy,
        tax: this.state.initialFormValues?.tax?.value || null,
        tax_type: this.state.initialFormValues?.tax?.type || null,
        misc: this.state.initialFormValues?.misc?.value || null,
        misc_type: this.state.initialFormValues?.misc?.type || null,
        started_at: payload.woStartDate
          ? moment(payload.woStartDate).toDate()
          : null,
        done_at: payload.woCompletedDate
          ? moment(payload.woCompletedDate).toDate()
          : null,
        mileage: payload.mileage,
        engine_hours: payload.engineHours,
        vendor_id: payload.vendor_id,
        wo_files: payload.wo_files,
      });

      // Check if we need to Resolve a DVIR
      if (
        payload.woStatus === 'resolved' &&
        payload.woRepairType === 'driver_identified' &&
        this.state.currentWorkOrder.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = this.state.currentWorkOrder.work_order_dvirs[0].dvir_id;
        this.state.currentWorkOrder.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveWorkOrder = async () => {
    const saveWorkOrderAfterValidation = async (payload) => {
      let workOrder;
      let woFileAndImages = {};
      if (this.state.activeTab === 'optional-information') {
        await this.uploadWorkOrderImages();
        await this.uploadWorkOrderFiles();
        woFileAndImages.wo_files = {
          wo_images: this.state.wo_images,
          wo_documents: this.state.wo_documents,
        };
      } else {
        // not in the optional-information tab, so if updating a work order, we need to keep the images and files
        if (this.state.currentWorkOrder) {
          woFileAndImages.wo_files = this.state.currentWorkOrder.wo_files;
        }
      }
      if (this.state.currentWorkOrder) {
        const modifiedPayload = {
          ...payload,
          ...woFileAndImages,
        };
        workOrder = await this.updateWorkOrder(modifiedPayload);
      } else {
        const modifiedPayload = {
          ...payload,
          ...woFileAndImages,
        };
        workOrder = await this.createWorkOrder(modifiedPayload);
      }

      // clear uploaded files and images
      this.setState({
        uploadedFiles: [],
        uploadedImages: [],
        wo_images: [],
        wo_documents: [],
      });

      return workOrder;
    };

    const workOrderFormProps = get(this.workOrderFormRef, 'props', null);
    const getPayloadFromCurrentWorkOrder = () => {
      return {
        woInvoiceNumber: this.state.currentWorkOrder.invoice_number,
        woCreatedBy: this.state.currentWorkOrder.created_by,
        woSelectVehicle: this.state.currentWorkOrder.id_car,
        woAssetType: this.state.currentWorkOrder.asset_type,
        woAssignedTo: this.state.currentWorkOrder.assigned_to,
        woStatus: this.state.currentWorkOrder.status,
        woRepairType: this.state.currentWorkOrder.type,
        woRepairPriority: this.state.currentWorkOrder.priority,
        woIssueReportDate: this.state.currentWorkOrder.created_at,
        woStartDate:
          this.state.currentWorkOrder.woStartDate ||
          this.state.currentWorkOrder.started_at,
        woCompletedDate:
          this.state.currentWorkOrder.woCompletedDate ||
          this.state.currentWorkOrder.done_at,
        mileage:
          this.state.currentWorkOrder.mileage ||
          this.state.currentWorkOrder.mileage,
        engineHours:
          this.state.currentWorkOrder.engineHours ||
          this.state.currentWorkOrder.engine_hours,
        tax: {
          type: this.state.currentWorkOrder.tax_type,
          value: this.state.currentWorkOrder.tax,
        },
        misc: {
          type: this.state.currentWorkOrder.misc_type,
          value: this.state.currentWorkOrder.misc,
        },
        vendor_id: this.state.currentWorkOrder.vendor_id,
        woSource: this.state.currentWorkOrder.migrate_source,
        rawData: this.state.currentWorkOrder.raw_data,
      };
    };
    if (!workOrderFormProps && this.state.currentWorkOrder) {
      // the main form was not rendered, adapt the payload based on the currentWorkOrder
      const payload = getPayloadFromCurrentWorkOrder();
      return await saveWorkOrderAfterValidation(payload);
    }

    return new Promise((resolve, reject) => {
      workOrderFormProps.form.validateFields(async (err, payload) => {
        if (err) {
          this.setState({ savingWorkOrder: false });
          AppStore.removeLoading('Saving Work Order...');
          let friendlyMsg = '';
          const errorKeys = Object.keys(err);
          if (
            errorKeys &&
            errorKeys[0] &&
            err[errorKeys[0]] &&
            err[errorKeys[0]].errors &&
            err[errorKeys[0]].errors.length &&
            err[errorKeys[0]].errors[0].message
          ) {
            friendlyMsg = err[errorKeys[0]].errors[0].message;
          } else {
            friendlyMsg = 'Error on saving Work Order';
          }
          return notification.error({
            message: 'Oops!',
            key: 'orderSaveError',
            description: <Container>{friendlyMsg}</Container>,
          });
        }
        try {
          if (isEmpty(payload) && this.state.currentWorkOrder) {
            payload = getPayloadFromCurrentWorkOrder();
          } else {
            // check if the current tab is optional-information
            if (this.state.activeTab === 'optional-information') {
              // add to payload the optional information
              // the images and docs are being added in the saveWorkOrderAfterValidation
              payload.woStartDate = this.state.currentWorkOrder.woStartDate;
              payload.woCompletedDate = this.state.currentWorkOrder.woCompletedDate;
              payload.mileage = this.state.currentWorkOrder.mileage;
              payload.engineHours = this.state.currentWorkOrder.engineHours;
              payload.vendor_id = this.state.currentWorkOrder.vendor_id;
            }
          }
          const workOrder = await saveWorkOrderAfterValidation(payload);
          resolve(workOrder);
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  formatWorkOrderLines = async (lines) => {
    return await Promise.all(
      lines.map(async (workOrderLine) => {
        const updatingData = {};
        if (workOrderLine.id) {
          updatingData.id = workOrderLine.id;
        }
        // remove empty parts (without a part selected)
        const parts = workOrderLine.parts.filter((p) => p.id_vehicle_part);
        // remove empty labours (without a labour selected)
        const labours = workOrderLine.labours.filter((l) => l.id_labour);

        // if vmrs_sys_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_sys_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(workOrderLine.vmrs_sys_code_id);
          const vmrsSysCode = response?.data;
          if (vmrsSysCode) {
            updatingData.vmrs_sys_text = vmrsSysCode.text;
            updatingData.vmrs_sys_code = vmrsSysCode.sys_text;
          }
        }

        // if vmrs_asm_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_asm_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(workOrderLine.vmrs_asm_code_id);
          const vmrsAsmCode = response?.data;
          if (vmrsAsmCode) {
            updatingData.vmrs_asm_text = vmrsAsmCode.text;
            updatingData.vmrs_asm_code = vmrsAsmCode.asm_text;
          }
        }

        return {
          ...updatingData,
          title:
            workOrderLine.title || `New Work Order Line ${workOrderLine.key}`,
          description: workOrderLine.description,
          vmrs_sys_code_id: workOrderLine.vmrs_sys_code_id,
          vmrs_asm_code_id: workOrderLine.vmrs_asm_code_id,
          labour_cost: parseFloat(workOrderLine.laborTotal),
          parts_cost: parseFloat(parseFloat(workOrderLine.partsTotal).toFixed(2)),
          parts: parts.map((p) => ({
            ...p,
            id_vehicle_part: p.id_vehicle_part,
            amount: p.amount ? parseInt(p.amount) : 0,
            originalAmount: p.originalAmount ? parseInt(p.originalAmount) : 0,
            markup: p.markup ? parseFloat(p.markup) : 0,
            cost: p.cost ? parseFloat(p.cost) : 0,
          })),
          labours: labours.map((l) => ({
            ...l,
            id_labour: l.id_labour,
            hours: l.hours || 0,
            cost: parseFloat(l.cost || 0),
          })),
          services: workOrderLine.services.map((i) => ({
            ...i,
            issue: i.issue,
            id_vehicle_service: +i.id_vehicle_service,
          })),
        };
      })
    );
  };

  saveWorkOrderLines = async (workOrder) => {
    try {
      this.setState({ savingWorkOrderLines: true });

      const formattedWorkOrderLines = await this.formatWorkOrderLines(
        this.state.lineItems
      );

      const payloadToSave = {
        workOrderLines: formattedWorkOrderLines,
      };

      const response = await WorkOrderStore.saveWorkOrderLinesOnCustomEndpoint(
        +workOrder.id,
        payloadToSave
      );

      // update parts unit cost on shop
      const lineItem = _.filter(this.state.lineItems, (lineItem) => {
        return lineItem.parts && lineItem.parts.length;
      });
      for (const lineItemWithParts of lineItem) {
        for (const part of lineItemWithParts.parts) {
          if (!part.id_vehicle_part) {
            continue;
          }
          await WorkOrderStore.updatePartOnCustomEndpoint({
            id: part.id_vehicle_part,
            cost: part.cost,
            markup: part.markup,
          });
        }
      }

      // update parts stock
      const workOrderLines = _.filter(formattedWorkOrderLines, (lineItem) => {
        return lineItem.parts && lineItem.parts.length;
      });
      for (const lineItemWithParts of workOrderLines) {
        for (const part of lineItemWithParts.parts) {
          if (!part.id_vehicle_part) {
            continue;
          }
          await WorkOrderStore.updatePartStock(part);
        }
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      this.setState({ savingWorkOrderLines: false });
    }
  };

  onClickSave = async (nextBehavior = 'next-section') => {
    try {
      // save the work order first...
      const isNewWorkOrder = !this.state.currentWorkOrder;
      this.setState({ savingWorkOrder: true });
      AppStore.addLoading('Saving Work Order...');
      const newWorkOrder = await this.saveWorkOrder();
      AppStore.removeLoading('Saving Work Order...');
      if (!newWorkOrder) {
        return;
      }

      // save the newWorkOrder on current state
      this.setState({
        currentWorkOrder: {
          ...newWorkOrder,
          // those values are not being used in the first tab form
          // they are being used in the optional-information tab
          woStartDate: newWorkOrder.started_at
            ? moment(newWorkOrder.started_at)
            : null,
          woCompletedDate: newWorkOrder.done_at
            ? moment(newWorkOrder.done_at)
            : null,
          mileage: newWorkOrder.mileage,
          engineHours: newWorkOrder.engine_hours,
          vendor_id: newWorkOrder.vendor_id,
        },
        // lineItems: this.hydrateLineItems(newWorkOrder.work_order_lines),
      });

      // if there's no errors, save the work order lines
      AppStore.addLoading('Saving Work Order Lines...');
      await this.saveWorkOrderLines(newWorkOrder);
      AppStore.removeLoading('Saving Work Order Lines...');

      // WorkOrderStore.addWorkOrderToStore(newWorkOrder.id, newWorkOrder, true);
      PitstopTableCacheStore.deleteKey(`${this.shopId}-work-order-list`);
      WorkOrderStore.clearShopWorkOrdersCache(this.shopId);

      this.setState({ savingWorkOrder: false });

      notification.success({
        message: 'Work order saved',
        key: 'orderUpdated',
        description: (
          <Container>
            You can always click on the work order for more details and to make
            edits
          </Container>
        ),
      });

      if (this.queryAssetId) {
        return this.props.history.push(
          `/trailer/${this.queryAssetId}/work-orders`
        );
      }

      if (nextBehavior === 'exit') {
        return this.props.history.push('/work-order');
      }

      const currentSection = this.state.activeTab;
      const lastSection = this.orderedSections[this.orderedSections.length - 1];
      const isLastSection = currentSection === lastSection;
      const nextSection = this.orderedSections[
        this.orderedSections.indexOf(currentSection) + 1
      ];

      if (isNewWorkOrder && isLastSection) {
        return this.props.history.push(
          `/work-order/${newWorkOrder.id}/edit?tab=${lastSection}`
        );
      } else if (isNewWorkOrder) {
        return this.props.history.push(
          `/work-order/${newWorkOrder.id}/edit?tab=${nextSection}`
        );
      } else {
        if (!isLastSection) {
          this.setState({ activeTab: nextSection }, () => {
            // update the query param
            this.props.history.push({
              search: `?tab=${this.state.activeTab}`,
            });
          });
        }
        // just reload
        return this.load();
      }
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Work Order. Try again later';
      Logger.log(error.message);
      if (error && error.message) {
        try {
          const errorObj = JSON.parse(error.message);
          friendlyMsg = _.isArray(errorObj.message)
            ? errorObj.message[0]
            : errorObj.message;
        } catch (error) {
          Logger.log(error);
          friendlyMsg = error.message;
        }
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
      AppStore.removeLoading('Saving Work Order...');
      AppStore.removeLoading('Saving Work Order Lines...');
      this.setState({ savingWorkOrder: false });
    }
  };

  uploadWorkOrderImages = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedImages;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_images: [...prevState.wo_images, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_images:', this.state.wo_images);
        }
      );
    }
  };

  uploadWorkOrderFiles = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedFiles;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_documents: [...prevState.wo_documents, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_documents:', this.state.wo_documents);
        }
      );
    }
  };

  // show modal to add new Part
  showModalToAddPart = (partName) => {
    this.setState({
      newPart: {
        name: '',
        description: partName,
        number: '',
        cost: 0,
      },
    });
    AppStore.openModals.set(ADD_PART_MODAL, true);
  };

  /**
   * Add a new part to the shop
   * @param {object} payload
   * @param {object} options
   * @return {Promise<object|null>} newPart
   */
  addNewPart = async (
    { description = '', number = '', cost = 0, addToInventory = false },
    { showSuccessAlert = true, hideModal = true, lineItemToAddPart = null }
  ) => {
    const getName = () => (number ? `${number} - ${description}` : description);
    const payload = {
      id_shop: ShopStore.currentShop.id,
      name: getName(),
    };
    // Logger.log('here a new part is attempting to get added');
    const newPart = await WorkOrderStore.createPartOnCustomEndpoint(payload);
    // update the new part with the description and number
    await WorkOrderStore.updatePartOnCustomEndpoint({
      id: newPart.id,
      description,
      number,
      cost,
      addToInventory,
    });
    if (showSuccessAlert) {
      notification.success({
        message: 'Part created',
        key: `newPartCreated${newPart.id}`,
        description: (
          <Container>The new part has been added to your account</Container>
        ),
      });
    }
    if (hideModal) {
      AppStore.openModals.set(ADD_PART_MODAL, false);
    }
    await this.loadShopParts();
    if (lineItemToAddPart) {
      const lineItemRef = this.lineItemsRef.workOrderLinesDetailsRefs[
        lineItemToAddPart
      ];
      if (lineItemRef) {
        lineItemRef.onAddExistingPart({
          amount: 1,
          id_vehicle_part: newPart.id,
          name: getName(),
          markup: 0,
          cost: parseFloat(cost),
        });
      }
    }

    return newPart;
  };

  onSubmitAddPartModal = async () => {
    try {
      this.setState({
        loadingSavingNewPart: true,
      });
      const {
        description,
        number,
        cost,
        addToInventory = false,
      } = this.state.newPart;
      await this.addNewPart(
        {
          description,
          number,
          cost,
          addToInventory,
        },
        {
          showSuccessAlert: true,
          hideModal: true,
          lineItemToAddPart: this.state.currentLinePartIsBeingAdded,
        }
      );
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Part. Try again later';
      console.log(error.message);
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = _.isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
    } finally {
      this.setState({
        loadingSavingNewPart: false,
      });
    }
  };

  onAddNewPart = async (partName = '', lineKey = null) => {
    this.setState({
      currentLinePartIsBeingAdded: lineKey,
    });
    this.showModalToAddPart(partName);
  };

  onUpdateLaborItem = (lineItemKey, laborKey, newVal) => {
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const labour = lineItem.labours.find((labour) => labour.key === laborKey);
    _.merge(labour, newVal);
    // refresh cost
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineItemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem.laborTotal = _.sumBy(lineItem.labours, (labour) => {
      return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
    });
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onUpdatePartItem = (lineItemKey, partKey, newVal, changedField) => {
    if (changedField === 'id_vehicle_part') {
      const shopPart = this.state.shopParts.find(
        (part) => part.id === newVal.id_vehicle_part
      );
      if (shopPart && shopPart.cost !== null && shopPart.cost !== undefined) {
        const partCost = parseFloat(shopPart.cost);
        newVal.cost = partCost;
      }
    }
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const part = lineItem.parts.find((part) => part.key === partKey);
    _.merge(part, newVal);
    // refresh cost
    lineItem.partsTotal = _.sumBy(lineItem.parts, (part) => {
      const cost = part.cost ? parseFloat(part.cost) : 0;
      const markup = part.markup ? parseFloat(part.markup) : 0;
      const amount = part.amount ? parseInt(part.amount) : 0;
      return (cost + (cost * markup) / 100) * amount;
    });
  };

  onSelectSystemCode = (lineItemKey, systemCode) => {
    // update the current vmrs_sys_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_sys_code_id = systemCode;
    lineItem.vmrs_sys_code = null;
    lineItem.vmrs_sys_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onSelectAsmCode = (lineItemKey, asmCode) => {
    // update the current vmrs_asm_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_asm_code_id = asmCode;
    lineItem.vmrs_asm_code = null;
    lineItem.vmrs_asm_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onUpdateServiceItem = (lineItemKey, serviceKey, newVal) => {
    // Logger.log('onUpdateServiceItem is being run');
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const service = lineItem.services.find(
      (service) => service.key === serviceKey
    );
    _.merge(service, newVal);
  };

  onChangeCollapseItems = (activeLineItemsKeys) => {
    const { lineItems } = this.state;
    lineItems.forEach((lineItem) => {
      if (activeLineItemsKeys.includes(`${lineItem.key}`)) {
        lineItem.expanded = true;
      } else {
        lineItem.expanded = false;
      }
    });
    this.forceUpdate();
  };

  onGetCarDetails = (carDetails) => {
    this.setState({
      carDetails,
    });
  };

  handleExportClick = async (type) => {
    const workOrderId = this.props.match.params.id;

    switch (type) {
      case 'print':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.printWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      case 'download':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.downloadWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      default:
        break;
    }
  };

  handleFileUpload = (file, additionalData) => {
    console.log('Before update wo_documents:', this.state.wo_documents);
    this.setState(
      (prevState) => {
        let updatedDocuments = prevState.uploadedFiles;
        let woDocumentsUpdated = prevState.wo_documents;

        if (additionalData === 'removed') {
          updatedDocuments = prevState.uploadedFiles.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woDocumentsUpdated = prevState.wo_documents.filter(
            (documentUrl) => documentUrl !== file.url
          );
        } else {
          updatedDocuments = [
            ...prevState.uploadedFiles,
            { file, additionalData },
          ];
        }

        return {
          uploadedFiles: updatedDocuments,
          wo_documents: woDocumentsUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after file upload:',
          this.state.uploadedFiles
        );
        console.log('Updated wo_documents:', this.state.wo_documents);
      }
    );

    // console.log('Document uploaded:', file);
    // console.log('Additional data:', additionalData);
    // console.log('State data:', this.state.uploadedFiles);
  };

  handleImageUpload = (file, additionalData) => {
    console.log('Before update wo_images:', this.state.wo_images);
    this.setState(
      (prevState) => {
        let updatedImages = prevState.uploadedImages;
        let woImagesUpdated = prevState.wo_images;

        if (additionalData === 'removed') {
          // Remove file from uploadedImages based on uid and name
          updatedImages = prevState.uploadedImages.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woImagesUpdated = prevState.wo_images.filter(
            (imageUrl) => imageUrl !== file.url
          );
        } else {
          updatedImages = [
            ...prevState.uploadedImages,
            { file, additionalData },
          ];
        }

        return {
          uploadedImages: updatedImages,
          wo_images: woImagesUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after image upload:',
          this.state.uploadedImages
        );
        console.log('Updated wo_images:', this.state.wo_images);
      }
    );

    // console.log('Image uploaded:', file);
    // console.log('Additional data:', additionalData);
  };

  loadWorkOrderWithExtractedInformation = async (data) => {
    // Logger.log('we are now attempting to fill in line items with json');
    this.setState({ importedInformation: JSON.stringify(data, null, 4) });
    const clearStr = (str) => deburr(kebabCase(str)).toLowerCase();
    const isFound = (str) => str && clearStr(str) !== 'not-found';
    try {
      // Logger.log('loading work order supposedly');
      AppStore.addLoading('Loading Work Order');
      const extractedInitialFormValues = {
        woRepairType: 'driver_identified',
      };
      // wo #
      if (isFound(data['Work Order Number'])) {
        extractedInitialFormValues.woInvoiceNumber = data['Work Order Number'];
      }
      // start date
      if (isFound(data['Service Date'])) {
        if (moment(data['Service Date'], 'MM/DD/YYYY').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'MM/DD/YYYY'
          );
        } else if (moment(data['Service Date'], 'YYYY-MM-DD').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'YYYY-MM-DD'
          );
        }
      }
      // vehicle
      if (isFound(data['Vehicle Identifier'])) {
        const { result: vehicle } = await CarStore.getShopCarByVin(
          ShopStore.currentShop.id,
          data['Vehicle Identifier']
        );
        if (vehicle.length) {
          extractedInitialFormValues.woSelectVehicle = vehicle[0].id;
        } else {
          extractedInitialFormValues.extractedVin = data['Vehicle Identifier'];
        }
      }
      // Tax
      if (isFound(data['Tax'])) {
        extractedInitialFormValues.tax = {
          type: 'absolute',
          value: parseFloat(data['Tax']),
        };
      }
      // Service Line Items
      const lineItems = [];
      if (
        isArray(data['Service Line Items']) &&
        !isEmpty(data['Service Line Items'])
      ) {
        const serviceLineItems = data['Service Line Items'];
        for (const serviceLineItem of serviceLineItems) {
          if (isFound(serviceLineItem['name'])) {
            const lineItem = {
              key: lineItems.length + 1,
              title: serviceLineItem['name'],
              description: serviceLineItem['description'] || '',
              labour_cost: 0,
              parts_cost: 0,
              parts: [],
              labours: [],
            };
            // check if has parts and add it too
            if (serviceLineItem['parts'] && isArray(serviceLineItem['parts'])) {
              // for each part, search that part based on it's name. If not found, create one and get the data back.
              for (const part of serviceLineItem['parts']) {
                if (!isFound(part['name'])) {
                  // if the part name is not found, skip it
                  continue;
                }
                const partName = part['name'];
                const partCost = isFound(part['unit_cost'])
                  ? parseFloat(part['unit_cost'])
                  : 0;
                const partNumber = isFound(part['part_number'])
                  ? part['part_number']
                  : '';
                const partMarkup = isFound(part['mark_up'])
                  ? parseFloat(part['mark_up'])
                  : 0;
                const partQuantity = isFound(part['quantity'])
                  ? parseInt(part['quantity'])
                  : 0;
                // search that part name in the shop/subshop parts
                let foundPart = this.state.shopParts.find((shopPart) => {
                  return (
                    clearStr(shopPart.name) ===
                    clearStr(`${partNumber} - ${partName}`)
                  );
                });
                if (!foundPart) {
                  // if not found, create a new part
                  foundPart = await this.addNewPart(
                    {
                      description: partName,
                      number: partNumber || '',
                      cost: partCost,
                      addToInventory: false,
                    },
                    {
                      showSuccessAlert: false,
                      hideModal: false,
                      lineItemToAddPart: null,
                    }
                  );
                }
                lineItem.parts.push({
                  key: lineItem.parts.length + 1,
                  id_vehicle_part: foundPart.id,
                  name: foundPart.name,
                  amount: partQuantity,
                  originalAmount: partQuantity,
                  markup: partMarkup,
                  cost: partCost,
                });
              }
            }

            // refresh parts total cost
            lineItem.parts_cost = _.sumBy(lineItem.parts, (part) => {
              const cost = part.cost ? parseFloat(part.cost) : 0;
              const markup = part.markup ? parseFloat(part.markup) : 0;
              const amount = part.amount ? parseInt(part.amount) : 0;
              return (cost + (cost * markup) / 100) * amount;
            });

            // push the lineItem before handling parts
            lineItems.push(lineItem);
          }
        }
      }
      const hydratedLineItems = this.hydrateLineItems(lineItems);
      this.setState({
        lineItems: hydratedLineItems,
        initialFormValues: {
          ...this.state.initialFormValues,
          ...extractedInitialFormValues,
        },
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading Work Order');
    }
  };

  handleWorkOrderUpload = async (fileList) => {
    if (!fileList.length) {
      return;
    }
    try {
      this.setState({ uploading: true, progress: 0 });
      const firstFile = fileList[0].originFileObj;
      // pass the first file to the ImageUploadContainer
      this.imageUploaderContainerRef.handlePreview(firstFile);

      // convert first file to base64
      const reader = new FileReader();
      reader.readAsDataURL(firstFile);
      reader.onload = async (e) => {
        const base64 = e.target.result;
        const withoutHeaders = base64.split(',')[1];
        // upload the base64 to the server
        try {
          this.setState({ isLoadingWorkOrderUpload: true, progress: 75 });
          const data = await WorkOrderStore.workOrderImgOcr({
            base64: withoutHeaders,
          });
          this.setState({ isUploadWorkOrderModalVisible: false, progress: 90 }, () => {
            this.loadWorkOrderWithExtractedInformation(data);
          });
          this.setState({ uploading: false, progress: 100 });
        } catch (error) {
          Logger.log('Error:', error);
        } finally {
          this.setState({ isLoadingWorkOrderUpload: false, uploading: false, progress: 0 });
        }
      };

    } catch (error) {
      Logger.error(error);
    }
  };

  get vendorOptions() {
    return this.state.shopVendors.map((user, index) => (
      <Select.Option key={index} value={user.id}>
        {user.name}
      </Select.Option>
    ));
  }

  render() {
    const { showUploadButton, isUploadWorkOrderModalVisible, uploading, progress } = this.state;

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    // const vendorOptions = this.state.shopVendors

    return this.state.loadingWorkOrder || this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <Fragment>
        <DealershipChooser />
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <h1 className='page-title'>{this.state.title} Work Order</h1>
            {showUploadButton && !this.state.currentWorkOrder && (
              <Row gutter={16}>
                <Col span={24}>
                  <UploadButton
                    icon="upload"
                    onClick={() => {
                      this.setState({ isUploadWorkOrderModalVisible: true }, () => {
                        this.forceUpdateTabs(); // Force re-render after setting the state
                      });
                    }}
                  >
                    Scan Work Order
                  </UploadButton>
                  <UploadImageModal
                    isVisible={isUploadWorkOrderModalVisible}
                    isLoading={this.state.isLoadingWorkOrderUpload}
                    onUpload={this.handleWorkOrderUpload}
                    onCancel={() => {
                      this.setState({ isUploadWorkOrderModalVisible: false });
                    }}
                    uploading={uploading}
                    progress={progress}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={24} sm={12}>
            {this.state.currentWorkOrder && (
              <ActionButtonsWrapperFlexEnd>
                <Button
                  type="primary"
                  ghost
                  onClick={() => this.handleExportClick('download')}
                  className='small-screen'
                >
                  <Icon type="download" />
                  Download
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() => this.handleExportClick('print')}
                  className='small-screen'
                >
                  <Icon type="printer" />
                  Print
                </Button>
              </ActionButtonsWrapperFlexEnd>
            )}
          </Col>
        </Row>
        {this.state.importedInformation && (
          <Row style={{ marginTop: '20px' }}>
            <Col span={18} offset={3}>
              <Collapse defaultActiveKey={[]} onChange={() => { }}>
                <Panel header="Imported Information" key="1">
                  <ImportedInformation>
                    <pre>{this.state.importedInformation}</pre>
                  </ImportedInformation>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
        <Row className="limit-size-tablets">
          <Col span={24}>
            <Tabs
              className='scrollable-tabs'
              defaultActiveKey={this.state.activeTab}
              onChange={(key) => {
                this.setState({ activeTab: key }, () => {
                  this.props.history.push({
                    search: `?tab=${this.state.activeTab}`,
                  });
                });
              }}
            >
              <Tabs.TabPane tab="Work Order Details" key="work-order-details">
                <Row type="flex" justify="center">
                  <Col sm={24} lg={18}>
                    <WorkOrderForm
                      childRef={(ref) => (this.workOrderFormRef = ref)}
                      workOrder={this.state.initialFormValues}
                      shopUsers={this.shopUsers}
                      onCarSelect={this.onCarSelect}
                      onChangeAssignedTo={this.onChangeAssignedTo}
                      onGetCarDetails={this.onGetCarDetails}
                      isDisabled={this.state.isDisabled}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Work Order Lines"
                key="work-order-line-items"
                disabled={!this.state.currentWorkOrder}
              >
                <Row type="flex" justify="center">
                  <Col sm={24} lg={18}>
                    <WorkOrderLineItems
                      childRef={(ref) => (this.lineItemsRef = ref)}
                      lineItems={this.state.lineItems}
                      shopUsers={this.shopUsers}
                      currentCarIssues={this.state.currentCarIssues}
                      shopParts={this.state.shopParts}
                      onAddLineItem={() => this.onAddLineItem([])}
                      onRemoveLineItem={this.onRemoveLineItem}
                      onChangeLineItemField={this.onChangeLineItemField}
                      onAddNewPart={this.onAddNewPart}
                      onUpdateLaborItem={this.onUpdateLaborItem}
                      onUpdatePartItem={this.onUpdatePartItem}
                      onUpdateServiceItem={this.onUpdateServiceItem}
                      onChangeCollapseItems={this.onChangeCollapseItems}
                      onSelectSystemCode={this.onSelectSystemCode}
                      onSelectAsmCode={this.onSelectAsmCode}
                      isDisabled={this.state.isDisabled}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Optional Information"
                key="optional-information"
                disabled={!this.state.currentWorkOrder}
              >
                <Row type="flex" justify="center">
                  <Col className="pb-15" sm={24} lg={18}>
                    <FormLegend>Optional Details</FormLegend>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <label>Work Order Start:</label>
                        <DatePicker
                          format={'MM/DD/YYYY HH:mm:ss'}
                          value={this.state.currentWorkOrder?.woStartDate}
                          onChange={(date) => {
                            this.setState({
                              currentWorkOrder: {
                                ...this.state.currentWorkOrder,
                                woStartDate: date,
                              },
                            });
                          }}
                          style={{ width: '100%' }}
                          showTime
                          placeholder="Select date and time"
                          disabled={this.state.isDisabled}
                        />
                      </Col>
                      <Col span={12}>
                        <label>Work Order Completed:</label>
                        <DatePicker
                          format={'MM/DD/YYYY HH:mm:ss'}
                          value={this.state.currentWorkOrder?.woCompletedDate}
                          onChange={(date) => {
                            this.setState({
                              currentWorkOrder: {
                                ...this.state.currentWorkOrder,
                                woCompletedDate: date,
                              },
                            });
                          }}
                          style={{ width: '100%' }}
                          showTime
                          placeholder="Select date and time"
                          disabled={this.state.isDisabled}
                        />
                      </Col>
                      <Col span={12}>
                        <label>Mileage:</label>
                        <InputNumber
                          value={this.state.currentWorkOrder?.mileage}
                          onChange={(value) => {
                            this.setState({
                              currentWorkOrder: {
                                ...this.state.currentWorkOrder,
                                mileage: value,
                              },
                            });
                          }}
                          style={{ width: '100%' }}
                          placeholder="Mileage"
                          disabled={this.state.isDisabled}
                          inputMode="tel"
                        />
                      </Col>
                      <Col span={12}>
                        <label>Engine Hours:</label>
                        <InputNumber
                          value={this.state.currentWorkOrder?.engineHours}
                          onChange={(value) => {
                            this.setState({
                              currentWorkOrder: {
                                ...this.state.currentWorkOrder,
                                engineHours: value,
                              },
                            });
                          }}
                          style={{ width: '100%' }}
                          placeholder="Engine Hours"
                          disabled={this.state.isDisabled}
                          inputMode="tel"
                        />
                      </Col>
                      <Col span={12}>
                        <label>Vendor:</label>
                        <Select
                          value={this.state.currentWorkOrder?.vendor_id}
                          onChange={(value) => {
                            this.setState({
                              currentWorkOrder: {
                                ...this.state.currentWorkOrder,
                                vendor_id: value,
                              },
                            });
                          }}
                          style={{ width: '100%' }}
                          placeholder="Select a User"
                          showSearch
                          optionFilterProp="children"
                          filterOption={filterOption}
                        >
                          {this.vendorOptions}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs={24} sm={24} lg={18}>
                    <FormLegend>Upload Content</FormLegend>
                    <ImageUploader
                      childRef={(ref) => {
                        Logger.log('here the ref is being assigned');
                        this.imageUploaderContainerRef = ref;
                        Logger.log('Ref Assigned:', this.imageUploaderContainerRef);
                      }}
                      onImageUploaded={this.handleImageUpload}
                      wo_files={
                        this.state.currentWorkOrder?.wo_files || {
                          wo_images: [],
                          wo_documents: [],
                        }
                      }
                    />

                    <div style={documentUploaderStyle}>
                      <DocumentUploader
                        onFileUploaded={this.handleFileUpload}
                        wo_files={
                          this.state.currentWorkOrder?.wo_files || {
                            wo_images: [],
                            wo_documents: [],
                          }
                        }
                      />
                    </div>
                  </Col> */}
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Summary"
                key="summary"
                disabled={!this.state.currentWorkOrder}
              >
                <Row type="flex" justify="center">
                  <Col sm={24} lg={18}>
                    <WorkOrderCostSummary
                      childRef={(ref) => (this.costSummaryRef = ref)}
                      lineItems={this.state.lineItems}
                      tax={this.state.initialFormValues.tax}
                      misc={this.state.initialFormValues.misc}
                      onChangeTaxType={this.onChangeTaxType}
                      onChangeTaxValue={this.onChangeTaxValue}
                      onChangeMiscType={this.onChangeMiscType}
                      onChangeMiscValue={this.onChangeMiscValue}
                      currentWorkOrder={this.state.currentWorkOrder}
                      shopUsers={this.shopUsers}
                      shopParts={this.state.shopParts}
                      currentCarIssues={this.state.currentCarIssues}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>

        <Row className="limit-size-tablets" style={{ marginTop: '20px' }}>
          <Col xs={24} sm={24} lg={18}>
            <FormLegend>Upload Content</FormLegend>
            <ImageUploader
              childRef={(ref) => {
                // Logger.log('here the ref is being assigned');
                this.imageUploaderContainerRef = ref;
                // Logger.log('Ref Assigned:', this.imageUploaderContainerRef);
              }}
              onImageUploaded={this.handleImageUpload}
              wo_files={
                this.state.currentWorkOrder?.wo_files || {
                  wo_images: [],
                  wo_documents: [],
                }
              }
            />
            <div style={documentUploaderStyle}>
              <DocumentUploader
                onFileUploaded={this.handleFileUpload}
                wo_files={
                  this.state.currentWorkOrder?.wo_files || {
                    wo_images: [],
                    wo_documents: [],
                  }
                }
              />
            </div>
          </Col>
        </Row>
        <ActionsWrapper>
          <div></div>
          <ActionButtonsWrapper>
            <PitstopButton type="link" onClick={this.goBack}>
              Cancel
            </PitstopButton>
            {
              // if activeTab is not summary, show Save & Exit
              this.state.activeTab !== 'summary' && (
                <PitstopButton
                  onClick={() => this.onClickSave('exit')}
                  type="link"
                  disabled={this.state.isDisabled}
                >
                  Save & Exit
                </PitstopButton>
              )
            }
            <PitstopButton
              loading={this.state.savingWorkOrder}
              onClick={this.onClickSave}
              disabled={this.state.isDisabled}
            >
              {this.state.activeTab === 'summary' ? 'Finish' : 'Next Section'}
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>

        <StyledModal
          id={ADD_PART_MODAL}
          title={'Add New Part'}
          width={640}
          okText="Save"
          footer={
            <>
              <Button
                key="back"
                onClick={() => {
                  AppStore.openModals.set(ADD_PART_MODAL, false);
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.onSubmitAddPartModal();
                }}
                loading={this.state.loadingSavingNewPart}
              >
                Save
              </Button>
            </>
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>Part Number</Text>
              <Input
                style={{ width: '100%' }}
                value={this.state.newPart.number}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      number: e.target.value,
                    },
                  });
                }}
              />
              {String(this.state.newPart.number).trim() &&
                this.state.shopParts.find(
                  (shopPart) =>
                    String(shopPart.number).trim() ===
                    String(this.state.newPart.number).trim()
                ) && (
                  <Typography.Text type="danger">
                    A part with this number already exists in your inventory. To
                    avoid duplicates, increase its stock count instead of adding
                    a new part.{' '}
                    <Link
                      to={`/parts/${this.state.shopParts.find(
                        (shopPart) =>
                          String(shopPart.number).trim() ===
                          String(this.state.newPart.number).trim()
                      ).id
                        }`}
                    >
                      Go to Existing Part
                    </Link>
                  </Typography.Text>
                )}
            </Col>
            <Col span={24}>
              <Text strong>Description</Text>
              <TextArea
                id="description"
                rows={4}
                placeholder="Add Description"
                value={this.state.newPart.description}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      description: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Text strong>Unit Cost:</Text>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                value={this.state.newPart.cost}
                onChange={(value) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      cost: value,
                    },
                  });
                }}
                inputMode="tel"
              />
            </Col>
            <Col span={24}>
              <Checkbox
                style={{
                  marginTop: '10px',
                }}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      addToInventory: e.target.checked,
                    },
                  });
                }}
              >
                Check box to add part to Parts List inventory. Leave unchecked
                to exclude from inventory
              </Checkbox>
            </Col>
          </Row>
        </StyledModal>
      </Fragment>
    );
  }
}

decorate(WorkOrderDetails, {
  shopId: observable,
  shopUsers: computed,
});

export default withRouter(observer(WorkOrderDetails));
