import { AbstractStore } from 'stores/abstract';

class CacheStore extends AbstractStore {
  setData = (key, val) => {
    this.data.set(key, val);
  };

  getData = (key) => {
    return this.data.get(key);
  };

  deleteKey = (key) => {
    this.data.delete(key);
  };

  reset = () => this.data.clear();
}

export const PitstopTableCacheStore = new CacheStore();
export const IssuesTableCacheStore = new CacheStore();
export const CarListTableCacheStore = new CacheStore();
export const ReportCacheStore = new CacheStore();
export const HighPriorityCarsCacheStore = new CacheStore();
export const IntegrationCacheStore = new CacheStore();
export const ConnectivityStatusCache = new CacheStore();
