import { observable, decorate, action, transaction } from 'mobx';
import _ from 'lodash';
import moment from 'moment-timezone';

import AbstractStore from './abstract/AbstractStore';
import AsyncData from './abstract/AsyncData';
import AppStore from './App';
import ShopStore from './ShopStore';
import IssueObject from './Classes/IssueObject';
import { download, webServiceProvider } from 'shared';
import { errors } from 'helpers';

import {
  CurrentUserStore,
} from 'stores';

import { PitstopTableCacheStore, IssuesTableCacheStore } from 'stores/CacheStore';

class IssueStoreC extends AbstractStore {
  getIssueController = new AbortController();
  getIssueSignal = this.getIssueController.signal;
  getIssueTroubleshootingController = new AbortController();
  getRelevantServicesController = new AbortController();

  summaryIssues = new AsyncData();

  currentPMFeedbacks = {
    title: '',
    currentStep: 1,
    pmsToReceiveFeedback: [],
    isModalVisible: false
  };

  currentPMFeedbacksInputs = [];

  resetCurrentPMFeedbacksInputs = () => {
    this.currentPMFeedbacksInputs = [];
  }

  updateCurrentPMFeedbacksInputs = (index, field, value) => {
    if (!this.currentPMFeedbacksInputs[index]) {
      this.currentPMFeedbacksInputs[index] = {};
    }
    this.currentPMFeedbacksInputs[index] = {
      ...this.currentPMFeedbacksInputs[index],
      [field]: value
    };
  }

  resetPMFeedbackModalData = () => {
    this.currentPMFeedbacks = {
      title: '',
      currentStep: 1,
      pmsToReceiveFeedback: [],
      isModalVisible: false
    };
  };

  goToNextStepOnFeedbackModal = () => {
    this.currentPMFeedbacks.currentStep++;
  };

  goToPreviousStepOnFeedbackModal = () => {
    this.currentPMFeedbacks.currentStep--;
  };

  setPMFeedbackModalData = (data) => {
    this.currentPMFeedbacks = data;
  };

  getSummaryForShopId = async (shopId) => {
    shopId = shopId || ShopStore.currentShop.id;
    try {
      const { data } = await webServiceProvider.get(
        `v1/shops/${shopId}/issues/summary`
      );
      transaction(() => {
        for (let i = 0; i < data.length; i++) {
          this.data.set(Number(data[i].id), new IssueObject(data[i]));
          this.summaryIssues.data.push(Number(data[i].id));
        }
        this.summaryIssues.loaded = true;
      });
    } catch (e) {
      this.summaryIssues.setError('Unable to load issues summary for shop');
    }
  };

  returnIssueById = async (id, shopId) => {
    if (!shopId) {
      shopId = ShopStore.currentShop.id;
    }
    try {
      const { data } = await webServiceProvider.getMany(`v1/issues/${id}`, {
        includeHistory: true,
        shopId: shopId
      });

      transaction(() => {
        this.data.set(Number(data.id), new IssueObject(data));
        this.data.get(Number(data.id)).loaded = true;
      });
      return new IssueObject(data);
    } catch (e) {
      console.error(e);
      // AppStore.addError(`Unable to load issue with id ${id}`);
    }
  };

  getIssueById = async (id, shopId) => {
    if (!shopId) {
      shopId = ShopStore.currentShop.id;
    }
    try {
      const { data } = await webServiceProvider.getMany(`v1/issues/${id}`, {
        includeHistory: true,
        shopId: shopId
      });

      transaction(() => {
        this.data.set(Number(data.id), new IssueObject(data));
        this.data.get(Number(data.id)).loaded = true;
      });
    } catch (e) {
      console.error(e);
      // AppStore.addError(`Unable to load issue with id ${id}`);
    }
  };
  /**
 *
 * @param {*} id - The issueId
 * @param {*} code - The issue code
 * @param {*} shopId - The shop ID
 * @returns
 */
  getRelevantServices = async (id, code, shopId) => {
    try {
      this.getRelevantServicesController.abort();
      this.getRelevantServicesController = new AbortController();
      const { data } = await webServiceProvider.getMany(
        `v1/issues/${id}/relevant-service-records?code=${code}&shopId=${shopId}`, {
        code,
        shopId
      },
        this.getRelevantServicesController.signal
      );
      transaction(() => {
        this.data.set(String(id)+'relevant-services', data);
      });
      return data;
    } catch (e) {
      console.error(e);
      // AppStore.addError(`Unable to load relevant service entries for issue with id ${id}`);
    }
  };
  /**
   *
   * @param {*} id - The issueId
   * @param {*} code - The issue code
   * @returns
   */
  getIssueTroubleshooting = async (id, code, fmiCode) => {
    try {
      this.getIssueTroubleshootingController.abort();
      this.getIssueTroubleshootingController = new AbortController();
      const { data } = await webServiceProvider.getMany(
        `v1/issues/${id}/troubleshooting`, {
          code,
          fmiCode
        },
        this.getIssueTroubleshootingController.signal
      );
      transaction(() => {
        this.data.set(Number(String(id)+String(code)+String(fmiCode)), data);
      });

      return data;
    } catch (e) {
      console.error(e);
      // AppStore.addError(`Unable to load issue troubleshooting with id ${id}`);
    }
  };

  addIssueToStore = (id, issue, isReformated) => {
    if (!this.data.has(Number(id)) || isReformated) {
      this.data.set(
        Number(id),
        issue instanceof IssueObject ? issue : new IssueObject(issue)
      );
    }
  };

  getIssuesForShopId = async (
    params,
    store,
    shopId = ShopStore.currentShop.id,
    startDate,
    endDate
  ) => {
    return await this.getIssuesForTable(
      params,
      store,
      `v1/shops/${shopId}/issues`,
      startDate,
      endDate
    );
  };

  downloadIssuesCsv = async (params, shopId) => {
    try {
      const response = await webServiceProvider.fetchCSV(`v1/shops/${shopId}/issues/download`, params);
      download(response, 'issues.csv', 'text/csv');
    } catch (e) {
      throw Error('Unable to download issues!');
    }
  }

  getIssuesForCarId = async (params, store, carId, reformatFunc = false, sendAllData = false) => {
    return await this.getIssuesForTable(
      params,
      store,
      `v1/cars/${carId}/issues`,
      reformatFunc,
      undefined,
      sendAllData
    );
  };

  getPMsAndAssetsByShop = async (params, shopId) => {
    try {
      const { data } = await webServiceProvider.getMany(
        `v1/shops/${shopId}/issues/pms`,
        params
      );
      return data;
    } catch (e) {
      AppStore.addError('Unable to load PMs and assets');
    }
  }

  updatePMService = async (shopId, serviceId, serviceCustomizedId, payload = {}) => {
    try {
      const { data } = await webServiceProvider.patch(
        `v1/shops/${shopId}/issues/pms?serviceId=${serviceId}&serviceCustomizedId=${serviceCustomizedId}`,
        payload
      );
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  getIssuesForTable = async (
    { offset, limit, filter, sort, includeHistory = false, shopId } = {},
    tableStore,
    URL,
    startDate,
    endDate,
    sendAllData = false
  ) => {
    try {
      if (!shopId) {
        shopId = ShopStore.currentShop.id;
      }
      tableStore.pending = true;
      tableStore.loaded = false;

      offset = offset || tableStore.offset;
      limit = limit || tableStore.limit;
      filter = filter || tableStore.filter;
      sort = sort || tableStore.sort;

      let params = {
        shopId,
        offset,
        limit,
        includeHistory,
        ...filter,
        startDate,
        endDate,
      };

      if (params.startDate && moment(params.startDate).isValid()) {
        params.startDate = moment(params.startDate)
          .tz(CurrentUserStore.user.settings.timezone || 'America/Toronto')
          .startOf('day')
          .format();
      }

      if (params.endDate && moment(params.endDate).isValid()) {
        params.endDate = moment(params.endDate)
          .tz(CurrentUserStore.user.settings.timezone || 'America/Toronto')
          .endOf('day')
          .format();
      }

      if (sort) {
        params.sort = sort;
      }

      let paramKeys = _.keys(params);

      let _params = {};

      _.forEach(paramKeys, (key) => {
        if (!_.isNil(params[key]) && params[key] !== '') {
          _params[key] = params[key];
        }
      });
      const { data, meta } = await webServiceProvider.getMany(URL, _params);

      _.forEach(data, (item) => {
        if (_.indexOf(tableStore.data, item.id) === -1) {
          this.data.set(item.id, new IssueObject(item));
          if (sendAllData) {
            tableStore.data.push(new IssueObject(item));
          } else {
            tableStore.data.push(item.id);
          }
        }
      });

      tableStore.setDataMetadata(
        meta.total,
        meta.pagination.offset,
        meta.pagination.limit,
        sort,
        filter
      );
    } catch (err) {
      errors.response(err, 'Unable to load services!');
    }
  };

  clearCacheForIssuesCarFromOtherTabs = () => {
    PitstopTableCacheStore.reset();
    IssuesTableCacheStore.reset();
  }

  getCarIssuesList = async (
    { offset, limit, filter, sort, includeHistory = false } = {},
    carId,
  ) => {
    try {
      const URL = `v1/cars/${carId}/issues`;

      let params = {
        offset,
        limit,
        includeHistory,
        ...filter,
      };

      if (sort) {
        params.sort = sort;
      }

      let paramKeys = _.keys(params);

      let _params = {};

      _.forEach(paramKeys, (key) => {
        if (!_.isNil(params[key]) && params[key] !== '') {
          _params[key] = params[key];
        }
      });

      return webServiceProvider.getMany(URL, _params).then(r => r.data);
    } catch (err) {
      errors.response(err, 'Unable to load services!');
    }
  };

  reset = () => {
    this.data.clear();
    this.summaryIssues.reset();
  };
}

decorate(IssueStoreC, {
  currentPMFeedbacks: observable,
  currentPMFeedbacksInputs: observable,
  getPrice: action,
  getSummaryForShop: action,
  getIssuesForShopId: action,
  getIssuesForTable: action,
  addIssueToStore: action,
  setPMFeedbackModalData: action,
  resetPMFeedbackModalData: action,
  reset: action,
});

const IssueStore = new IssueStoreC();

export default IssueStore;
