import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Divider, Popconfirm } from 'antd';

const PitstopSimpleForm = ({ children, onSubmit, onDelete, submitLabel, deleteLabel, submitButtonDisabled }) => {
  return (
    <Form
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit();
      }}
    >
      {children}
      <Divider />
      <Form.Item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {
          deleteLabel && (
            <Popconfirm placement="top" title={'Are you sure?'} onConfirm={onDelete} okText="Yes" cancelText="No">
              <Button
                type="danger"
                ghost
                htmlType="button"
                style={{
                  marginRight: 8,
                }}
              >
                {deleteLabel}
              </Button>
            </Popconfirm>
          )
        }
        <Button type="primary" htmlType="submit" disabled={submitButtonDisabled}>
          {submitLabel}
        </Button>
      </Form.Item>
    </Form>
  );
};

PitstopSimpleForm.propTypes = {
  submitLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

PitstopSimpleForm.defaultProps = {
  submitLabel: 'Submit',
  deleteLabel: '',
  submitButtonDisabled: false,
  onSubmit: () => { },
  onDelete: () => { },
};

export default PitstopSimpleForm;
