import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Button } from 'antd';
import WorkOrderLineItemDetails from './WorkOrderLineItemDetails';
import FormLegendCentered from 'components/Forms/FormLegendCentered';

// const { Text } = Typography;
// const { Panel } = Collapse;

// const AddServiceWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   padding: 20px;
// `;

class WorkOrderLineItems extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    lineItems: PropTypes.array,
    onAddLineItem: PropTypes.func,
    onRemoveLineItem: PropTypes.func,
    onChangeLineItemField: PropTypes.func,
    shopUsers: PropTypes.array,
    shopParts: PropTypes.array,
    currentCarIssues: PropTypes.array,
    onAddNewPart: PropTypes.func.isRequired,
    onUpdateLaborItem: PropTypes.func.isRequired,
    onUpdatePartItem: PropTypes.func.isRequired,
    onUpdateServiceItem: PropTypes.func.isRequired,
    onChangeCollapseItems: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    onSelectSystemCode: PropTypes.func,
    onSelectAsmCode: PropTypes.func,
  };

  workOrderLinesDetailsRefs = {};

  get currentLineItems() {
    return this.props.lineItems;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  updateLineItemHeader = (e, directValue = '', lineItem) => {
    let value;
    if (e) {
      value = e.target.value;
    } else {
      value = directValue;
    }
    if (!value) {
      lineItem.header = `New Work Order Line ${lineItem.key}`;
    } else {
      lineItem.header = value;
    }
    this.forceUpdate();
  };

  onChangeLineItemField = (lineKey, newVal, fieldName) => {
    if (this.props.onChangeLineItemField) {
      this.props.onChangeLineItemField(lineKey, newVal, fieldName);
    }
  };

  render() {
    // const getActionIcons = (key) => {
    //   return (
    //     <Icon
    //       type="delete"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         this.props.onRemoveLineItem(key);
    //       }}
    //     />
    //   );
    // };

    // const panels = this.currentLineItems.map((lineItem) => {
    //   return (
    //     <Panel
    //       header={<FormLegend>{lineItem.header}</FormLegend>}
    //       key={lineItem.key}
    //       extra={getActionIcons(lineItem.key)}
    //     >
    //       <WorkOrderLineItemDetails
    //         childRef={(ref) =>
    //           (this.workOrderLinesDetailsRefs[lineItem.key] = ref)
    //         }
    //         lineItem={lineItem}
    //         shopUsers={this.props.shopUsers}
    //         currentCarIssues={this.props.currentCarIssues}
    //         shopParts={this.props.shopParts}
    //         onChangeServiceTask={(e, value) =>
    //           this.updateLineItemHeader(e, value, lineItem)
    //         }
    //         onChangeField={(newVal, fieldName) =>
    //           this.onChangeLineItemField(lineItem.key, newVal, fieldName)
    //         }
    //         onAddNewPart={(name) =>
    //           this.props.onAddNewPart(name || '', lineItem.key)
    //         }
    //         onUpdateLaborItem={(labourKey, newVal) =>
    //           this.props.onUpdateLaborItem(lineItem.key, labourKey, newVal)
    //         }
    //         onUpdatePartItem={(partKey, newVal, changedField) =>
    //           this.props.onUpdatePartItem(
    //             lineItem.key,
    //             partKey,
    //             newVal,
    //             changedField
    //           )
    //         }
    //         onUpdateServiceItem={(serviceKey, newVal) =>
    //           this.props.onUpdateServiceItem(lineItem.key, serviceKey, newVal)
    //         }
    //         isDisabled={this.props.isDisabled}
    //       />
    //     </Panel>
    //   );
    // });

    return (
      <Row gutter={[8, 8]}>
        {/* <Col span={24}>
          <Text strong>Line Items</Text>
        </Col> */}
        {/* <Col span={24}>
          <Collapse
            activeKey={this.currentLineItems
              .filter((li) => li.expanded)
              .map((li) => li.key)}
            onChange={this.props.onChangeCollapseItems}
          >
            {panels}
          </Collapse>
        </Col> */}
        <Col span={24}>
          {this.currentLineItems.map((lineItem) => {
            return (
              <div key={lineItem.key}>
                <WorkOrderLineItemDetails
                  childRef={(ref) =>
                    (this.workOrderLinesDetailsRefs[lineItem.key] = ref)
                  }
                  lineItem={lineItem}
                  shopUsers={this.props.shopUsers}
                  currentCarIssues={this.props.currentCarIssues}
                  shopParts={this.props.shopParts}
                  onChangeServiceTask={(e, value) =>
                    this.updateLineItemHeader(e, value, lineItem)
                  }
                  onChangeField={(newVal, fieldName) =>
                    this.onChangeLineItemField(lineItem.key, newVal, fieldName)
                  }
                  onAddNewPart={(name) =>
                    this.props.onAddNewPart(name || '', lineItem.key)
                  }
                  onUpdateLaborItem={(labourKey, newVal) =>
                    this.props.onUpdateLaborItem(
                      lineItem.key,
                      labourKey,
                      newVal
                    )
                  }
                  onUpdatePartItem={(partKey, newVal, changedField) =>
                    this.props.onUpdatePartItem(
                      lineItem.key,
                      partKey,
                      newVal,
                      changedField
                    )
                  }
                  onUpdateServiceItem={(serviceKey, newVal) =>
                    this.props.onUpdateServiceItem(
                      lineItem.key,
                      serviceKey,
                      newVal
                    )
                  }
                  onRemoveLineItem={() =>
                    this.props.onRemoveLineItem(lineItem.key)
                  }
                  onSelectSystemCode={(systemCode) => {
                    if (this.props.onSelectSystemCode) {
                      this.props.onSelectSystemCode(lineItem.key, systemCode);
                    }
                  }}
                  onSelectAsmCode={(asmCode) => {
                    if (this.props.onSelectAsmCode) {
                      this.props.onSelectAsmCode(lineItem.key, asmCode);
                    }
                  }}
                  isDisabled={this.props.isDisabled}
                />
              </div>
            );
          })}
        </Col>
        <Col span={24}>
          <FormLegendCentered>
            <Button
              type="primary"
              ghost
              onClick={this.props.onAddLineItem}
              icon="plus-circle"
            >
              Add New Service Task
            </Button>
          </FormLegendCentered>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItems, {
  currentLineItems: computed,
});

export default observer(WorkOrderLineItems);
