import { computed, decorate, observable, action } from 'mobx';
import _ from 'lodash';
import moment from 'moment-timezone';

import { webServiceProvider } from 'shared';

import { AppStore, IssueStore, CurrentUserStore, UserStore } from 'stores';
import { getPriorityCategory } from 'services/Issue';

export const getPriorityTagColor = (priority) => {
  if (priority === undefined || priority === null) {
    return 'gray';
  } else {
    switch (priority) {
      case 2:
      case 3:
      case 'Major':
        return '#e28931';
      case 4:
      case 5:
      case 'Critical':
        return '#f92d2e';
      case -1:
        return '#c4c3c2';
      default:
        return '#dec431';
    }
  }
};

export const getPriorityTagDescription = (priority) => {
  if (priority === undefined || priority === null) {
    return 'gray';
  } else {
    switch (priority) {
      case 2:
      case 3:
        return 'Major';
      case 4:
      case 5:
        return 'Critical';
      case -1:
        return 'Not Valid';
      default:
        return 'Minor';
    }
  }
};

class IssueObject {
  loaded = false;
  constructor(data) {
    Object.assign(this, data);
  }

  get priorityCategory() {
    return getPriorityCategory(this.priority);
  }

  get priorityTagColor() {
    return getPriorityTagColor(this.priority);
  }

  get updatedBy() {
    if (
      _.isNil(this.meta) ||
      _.isNil(UserStore.data.get(this.meta.updateBy)) ||
      UserStore.data.get(this.meta.updateBy).role === 'admin'
    )
      return 'Pitstop';

    return UserStore.data.get(this.meta.updateBy).name;
  }

  get updatedOn() {
    if (_.isNil(this.meta) || _.isNil(this.meta.updateDate)) return null;

    return moment
      .tz(this.meta.updateDate, CurrentUserStore.user.settings.timezone)
      .format('lll');
  }

  get updatedType() {
    if (_.isNil(this.meta) || _.isNil(this.meta.updateType)) return null;

    return this.meta.updateType;
  }

  get createdBy() {
    if (_.isNil(this.meta) || _.isNil(UserStore.data.get(this.meta.createBy)))
      return null;

    return UserStore.data.get(this.meta.createBy).name;
  }

  getItem = () => {
    let { item, source, explanation } = this;
    // If the API server sends a string here when it should be sending an object, this will parse it into an object
    // So that titles are still displayed correctly
    if (
      typeof explanation === 'string' &&
      (source === 'algorithm' || source === 'algorithm_history')
    ) {
      try {
        explanation = JSON.parse(explanation);
        //If this throws an exception, it doesn't really matter since it's likely due to the explanation not being in a JSON format
      } catch {}
    }

    if (!_.isNil(explanation) && !_.isNil(explanation.item)) {
      return explanation.item;
    }

    return item;
  };

  getAction = () => {
    let { action, source, explanation } = this;
    // If the API server sends a string here when it should be sending an object, this will parse it into an object
    // So that titles are still displayed correctly
    if (
      typeof explanation === 'string' &&
      (source === 'algorithm' || source === 'algorithm_history')
    ) {
      try {
        explanation = JSON.parse(explanation);
        //If this throws an exception, it doesn't really matter since it's likely due to the explanation not being in a JSON format
      } catch {}
    }

    if (!_.isNil(explanation) && !_.isNil(explanation.action)) {
      return explanation.action;
    }

    return action;
  };

  getName = () => {
    let { source, description } = this;

    let issueNames = [];

    this.getAction() && issueNames.push(this.getAction());

    this.getItem() && issueNames.push(this.getItem());

    if (
      source === 'dtc' &&
      description &&
      description !== 'No description available'
    ) {
      if (description && String(description).includes('Unknown Diagnostic')) {
        issueNames.push('Proprietary Code (Contact your service centre)');
      } else {
        issueNames.push(description);
      }
    }

    if (_.isEmpty(issueNames) && this.getDescription()) {
      issueNames.push(this.getDescription());
    }

    if (
      issueNames.some(
        (name) => name.toString() === 'null' || name.toString() === 'N/A'
      )
    ) {
      return this.getDescription(); // if some issue names are null or N/A, return description
    }

    return issueNames.join(' - ');
  };

  getDescription = () => {
    let { description } = this;

    if (description && String(description).includes('Unknown'))
      return 'This is a proprietary code that cannot be decoded by the telematic device. Please contact your service centre to learn more about the code';

    return description
      ? String(description).trim()
      : 'No description available';
  };

  getSuggestedActions = () => {
    let { source, suggestedAction } = this;

    if (source === 'algorithm') {
      return suggestedAction
        ? suggestedAction
        : 'No available suggested actions';
    }

    return suggestedAction || 'No available suggested actions';
  };

  getMilStatus = () => {
    let { milStatus } = this;

    return milStatus || 'No available mil status';
  };

  getEngineDeratePossible = () => {
    let { source, engineDeratePossible } = this;

    if (source === 'algorithm') {
      return !_.isNil(engineDeratePossible)
        ? engineDeratePossible
        : 'No available engine derate possible';
    }

    return !_.isNil(engineDeratePossible)
      ? engineDeratePossible
      : 'No available engine derate possible';
  };

  getFinishRoute = () => {
    let { source, finishRoute } = this;
    if (source === 'algorithm') {
      return !_.isNil(finishRoute) ? finishRoute : 'No available finish route';
    }

    return !_.isNil(finishRoute) ? finishRoute : 'No available finish route';
  };

  getExplanation = () => {
    let { source, explanation, gptDescription, report } = this;

    if (source === 'algorithm') {
      return report ? report.explanation : 'No available explanation';
    }

    return gptDescription || explanation || 'No available explanation';
  };

  getFailureModeIndicator = () => {
    let { fmi } = this;

    let result;
    if (fmi?.fmiDescription && !_.isNil(fmi?.fmiCode)) {
      result = `${fmi.fmiCode} - ${fmi.fmiDescription}`;
    }

    return result || 'No available failure mode indicator (FMI)';
  };

  getSourceAddress = () => {
    let { sourceAddress } = this;

    let result;
    if (
      sourceAddress?.sourceAddressDescription &&
      sourceAddress?.sourceAddressCode
    ) {
      result = `${sourceAddress.sourceAddressCode} - ${sourceAddress.sourceAddressDescription}`;
    }

    return result || 'No available source address (SA)';
  };

  getCauses = () => {
    let { causes } = this;

    return causes || 'No available cause';
  };

  refreshIssuesStore = () => {
    IssueStore.clearCacheForIssuesCarFromOtherTabs();
  };

  setAsComplete = async () => {
    try {
      await webServiceProvider.put(`v1/issues/${this.id}`, {
        status: 'done',
      });
      this.status = 'done';
      AppStore.addSuccess('Updated service status.');
      this.refreshIssuesStore();
    } catch (e) {
      AppStore.addError('Unable to mark service as completed.');
    }
  };

  update = async (data, showFeedbackMessage = true) => {
    try {
      await webServiceProvider.put(
        `v1/issues/${this.id}?triggerDriverNotification=true`,
        {
          ...data,
          updateBy: CurrentUserStore.user.id,
        }
      );
      Object.keys(data).forEach((key) => (this[key] = data[key]));
      this.refreshIssuesStore();
      if (showFeedbackMessage) {
        AppStore.addSuccess('Updated issue.');
      }
    } catch (e) {
      if (showFeedbackMessage) {
        AppStore.addError('Unable to update issue.');
      }
    }
  };

  setAsNew = async (showFeedbackMessage = true) => {
    try {
      await webServiceProvider.put(`v1/issues/${this.id}`, {
        status: 'new',
        updateBy: CurrentUserStore.user.id,
      });
      this.status = 'new';
      if (showFeedbackMessage) {
        AppStore.addSuccess('Updated service status.');
      }
      this.refreshIssuesStore();
    } catch (e) {
      if (showFeedbackMessage) {
        AppStore.addError('Unable to mark service as completed.');
      }
    }
  };

  delete = async () => {
    try {
      await webServiceProvider.put(`v1/issues/${this.id}`, {
        status: 'deleted',
      });
      this.status = 'deleted';
      IssueStore.data.delete(this.id);
      AppStore.addSuccess('Deleted issue.');
      this.refreshIssuesStore();
    } catch (e) {
      AppStore.addError('Unable to delete issue.');
    }
  };
}

export default decorate(IssueObject, {
  priorityCategory: computed,
  priorityTagColor: computed,
  setAsComplete: action,
  delete: action,
  getName: action,
  getAction: action,
  getItem: action,
  getDescription: action,
  getExplanation: action,
  getEngineDeratePossible: action,
  getSuggestedActions: action,
  getFinishRoute: action,
  getFailureModeIndicator: action,
  getSourceAddress: action,
  getCauses: action,
  status: observable,
  loaded: observable,
  priority: observable,
  description: observable,
  updatedBy: computed,
  createdBy: computed,
});
