import React from 'react';
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Logger } from 'stores/Classes';

const DarkHeaderModal = styled(Modal)`
  & {
    width: 20% !important;

    @media (max-width: 1615px) {
      width: 30% !important;
    }

    @media (max-width: 1366px) {
      width: 40% !important;
    }

    @media (max-width: 1024px) {
      width: 50% !important;
    }

    @media (max-width: 768px) {
      width: 70% !important;
    }
  }

  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #ffffff;
      }
    }

    .ant-modal-header {
      background-color: #05103a;
      border-bottom: 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-body {
      padding: 12px 18px;
    }
  }

  .footer-row {
    margin-top: 21px !important;

    .ant-col {
      display: flex;
      justify-content: flex-end;
    }
  }

  .save-btn {
    margin-left: 8px;
  }
`;

const ExpiringSoonStatusForm = styled(Form)`
  padding: 10px;

  .ant-col-12 {
    .ant-form-item {
      margin: 0;
    }
  }

  .ant-form-item-label {
    line-height: 10px;
  }
`;

const ExpiringSoonStatusFormWrapper = Form.create({
  name: 'expiring_soon_status',
})(
  // eslint-disable-next-line
  class extends React.Component {
    static propTypes = {
      form: PropTypes.object,
      saving: PropTypes.bool,
      onSubmit: PropTypes.func,
      onCancel: PropTypes.func,
      initialData: PropTypes.object,
      assets: PropTypes.array,
      vendors: PropTypes.array,
    };

    warrantyComponentsRef = null;
    documentsCardRef = null;

    onSubmit = async () => {
      try {
        // for now: check if promise does not resolve in 500 milliseconds, isValidForm should be true
        // for some unknown reason, the promise does not resolve when the form is valid
        const isValidForm = await new Promise((resolve) => {
          this.props.form.validateFields((err) => {
            if (err) {
              return resolve(false);
            }
            resolve(true);
          });
        });

        if (!isValidForm) {
          notification.error({
            message: 'Error',
            description: 'Please fill in all required fields',
          });
          return;
        }
        const values = this.props.form.getFieldsValue();

        this.props.onSubmit({
          ...values,
        });
      } catch (error) {
        Logger.error('Error saving preferences', error);
        notification.error({
          message: 'Error',
          description: 'An error occurred while saving your settings',
        });
      }
    };

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <ExpiringSoonStatusForm
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.onSubmit();
          }}
        >
          <Row gutter={[8, 8]}>
            <Col span={10}>
              <Form.Item label="Days">
                {getFieldDecorator('warrantyStatusChangeDays', {
                  initialValue: this.props.initialData
                    ?.warrantyStatusChangeDays,
                  rules: [
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="30"
                    style={{ width: '100%' }}
                    allowClear
                  />
                )}
              </Form.Item>
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '28px',
              }}
            >
              <Typography.Text style={{ lineHeight: '32px' }}>
                OR
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Form.Item label="Mileage">
                {getFieldDecorator('warrantyStatusChangeMiles', {
                  initialValue: this.props.initialData
                    ?.warrantyStatusChangeMiles,
                  rules: [
                    {
                      required: true,
                      message: 'Please fill this field',
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="1000"
                    style={{ width: '100%' }}
                    allowClear
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Button
                type="primary"
                style={{ float: 'right', marginLeft: 8 }}
                htmlType="submit"
                loading={this.props.saving}
              >
                Save
              </Button>
              <Button
                style={{ float: 'right' }}
                htmlType="button"
                onClick={() => {
                  this.props.onCancel();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ExpiringSoonStatusForm>
      );
    }
  }
);

function StatusSettingsModal({
  visible = false,
  saving = false,
  initialData = {},
  onOk = () => {},
  onCancel = () => {},
}) {
  const handleFormSubmit = (data) => {
    onOk(data);
  };

  return (
    <DarkHeaderModal
      onCancel={onCancel}
      title="Status Settings"
      visible={visible}
      footer={null}
    >
      <Typography.Text
        style={{
          display: 'block',
          marginBottom: 16,
          fontWeight: 'bold',
          padding: '9px 9px 1px',
        }}
      >
        Expiring Soon status shows when time or mileage is less than:
      </Typography.Text>
      <ExpiringSoonStatusFormWrapper
        initialData={initialData}
        saving={saving}
        onSubmit={(data) => handleFormSubmit(data)}
        onCancel={onCancel}
      />
    </DarkHeaderModal>
  );
}

StatusSettingsModal.propTypes = {
  visible: PropTypes.bool,
  saving: PropTypes.bool,
  initialData: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default StatusSettingsModal;
