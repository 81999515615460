import { decorate, observable } from 'mobx';

export const WorkOrderStatusEnum = {
  pending: 'Pending',
  open: 'Open',
  in_progress: 'In Progress',
  blocked_for_parts: 'Blocked',
  completed: 'Completed',
  waiting_for_approval: 'Waiting For Invoice',
  resolved: 'Resolved',
};

export const WorkOrderTypeOfServiceEnum = {
  driver_identified: 'Driver Identified',
  breakdown: 'Breakdown',
  preventive_maintenance: 'PM',
  repair: 'Accident',
  inspection: 'Inspection',
};

export const WorkOrderRepairPriorityEnum = {
  minor: 'Minor',
  major: 'Major',
  critical: 'Critical',
};

const workOrderKeys = Object.keys(WorkOrderStatusEnum);
export const WorkOrderFilterOptions = workOrderKeys.map((key) => {
  return {
    value: key,
    text: WorkOrderStatusEnum[key],
  };
});

class WorkOrderObject {
  id;
  workOrderId;
  id_car;
  id_shop;
  type;
  status;
  type_of_service;
  odometer_in;
  odometer_out;
  priority;
  invoice_number;
  description;
  created_at;
  updated_at;
  started_at;
  done_at;

  constructor(jsonObject = {}) {
    const { ...assignObject } = jsonObject;
    if (Object.keys(assignObject).length === 0) {
      return;
    }
    this.loaded = true;
    Object.assign(this, assignObject);
  }
}

export default decorate(WorkOrderObject, {
  id_car: observable,
  id_shop: observable,
  status: observable,
  priority: observable,
  type: observable,
  invoice_number: observable,
  description: observable,
  started_at: observable,
  id: observable,
  created_at: observable,
  updated_at: observable,
  done_at: observable,
});
