import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Spin, Tabs } from 'antd';
import { PropTypes } from 'prop-types';
import { CarObject } from 'stores/Classes';
import { CarStore, ShopStore } from 'stores';
import { Sticky, StickyContainer } from 'react-sticky';
import CarProfileHeader from 'containers/CarProfile/CarProfileHeader';
import { computed, decorate } from 'mobx';
import styled from 'styled-components';
import TrailerWorkOrders from './TrailerWorkOrders';
import CarDocumentSections from 'containers/CarProfile/CarDocumentSections';

const { TabPane } = Tabs;

const VehicleHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  > p {
    font-size: 14px;
    margin: 0 20px;
  }
  > p > strong {
    font-size: 20px;
  }
`;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky topOffset={-64}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        style={{
          ...style,
          zIndex: 4,
          background: '#fff',
          top: '64px',
        }}
      />
    )}
  </Sticky>
);

class TrailerProfilePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    car: PropTypes.instanceOf(CarObject),
  };

  state = {
    activeKey: this.activeKey,
  };

  get car() {
    return CarStore.data.get(Number(this.props.match.params.id));
  }

  get activeKey() {
    if (this.props.match.params.selector) {
      return this.props.match.params.selector.toNormalString().capitalizeFirstLetter();
    }

    return 'General';
  }

  get shopId() {
    return ShopStore.currentShop.id === -1 ? this.car.shopId : ShopStore.currentShop.id;
  }

  async componentDidMount() {
    if (!CarStore.data.has(Number(this.props.match.params.id))) {
      await CarStore.getCarById(this.props.match.params.id);
    }
  }

  getTabs = (car) => {
    let leanDashboardPanels = [
      <TabPane tab="General" key="General">
        <CarProfileHeader car={car} shopId={this.shopId} />
      </TabPane>,
      <TabPane tab="Work Orders" key="Work Orders">
        <TrailerWorkOrders car={car} shopId={this.shopId} />
      </TabPane>,
      <TabPane tab="Documents" key="Documents">
        <CarDocumentSections car={car} />
      </TabPane>,
    ];

    let tabs = leanDashboardPanels;

    return tabs.map((el) => {
      if (el.key === this.state.activeKey) {
        return el;
      }
      return <TabPane tab={el.key} key={el.key} />;
    });
  };

  changeTab = (activeKey) => {
    this.props.history.replace(`/trailer/${this.props.match.params.id}/${activeKey.toReadableUrl()}`);
    this.setState({ activeKey });
  };

  carNameFrag(car) {
    const { carName } = car;
    return carName && <p>{`Unit Id: ${carName}`}</p>;
  }

  render() {
    if (!this.car || !this.car.loaded) {
      return <Spin size="large" tip="Loading..." />;
    }

    return (
      <div
        style={{
          maxHeight: '100%',
          minWidth: '77%',
          marginTop: 10,
        }}
      >
        <VehicleHeaderContainer>
          <p style={{ fontSize: '20px' }}>
            <strong>{`${this.car.mmy}`}</strong>
          </p>
          {this.carNameFrag(this.car)}
        </VehicleHeaderContainer>
        <StickyContainer>
          <Tabs
            defaultActiveKey={this.state.activeKey}
            size="large"
            onChange={(activeKey) => this.changeTab(activeKey)}
            renderTabBar={renderTabBar}
          >
            {this.getTabs(this.car)}
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}

decorate(TrailerProfilePage, {
  shopId: computed,
});

export default observer(TrailerProfilePage);
