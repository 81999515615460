import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  .legend-line {
    flex-grow: 1;
    height: 1px;
    background-color: #0038b3;

    &.first {
      flex-grow: 0.03;
    }
  }

  .legend-text {
    color: #333;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const FormLegend = ({ children }) => (
  <Legend>
    <div className="legend-line first"></div>
    <div className="legend-text">{children}</div>
    <div className="legend-line"></div>
  </Legend>
);

FormLegend.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormLegend;
