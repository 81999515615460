import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  DatePicker,
  Form,
  Input,
  Radio,
  AutoComplete,
  InputNumber,
  Switch,
  Row,
  Col,
  Icon,
  Button,
} from 'antd';

import { partList } from './data/general';

import { webServiceProvider } from 'shared';
import { getMileageOrKm } from 'helpers/unitCalculations';

import { AppStore, ShopStore, CurrentUserStore } from 'stores';
import { SelectCars } from 'containers/Users';
import RoutinesTableList from 'containers/Maintenance/RoutinesTableList';

const RoutineContainer = styled.div`
  & > div {
    margin: 0px;
  }
  margin-bottom: 24px;

  .routineBased {
    font-weight: 500;
  }

  .ant-row {
    margin: 0px;
  }
`;

class RoutineServiceAntdForm extends Component {
  state = {
    selectedCarIds: [],
    action: null,
    partName: null,
    description: null,
    priority: null,
    isTimeAlertOn: false,
    isTimeIntervalAlertOn: false,
    isMileageAlertOn: false,
    isMileageIntervalAlertOn: false,
    isEngineHoursAlertOn: false,
    isEngineHoursIntervalAlertOn: false,
    timeAlert: {
      started: moment().format('YYYY-MM-DD'),
      interval: 1,
      threshold: 1,
    },
    mileageAlert: { started: 1, interval: 1, threshold: 1 },
    engineHoursAlert: { started: 1, interval: 1, threshold: 1 },
  };

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState({
      selectedCarIds: [],
    });
  });

  componentWillUnmount() {
    this.disposer();
  }

  onSelect = (ids) => {
    this.setState({
      selectedCarIds: ids,
    });
  };

  resetFormFields = () => this.props.form.resetFields();

  onSubmit = () => {
    const {
      isTimeAlertOn,
      isEngineHoursAlertOn,
      isMileageAlertOn,
      selectedCarIds,
    } = this.state;

    this.props.form.validateFields(async (err, values) => {
      try {
        if (ShopStore.currentShop.id === -1) {
          throw Error('Please select another shop!');
        }

        if (err) {
          throw Error('Some fields in the form are required!');
        }

        if (_.isEmpty(selectedCarIds)) {
          throw Error('Please select vehicles!');
        }

        if (!isTimeAlertOn && !isMileageAlertOn && !isEngineHoursAlertOn) {
          throw Error('Please Select At Least One Alert!');
        }

        AppStore.addLoading('Service is being created...');

        await this.onAddService();

        AppStore.addSuccess('Service has been created!');
      } catch (err) {
        AppStore.addError(`Error in adding routine service: ${err.message}`);
      } finally {
        AppStore.removeLoading('Service is being created...');
      }
    });
  };

  onAddService = async () => {
    let {
      selectedCarIds,
      action,
      partName,
      description,
      priority,
      timeAlert,
      mileageAlert,
      engineHoursAlert,
      isTimeAlertOn,
      isTimeIntervalAlertOn,
      isMileageAlertOn,
      isMileageIntervalAlertOn,
      isEngineHoursAlertOn,
      isEngineHoursIntervalAlertOn,
    } = this.state;

    let data = {
      shopId: ShopStore.currentShop.id,
      data: {
        action: action,
        item: partName,
        description: description || 'No description available',
        priority: priority,
        status: 'new',
        interval_month:
          isTimeAlertOn && isTimeIntervalAlertOn && timeAlert.interval
            ? Number(timeAlert.interval)
            : null,
        started_month:
          isTimeAlertOn && isTimeIntervalAlertOn && timeAlert.started
            ? moment(timeAlert.started).unix()
            : null,
        fixed_month:
          isTimeAlertOn && !isTimeIntervalAlertOn && timeAlert.started
            ? moment(timeAlert.started).unix()
            : null,
        interval_mileage:
          isMileageAlertOn &&
          isMileageIntervalAlertOn &&
          Number(mileageAlert.interval)
            ? getMileageOrKm(Number(mileageAlert.interval))
            : null,
        fixed_mileage:
          isMileageAlertOn &&
          !isMileageIntervalAlertOn &&
          Number(mileageAlert.started)
            ? getMileageOrKm(Number(mileageAlert.started))
            : null,
        interval_engine_hours:
          isEngineHoursAlertOn &&
          isEngineHoursIntervalAlertOn &&
          engineHoursAlert.interval
            ? Number(engineHoursAlert.interval) * 3600
            : null,
        fixed_engine_hours:
          isEngineHoursAlertOn &&
          !isEngineHoursIntervalAlertOn &&
          engineHoursAlert.started
            ? Number(engineHoursAlert.started) * 3600
            : null,
        threshold: {
          time:
            isTimeAlertOn && timeAlert.threshold ? timeAlert.threshold : null,
          mileage:
            isMileageAlertOn && mileageAlert.threshold
              ? getMileageOrKm(Number(mileageAlert.threshold))
              : null,
          engineHours:
            isEngineHoursAlertOn && engineHoursAlert.threshold
              ? engineHoursAlert.threshold * 3600
              : null,
        },
      },
      carIds: selectedCarIds,
    };

    // route that create the routine
    await webServiceProvider.post('v1/routines', data);
  };

  getTimeAlertText = () => {
    let { isTimeAlertOn, isTimeIntervalAlertOn, timeAlert } = this.state;

    if (!isTimeAlertOn) return '';

    if (isTimeIntervalAlertOn) {
      return (
        <p>
          This service will be created every{' '}
          <b>(Z) {`${timeAlert.interval}`} months</b> start from{' '}
          <b>(X) {`${timeAlert.started}`}</b> and alert{' '}
          <b>(Y) {`${timeAlert.threshold}`} days</b> before
        </p>
      );
    }

    if (!_.isNil(timeAlert.started)) {
      return (
        <p>
          This service will be created on <b>(X) {`${timeAlert.started}`}</b>{' '}
          and alert <b>(Y) {`${timeAlert.threshold}`} days</b> before
        </p>
      );
    }
  };

  getMileageAlertText = () => {
    let {
      isMileageAlertOn,
      isMileageIntervalAlertOn,
      mileageAlert,
    } = this.state;

    if (!isMileageAlertOn) return '';

    if (isMileageIntervalAlertOn) {
      return (
        <p>
          This service will be created every{' '}
          <b>
            (X){' '}
            {`${mileageAlert.interval} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          and alert{' '}
          <b>
            (Y){' '}
            {`${mileageAlert.threshold} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          before
        </p>
      );
    }

    if (!_.isNil(mileageAlert.started)) {
      return (
        <p>
          This service will be created at{' '}
          <b>
            (X){' '}
            {`${mileageAlert.started} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          and alert{' '}
          <b>
            (Y){' '}
            {`${mileageAlert.threshold} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          before
        </p>
      );
    }
  };

  getEngineHoursAlertText = () => {
    let {
      isEngineHoursAlertOn,
      isEngineHoursIntervalAlertOn,
      engineHoursAlert,
    } = this.state;

    if (!isEngineHoursAlertOn) return '';

    if (isEngineHoursIntervalAlertOn) {
      return (
        <p>
          This service will be created every{' '}
          <b>(X) {`${engineHoursAlert.interval}`} engine hours</b> and alert{' '}
          <b>(Y) {`${engineHoursAlert.threshold}`} engine hours</b> before
        </p>
      );
    }

    if (!_.isNil(engineHoursAlert.started)) {
      return (
        <p>
          This service will be created at{' '}
          <b>(X) {`${engineHoursAlert.started}`} engine hours</b> and alert{' '}
          <b>(Y) {`${engineHoursAlert.threshold}`} engine hours</b> before
        </p>
      );
    }
  };

  render() {
    const { selectedCarIds } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form layout="vertical">
              <Form.Item label="Action / Service Name">
                {getFieldDecorator('action', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Service name!',
                    },
                  ],
                })(
                  <AutoComplete
                    type="textarea"
                    dataSource={[
                      'Change',
                      'Inspect',
                      'Perform',
                      'Replace',
                      'Remind',
                      'Rotate',
                    ]}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(val) =>
                      this.setState({
                        action: val,
                      })
                    }
                  />
                )}
              </Form.Item>

              <Form.Item label="Part Name">
                {getFieldDecorator('partName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Part Name!',
                    },
                  ],
                })(
                  <AutoComplete
                    type="textarea"
                    dataSource={partList}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(val) =>
                      this.setState({
                        partName: val,
                      })
                    }
                  />
                )}
              </Form.Item>

              <Form.Item label="Description">
                {getFieldDecorator('description')(
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={(e) => {
                      this.setState({
                        description: e.target.value,
                      });
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item label="Priority">
                {getFieldDecorator('priority', {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(
                  <Radio.Group
                    size="large"
                    onChange={(e) => {
                      let priority;

                      switch (e.target.value) {
                        case 'Minor':
                          priority = 1;
                          break;
                        case 'Major':
                          priority = 3;
                          break;
                        case 'Critical':
                          priority = 4;
                          break;
                        default:
                          priority = 4;
                      }

                      this.setState({
                        priority: priority,
                      });
                    }}
                  >
                    <Radio value="Minor" size="large">
                      <p
                        style={{
                          backgroundColor: '#dec431',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Minor
                      </p>
                    </Radio>

                    <Radio value="Major" size="large">
                      <p
                        style={{
                          backgroundColor: '#e28931',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Major
                      </p>
                    </Radio>

                    <Radio value="Critical" size="large">
                      <p
                        style={{
                          backgroundColor: '#f92d2e',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Critical
                      </p>
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <>
                <RoutineContainer>
                  <Form.Item className="routineBased" label="Set Time Alert ?">
                    {getFieldDecorator('timeBased', {
                      initialValue: 'no',
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(
                      <Radio.Group
                        buttonStyle="solid"
                        size="small"
                        name="timeBased"
                        onChange={() =>
                          this.setState({
                            isTimeAlertOn: !this.state.isTimeAlertOn,
                            isTimeIntervalAlertOn: false,
                          })
                        }
                      >
                        <Radio.Button value="no">No</Radio.Button>
                        <Radio.Button value="yes">Yes</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  {this.state.isTimeAlertOn && (
                    <>
                      <Form.Item label="Repeat?">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={(checked) =>
                            this.setState({
                              isTimeIntervalAlertOn: !this.state
                                .isTimeIntervalAlertOn,
                            })
                          }
                        />
                      </Form.Item>

                      {this.state.isTimeIntervalAlertOn && (
                        <>
                          <Form.Item
                            label="Service Repeat Every (Z) Months?"
                            name="interval_month"
                          >
                            {getFieldDecorator('interval_month', {
                              initialValue: 1,
                              rules: [
                                {
                                  required: true,
                                  type: 'number',
                                  message:
                                    'This is required and must be a number!',
                                },
                              ],
                            })(
                              <InputNumber
                                min={1}
                                onChange={(val) => {
                                  let key = this.state.isTimeIntervalAlertOn
                                    ? 'interval'
                                    : 'started';

                                  this.setState({
                                    timeAlert: {
                                      ...this.state.timeAlert,
                                      [`${key}`]: val,
                                    },
                                  });
                                }}
                              />
                            )}
                          </Form.Item>
                        </>
                      )}

                      <Row>
                        <Col span={6}>
                          <Form.Item label="Service Start On (X)?">
                            {getFieldDecorator('started_month', {
                              initialValue: moment(),
                              rules: [
                                { required: true, message: 'This is required' },
                              ],
                            })(
                              <DatePicker
                                onChange={(date) =>
                                  this.setState({
                                    timeAlert: {
                                      ...this.state.timeAlert,
                                      started: moment(date).format(
                                        'YYYY-MM-DD'
                                      ),
                                    },
                                  })
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Y) Days?">
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('thresholdTime', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={0}
                                  onChange={(val) =>
                                    this.setState({
                                      timeAlert: {
                                        ...this.state.timeAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                days
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getTimeAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>

                <RoutineContainer>
                  <Form.Item
                    className="routineBased"
                    label="Set Mileage Alert?"
                  >
                    {getFieldDecorator('mileageBased', {
                      initialValue: 'no',
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(
                      <Radio.Group
                        buttonStyle="solid"
                        size="small"
                        name="mileageBased"
                        onChange={() =>
                          this.setState({
                            isMileageAlertOn: !this.state.isMileageAlertOn,
                            isMileageIntervalAlertOn: false,
                          })
                        }
                      >
                        <Radio.Button value="no">No</Radio.Button>
                        <Radio.Button value="yes">Yes</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  {this.state.isMileageAlertOn && (
                    <>
                      <Form.Item label="Repeat?">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={(checked) =>
                            this.setState({
                              isMileageIntervalAlertOn: !this.state
                                .isMileageIntervalAlertOn,
                            })
                          }
                        />
                      </Form.Item>

                      <Row>
                        <Col span={6}>
                          <Form.Item
                            label={
                              this.state.isMileageIntervalAlertOn
                                ? 'Service Repeat Every (X) Mileage?'
                                : 'Service Start At (X) Mileage?'
                            }
                            name="mileage"
                          >
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator(
                                this.state.isMileageIntervalAlertOn
                                  ? 'interval_mileage'
                                  : 'fixed_mileage',
                                {
                                  initialValue: 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={0}
                                  onChange={(val) => {
                                    let key = this.state
                                      .isMileageIntervalAlertOn
                                      ? 'interval'
                                      : 'started';

                                    this.setState({
                                      mileageAlert: {
                                        ...this.state.mileageAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                {CurrentUserStore.user.displayedOdometer}
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Y) Mileage?">
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('thresholdMiles', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={0}
                                  onChange={(val) =>
                                    this.setState({
                                      mileageAlert: {
                                        ...this.state.mileageAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                {CurrentUserStore.user.displayedOdometer}
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getMileageAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>

                <RoutineContainer>
                  <Form.Item
                    className="routineBased"
                    label="Set Engine Hours Alert?"
                  >
                    {getFieldDecorator('engineHoursBased', {
                      initialValue: 'no',
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(
                      <Radio.Group
                        buttonStyle="solid"
                        size="small"
                        name="engineHoursBased"
                        onChange={() =>
                          this.setState({
                            isEngineHoursAlertOn: !this.state
                              .isEngineHoursAlertOn,
                            isEngineHoursIntervalAlertOn: false,
                          })
                        }
                      >
                        <Radio.Button value="no">No</Radio.Button>
                        <Radio.Button value="yes">Yes</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  {this.state.isEngineHoursAlertOn && (
                    <>
                      <Form.Item label="Repeat?">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={(checked) =>
                            this.setState({
                              isEngineHoursIntervalAlertOn: !this.state
                                .isEngineHoursIntervalAlertOn,
                            })
                          }
                        />
                      </Form.Item>

                      <Row>
                        <Col span={6}>
                          <Form.Item
                            label={
                              this.state.isEngineHoursIntervalAlertOn
                                ? 'Service Repeat Every (X) Engine Hours?'
                                : 'Service Start At (X) Engine Hours?'
                            }
                            name="engineHours"
                          >
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator(
                                this.state.isEngineHoursIntervalAlertOn
                                  ? 'interval_engine_hours'
                                  : 'fixed_engine_hours',
                                {
                                  initialValue: 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={1}
                                  onChange={(val) => {
                                    let key = this.state
                                      .isEngineHoursIntervalAlertOn
                                      ? 'interval'
                                      : 'started';

                                    this.setState({
                                      engineHoursAlert: {
                                        ...this.state.engineHoursAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                hours
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Y) Engine Hours?">
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('thresholdEngineHours', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={1}
                                  onChange={(val) =>
                                    this.setState({
                                      engineHoursAlert: {
                                        ...this.state.engineHoursAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                hours
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getEngineHoursAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>
              </>
            </Form>
          </Col>

          <Col span={24}>
            <b>
              <p>Select Vehicles</p>
            </b>
            <SelectCars
              rowSelection={{
                selectedCarIds: selectedCarIds,
                onChange: this.onSelect,
              }}
            />
            <p>
              <b>Total Selected Vehicles:</b> {selectedCarIds.length}
            </p>

            <Button
              style={{ marginTop: '10px' }}
              type="primary"
              onClick={this.onSubmit}
            >
              Add Service
            </Button>
          </Col>

          {ShopStore.currentShop.id !== -1 && (
            <Col span={24}>
              <RoutinesTableList />
            </Col>
          )}
        </Row>
      </>
    );
  }
}

RoutineServiceAntdForm.propTypes = {
  form: PropTypes.object,
};

const RoutineServiceForm = Form.create({ name: 'routine-service' })(
  observer(RoutineServiceAntdForm)
);

export default RoutineServiceForm;
