import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable, toJS } from 'mobx';
import PropTypes from 'prop-types';
import _, { debounce } from 'lodash';
// import styled from 'styled-components';

import { CurrentUserStore, WorkOrderStore } from 'stores';
import { Col, Row, Typography, Input, Form, Button, Select, Spin } from 'antd';

import WorkOrderLineItemLabors from '../Labors/WOLineItemLabors';
import WorkOrderLineItemParts from '../Parts/WOLineItemParts';
import WorkOrderLineItemIssues from '../Issues/WOLineItemIssues';
import TotalAmount from 'components/TotalAmount';
import FormLegend from 'components/Forms/FormLegend';
import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

// const NoFoundContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
class WorkOrderLineItemDetails extends Component {
  constructor(props) {
    super(props);
    this.lastFetchSystemCodeId = 0;
    this.fetchSystemCode = debounce(this.fetchSystemCode, 800);

    this.lastFetchAssemblyCodeId = 0;
    this.fetchAsmCodesForSysCode = debounce(this.fetchAsmCodesForSysCode, 800);
  }

  static propTypes = {
    childRef: PropTypes.func,
    onChangeServiceTask: PropTypes.func,
    onChangeDescription: PropTypes.func,
    lineItem: PropTypes.object,
    onChangeField: PropTypes.func,
    shopUsers: PropTypes.array,
    shopParts: PropTypes.array,
    currentCarIssues: PropTypes.array,
    onAddNewPart: PropTypes.func.isRequired,
    onUpdateLaborItem: PropTypes.func.isRequired,
    onUpdatePartItem: PropTypes.func.isRequired,
    onUpdateServiceItem: PropTypes.func.isRequired,
    onRemoveLineItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    onSelectSystemCode: PropTypes.func,
    onSelectAsmCode: PropTypes.func,
  };

  state = {
    currentServiceTaskOnSearch: '',
    fetchingSystemCode: false,
    currentSystemCodeOptions: [],
    fetchingAssemblyCode: false,
    systemCodeAsms: [],
    currentAssemblyCodeOptions: [],
  };

  labours = this.localLineItem.labours;
  parts = this.localLineItem.parts;
  services = this.localLineItem.services;
  shouldDisableLaborCost = false;
  shouldDisablePartsCost = false;

  laborsRef = null;
  partsRef = null;
  issuesRef = null;

  title = this.localLineItem.title;
  description = this.localLineItem.description;
  laborTotal = this.localLineItem.laborTotal;
  partsTotal = this.localLineItem.partsTotal;

  serviceTaskSuggestions = _.chain(WorkOrderStore.key32ServiceTasks)
    .map((description, i) => ({
      description,
      i,
    }))
    .sortBy('description')
    .value();

  get lineSubtotal() {
    return (this.laborTotal || 0) + (this.partsTotal || 0);
  }

  get localLineItem() {
    return this.props.lineItem;
  }

  get localSystemCode() {
    const vmrsSysCode = this.props.lineItem?.vmrs_sys_code;
    const vmrsSysText = this.props.lineItem?.vmrs_sys_text;
    if (vmrsSysCode && vmrsSysText) {
      return `${vmrsSysCode} - ${vmrsSysText}`;
    }
    return this.props.lineItem?.vmrs_sys_code_id || null;
  }

  get localAssemblyCode() {
    const vmrsAsmCode = this.props.lineItem?.vmrs_asm_code;
    const vmrsAsmText = this.props.lineItem?.vmrs_asm_text;
    if (vmrsAsmCode && vmrsAsmText) {
      return `${vmrsAsmCode} - ${vmrsAsmText}`;
    }
    return this.props.lineItem?.vmrs_asm_code_id || null;
  }

  get serviceTasksOptions() {
    return this.serviceTaskSuggestions.map((serviceTask, index) => (
      <Option key={index} value={serviceTask.i}>
        {serviceTask.description}
      </Option>
    ));
  }

  get vmrsSystemCodeOptions() {
    return this.state.currentSystemCodeOptions.map((systemCodeOption, index) => (
      <Option key={index} value={systemCodeOption.id}>
        {`${systemCodeOption.sys_text} - ${systemCodeOption.text}`}
      </Option>
    ));
  }

  get vmrsAssemblyCodeOptions() {
    return this.state.currentAssemblyCodeOptions.map((assemblyCodeOption, index) => (
      <Option key={index} value={assemblyCodeOption.id}>
        {`${assemblyCodeOption.asm_text} - ${assemblyCodeOption.text}`}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    this.labours = this.localLineItem.labours || [];
    this.onChangeField(this.labours, 'labours');
    this.parts = this.localLineItem.parts || [];
    this.onChangeField(this.parts, 'parts');

    // if labors is empty, add a new labor by default
    if (!this.labours.length) {
      this.onAddLabor();
    }

    // if parts is empty, add a new part by default
    if (!this.parts.length) {
      this.onAddExistingPart();
    }

    this.services = this.localLineItem.services || [];
    this.onChangeField(this.services, 'services');
    this.disableOrEnableLaborCost();
    this.disableOrEnablePartsCost();

    this.fetchSystemCode('', true);
  }

  disableOrEnableLaborCost = () => {
    this.shouldDisableLaborCost = !!this.labours.length;
  };

  disableOrEnablePartsCost = () => {
    this.shouldDisablePartsCost = !!this.parts.length;
  };

  fetchSystemCode = async (value, searchAll = false) => {
    this.lastFetchSystemCodeId += 1;
    const fetchId = this.lastFetchSystemCodeId;
    this.setState({ fetchingSystemCode: true });
    if (!value && !searchAll) {
      this.setState({ fetchingSystemCode: false });
      return;
    }
    const { data } = await WorkOrderStore.searchVmrs({
      value,
      type: 'system_code',
    });
    if (fetchId !== this.lastFetchSystemCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      currentSystemCodeOptions: data,
      fetchingSystemCode: false,
    });
  };

  handleChangeSystemCode = (vmrsKeyId) => {
    this.setState({ fetchingSystemCode: false }, () => {
      this.fetchAsmCodesForSysCode(vmrsKeyId);

      if (this.props.onSelectSystemCode) {
        this.props.onSelectSystemCode(vmrsKeyId);
      }
    });
  };

  fetchAsmCodesForSysCode = async (vmrsKeyId) => {
    this.lastFetchAssemblyCodeId += 1;
    const fetchId = this.lastFetchAssemblyCodeId;
    this.setState({ fetchingAssemblyCode: true });
    if (!vmrsKeyId) {
      this.setState({ fetchingAssemblyCode: false });
      return;
    }
    const { data } = await WorkOrderStore.getAsmCodesForSysCode(vmrsKeyId);
    if (fetchId !== this.lastFetchAssemblyCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      currentAssemblyCodeOptions: data,
      systemCodeAsms: data,
      fetchingAssemblyCode: false,
    });
  };

  filterAssemblyCodes = (value) => {
    const { systemCodeAsms } = this.state;
    const filteredAsmCodes = systemCodeAsms.filter((asm) => {
      return asm.text.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({ currentAssemblyCodeOptions: filteredAsmCodes });
  };

  getKey = (arr) => {
    const newKey = _.random(10000);
    if (arr.some((a) => a.key === newKey)) {
      return this.getKey(arr);
    }
    return newKey;
  };

  onChangeServiceTask = (event) => {
    const { value } = event.target;
    this.title = value;
    if (this.props.onChangeServiceTask) {
      this.props.onChangeServiceTask(event);
    }
    if (this.props.onChangeField) {
      this.props.onChangeField(value, 'title');
    }
  };

  onChangeDescription = (event) => {
    const { value } = event.target;
    this.description = value;
    if (this.props.onChangeDescription) {
      this.props.onChangeDescription(event);
    }
    if (this.props.onChangeField) {
      this.props.onChangeField(value, 'description');
    }
  };

  onAddLabor = () => {
    const currentUserSettings = CurrentUserStore.user.settings;
    const key = this.getKey(this.labours);
    this.labours.push({
      key,
      name: `Labor ${key}`,
      id_labour: '',
      hours: 0,
      cost: currentUserSettings?.laborRate || 0,
    });
    this.disableOrEnableLaborCost();
    this.onChangeField(this.labours, 'labours');
  };

  onAddExistingPart = (existingPart = {}) => {
    const key = this.getKey(this.parts);
    this.parts.push({
      key,
      name: `Part ${key}`,
      id_vehicle_part: '',
      cost: 0,
      amount: 1,
      markup: 0,
      ...existingPart,
    });
    this.disableOrEnablePartsCost();
    this.onChangeField(this.parts, 'parts');
    // update parts total
    this.onUpdatePartLineTotal();
  };

  onAddIssue = () => {
    const key = this.getKey(this.services);
    this.services.push({
      key,
      name: `Issue ${key}`,
      id_vehicle_service: '',
    });
    this.onChangeField(this.services, 'services');
  };

  onRemoveLabor = (event, itemKey) => {
    const index = this.labours.findIndex((l) => l.key === itemKey);
    this.labours.splice(index, 1);
    this.disableOrEnableLaborCost();
    this.onChangeField(toJS(this.labours), 'labours');
  };

  onRemovePart = (event, itemKey) => {
    const index = this.parts.findIndex((l) => l.key === itemKey);
    this.parts.splice(index, 1);
    this.disableOrEnablePartsCost();
    this.onChangeField(toJS(this.parts), 'parts');
  };

  onRemoveIssue = (event, itemKey) => {
    const index = this.services.findIndex((l) => l.key === itemKey);
    this.services.splice(index, 1);
    this.onChangeField(toJS(this.services), 'services');
  };

  onAddItemClick = (itemRef) => {
    switch (itemRef) {
      case 'Labor':
        return this.onAddLabor();
      case 'Part':
        return this.onAddExistingPart();
      case 'Issue':
        return this.onAddIssue();
      default:
        return;
    }
  };

  onChangeField = (newVal, fieldName) => {
    this[fieldName] = newVal;

    if (this.props.onChangeField) {
      this.props.onChangeField(newVal, fieldName);
    }
  };

  onUpdateLaborLineTotal = () => {
    this.laborTotal = _.sumBy(this.labours, (l) => l.hours * l.cost);

    if (this.props.onChangeField) {
      this.props.onChangeField(this.laborTotal, 'laborTotal');
    }
  };

  onUpdatePartLineTotal = () => {
    this.partsTotal = _.sumBy(this.parts, (part) => {
      const cost = part.cost || 0;
      const markup = part.markup || 0;
      const amount = part.amount || 0;

      return (cost + (cost * markup) / 100) * amount;
    });

    if (this.props.onChangeField) {
      this.props.onChangeField(this.partsTotal, 'partsTotal');
    }
  };

  onSelectServiceTask = (value) => {
    const suggestion = this.serviceTaskSuggestions.find((s) => s.i === value);
    this.title = suggestion.description;
    if (this.props.onChangeServiceTask) {
      this.props.onChangeServiceTask(null, suggestion.description);
    }
    if (this.props.onChangeField) {
      this.props.onChangeField(suggestion.description, 'title');
    }
  };

  onSearchServiceTask = (value) => {
    this.setState({ currentServiceTaskOnSearch: value });
  };

  onClickAddServiceTask = () => {
    const key = this.serviceTaskSuggestions.length + 1;
    this.serviceTaskSuggestions.push({
      i: key,
      description: this.state.currentServiceTaskOnSearch,
    });
    this.onSelectServiceTask(key);
  };

  render() {
    // const serviceTaskFilterOption = (input, option) => {
    //   return (
    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   );
    // };

    // const noServiceTaskFound = this.state.currentServiceTaskOnSearch ? (
    //   <NoFoundContainer>
    //     <Button type="link" icon="plus" onClick={this.onClickAddServiceTask}>
    //       Add {this.state.currentServiceTaskOnSearch}
    //     </Button>
    //   </NoFoundContainer>
    // ) : (
    //   <NoFoundContainer>
    //     <Icon style={{ fontSize: '24px' }} type="inbox" />
    //     <Text level={4}>No Data</Text>
    //   </NoFoundContainer>
    // );

    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <FormLegend>{this.localLineItem.header}</FormLegend>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs System Code:</Text>
            <Select
              value={this.localSystemCode}
              placeholder="Search"
              notFoundContent={this.state.fetchingSystemCode ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={this.fetchSystemCode}
              onChange={this.handleChangeSystemCode}
              showSearch
              disabled={this.props.isDisabled}
            >
              {this.vmrsSystemCodeOptions}
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs Assembly Code:</Text>
            <Select
              value={this.localAssemblyCode}
              onChange={(value) => {
                if (this.props.onSelectAsmCode) {
                  this.props.onSelectAsmCode(value);
                }
              }}
              placeholder="Search"
              notFoundContent={this.state.fetchingAssemblyCode ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={this.filterAssemblyCodes}
              showSearch
              disabled={this.props.isDisabled}
            >
              {this.vmrsAssemblyCodeOptions}
            </Select>
          </Col>
          <Col xs={8} sm={8}>
            <Text strong>Labor Cost:</Text>
            <TotalAmount amount={this.laborTotal} />
          </Col>
          <Col xs={8} sm={8}>
            <Text strong>Parts Cost:</Text>
            <TotalAmount amount={this.partsTotal} />
          </Col>
          <Col xs={8} sm={8}>
            <FlexRow>
              <FlexColum style={{ flex: 1 }}>
                <Text strong>Subtotal:</Text>
                <TotalAmount amount={this.lineSubtotal} />
              </FlexColum>
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  icon="delete"
                  type="link"
                  className="delete-danger"
                  onClick={() => this.props.onRemoveLineItem()}
                />
              </FlexColum>
            </FlexRow>
          </Col>
          <Col span={24}>
            <Text strong>Description:</Text>
            <TextArea
              rows={4}
              placeholder="Add Description"
              value={this.description}
              onChange={this.onChangeDescription}
              disabled={this.props.isDisabled}
            />
          </Col>
          <Col span={24}>
            <Button
              type="link"
              onClick={() => this.onAddIssue()}
              icon="plus-circle"
            >
              Link Existing Issues
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddLabor()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add labor
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddExistingPart()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add Existing Part
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (this.props.onAddNewPart) {
                  this.props.onAddNewPart();
                }
              }}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add New Part
            </Button>
          </Col>
          <Col span={24}>
            <WorkOrderLineItemLabors
              title="Labors (Technician)"
              description="Labor"
              items={this.labours}
              shopUsers={this.props.shopUsers}
              childRef={(ref) => (this.laborsRef = ref)}
              onAddItem={() => this.onAddItemClick('Labor')}
              onRemoveItem={this.onRemoveLabor}
              onUpdateLineTotal={this.onUpdateLaborLineTotal}
              onUpdateLocalItem={this.props.onUpdateLaborItem}
              isDisabled={this.props.isDisabled}
            />
          </Col>
          <Col span={24}>
            <WorkOrderLineItemParts
              title="Parts"
              description="Parts"
              items={this.parts}
              shopParts={this.props.shopParts}
              childRef={(ref) => (this.partsRef = ref)}
              onAddItem={() => this.onAddItemClick('Part')}
              onRemoveItem={this.onRemovePart}
              onUpdateLineTotal={this.onUpdatePartLineTotal}
              onAddNewPart={this.props.onAddNewPart}
              onUpdateLocalItem={this.props.onUpdatePartItem}
              isDisabled={this.props.isDisabled}
            />
          </Col>
          {!this.props.isDisabled && (
            <Col span={24}>
              <WorkOrderLineItemIssues
                title="Link issues"
                description="link issues"
                items={this.services}
                currentCarIssues={this.props.currentCarIssues}
                childRef={(ref) => (this.issuesRef = ref)}
                onAddItem={() => this.onAddItemClick('Issue')}
                onRemoveItem={this.onRemoveIssue}
                onUpdateLocalItem={this.props.onUpdateServiceItem}
              />
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

decorate(WorkOrderLineItemDetails, {
  title: observable,
  description: observable,
  laborTotal: observable,
  partsTotal: observable,
  labours: observable,
  parts: observable,
  shouldDisableLaborCost: observable,
  shouldDisablePartsCost: observable,
  services: observable,
  lineSubtotal: computed,
  localLineItem: computed,
  localSystemCode: computed,
  localAssemblyCode: computed,
});

export default observer(WorkOrderLineItemDetails);
