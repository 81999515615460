import _ from 'lodash';
import moment from 'moment-timezone';

import { calcMileage } from 'helpers/unitCalculations';
import { CurrentUserStore } from 'stores';

export const getDueAt = (interval, currentMetric) => {
  let n = _.divide(currentMetric, interval);

  return _.multiply(interval, Math.ceil(n));
};

export const getRoutineDueDate = (issue) => {
  let { routineInfo } = issue;

  if (routineInfo?.fixed_month) {
    return moment.unix(routineInfo.fixed_month).tz(CurrentUserStore.user.settings.timezone || 'America/Toronto').format('ll');
  }

  if (routineInfo?.interval_month) {
    let serviceCreatedAt = moment
      .tz(
        issue.reportDate,
        CurrentUserStore.user.settings.timezone || 'America/Toronto'
      )
      .utc();

    let n = moment(serviceCreatedAt).diff(
      moment
        .unix(routineInfo.started_month)
        .add(Number(routineInfo.interval_month), 'months'),
      'months'
    );

    if (n <= 0)
      return moment
        .unix(routineInfo.started_month)
        .add(Number(routineInfo.interval_month), 'months')
        .format('ll');

    return moment(serviceCreatedAt)
      .add(Number(routineInfo.interval_month), 'months')
      .format('ll');
  }

  return null;
};

export const getRoutineDueMileage = (issue) => {
  let { routineInfo, mileage } = issue;

  if (routineInfo?.fixed_mileage) return Number(routineInfo.fixed_mileage);

  if (routineInfo?.interval_mileage) {
    return Number(
      calcMileage(
        _.sum([Number(mileage), Number(routineInfo.interval_mileage)])
      )
    ).toFixed(2);
  }

  return null;
};

export const getRoutineDueEngineHours = (issue) => {
  let { routineInfo, engineHours } = issue;

  if (routineInfo?.fixed_engine_hours)
    return Number(routineInfo.fixed_engine_hours);

  if (routineInfo?.interval_engine_hours) {
    return Number(
      _.divide(
        _.sum([Number(engineHours), Number(routineInfo.interval_engine_hours)]),
        3600
      )
    ).toFixed(2);
  }

  return null;
};

export const getRoutineStartingDate = (issue) => {
  let { reportDate, doneDate } = issue;

  return doneDate || reportDate;
};
