import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Col, Row, Tabs } from 'antd';

import { queryUtils } from 'helpers';

import EditProfileForm from './EditProfileForm';
import EditSettingsForm from './EditSettingsForm';
import EditPasswordForm from './EditPasswordForm';
import EditImageForm from './EditImageForm';
import FleetRulesForm from './FleetRulesForm';

const { TabPane } = Tabs;

class ProfilePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    currentTab: 'profile'
  }

  componentDidMount () {
    const currentTab = queryUtils.getSearchParamByKey(window.location.search, 'tab');
    if (currentTab) {
      this.setState({ currentTab });
    }
  }

  onChangeTab = (key) => {
    this.setState({ currentTab: key });
    this.props.history.push({
      pathname: '/profile',
      search: `?tab=${key}`
    });
  }

  render() {
    return (
      <>
        <h1>Profile Settings</h1>
        <Tabs activeKey={this.state.currentTab} onChange={this.onChangeTab} type="card">
          <TabPane tab="Edit Profile" key="profile">
            <Row gutter={[32, 32]} type="flex">
              <Col md={8} sm={12} xs={24}>
                <EditProfileForm />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Dashboard Logo" key="image">
            <EditImageForm />
          </TabPane>
          <TabPane tab="Edit Preferences" key="settings">
            <Row gutter={[32, 32]} type="flex">
              <Col md={8} sm={12} xs={24}>
                <EditSettingsForm />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Change Password" key="password">
            <Row gutter={[32, 32]} type="flex">
              <Col md={8} sm={12} xs={24}>
                <EditPasswordForm />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Fleet Rules" key="rules">
            <Row gutter={[32, 32]} type="flex">
              <Col md={24} sm={24} xs={24}>
                <FleetRulesForm />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export default withRouter(ProfilePage);
